import React, { Component } from "react";
import { FormGroup, Image } from "react-bootstrap";
import TextareaAutosize from "react-autosize-textarea";
// import doc from "../Styles/Images/doc.png";
// import pdf from '../Styles/Images/pdf.png';
// import excel from "../Styles/Images/excel.png";
import { API } from "aws-amplify";
import { s3Upload } from './Storage';

export class AuthFooterFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFile: {},
      sent: false,
      email: "",
      message: "",
      fileType: false,
      loading: false
    };
  }

  toggleFeedback = () => {
    this.props.toggleFeedback();
    if(this.state.sent){
      this.setState({
        sent: false,
        activeFile: {},
        fileType: false,
        message: ""
      })
    }
  };

  getMessage = e => {
    this.setState({
      message: e.target.value
    });
  };

  handleUploadingDoc = (event) => {
    var file = event.target.files[0];
    this.setState({ 
      //  additionalAccess: true,
      //  summaryFocus: true,
       newFile: file
   })
}

  // addAttachment = () => {
  //   let extension;
  //   this.setState({
  //     activeFile: { filePath: "testFile.doc" }
  //   });

  //   setTimeout(() => {
  //     extension = this.state.activeFile.filePath
  //       .split(".")
  //       .pop()
  //       .toLowerCase()
  //       .toString();
  //     this.setState({
  //       fileType: extension
  //     });
  //   }, 50);
  // };

  submit = () => {
    if (this.state.message.length >= 5 && this.state.newFile) {
      this.setState({
        loading: true
      })
        s3Upload(this.state.newFile).then(response => {
          API.post("statmvp", "postauthusercomment", {
            body: {
              message: this.state.message,
              fileUrl: response
            }
          })
            .then(response => {
              this.setState({
                sent: true,
                loading: false
              });
            })
            .catch(error => {
              console.log(error);
            });
          })
    } else if (this.state.message.length >= 5){
      API.post("statmvp", "postauthusercomment", {
        body: {
          message: this.state.message,
          fileUrl: false
        }
      })
        .then(response => {
          this.setState({
            sent: true
          });
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      alert("Please enter your comment!");
    }
  };

  render() {
    // let fileIcon = false;
    // if (this.state.fileType) {
    //   if (this.state.fileType === "pdf") {
    //     fileIcon = pdf;
    //   } else if (this.state.fileType === "doc") {
    //     fileIcon = doc;
    //   } else if (this.state.fileType === "excel") {
    //     fileIcon = excel;
    //   }
    // }

    return (
      <div
        className={
          this.props.feedbackOpen ? "footerFeedback open" : "footerFeedback"
        }
      >
        <div
          className={this.props.feedbackOpen ? "feedInner open" : "feedInner"}
        >
          <div
            className={this.props.feedbackOpen ? "bubbleDiv open" : "bubbleDiv"}
            onClick={this.toggleFeedback}
          >
            <p className="color-w bubbleText uppercase">Share your insights</p>
          </div>

          {this.props.feedbackOpen ? (
            <div className="insightsFeedbackDiv">
              {this.state.sent ? (
                <div className="sentMessage">
                  <p className="color-pk centered">message sent</p>
                </div>
              ) : (
                <form className="form" onSubmit={this.handleSubmit}>
                  <div className="wrapper">
                    <FormGroup
                      controlId="formControlsTextarea"
                      className="textAreaGroup"
                    >
                      <TextareaAutosize
                        className={"textArea"}
                        value={this.state.message}
                        rows={1} // min rows
                        placeholder="We would love to hear from you! Please share any feedback, questions or comments you want the STAT team to know here…"
                        onChange={this.getMessage}
                      />
                      <input
                        onChange={ e => this.handleUploadingDoc(e)}
                        type="file"
                        style={{color: 'black', maxWidth: '100%'}}
                      />
                    </FormGroup>
                    <div className="sendDiv">
                      <p className="color-pk noMargin text">
                        Send a message...
                      </p>
                      <div className="iconWrapper">
                          
                        {/* {fileIcon && (
                          <Image
                            className="fileIcon"
                            width="auto"
                            height="auto"
                            src={`${fileIcon}`}
                          />
                        )}
                        {this.props.showAttachments && (
                          <Image
                            className="attachmentIcon"
                            width="auto"
                            height="auto"
                            src={require("../Styles/Images/addAttachments.png")}
                            onClick={this.addAttachment}
                          />
                        )} */}
                        {this.state.loading === false ?
                        <Image
                          className="sendIcon"
                          width="auto"
                          height="auto"
                          src={require("../Styles/Images/sendFeedback.png")}
                          onClick={this.submit}
                        />
                        :
                        <p>Loading...</p>
                        }
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
