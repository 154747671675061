import React, { Component } from 'react';
import  { Link } from 'react-router-dom';
import { API } from 'aws-amplify';
import { 
    DropdownButton,
    Checkbox,
    // Button,
    Image } from 'react-bootstrap';
import Switch from "react-switch";
import { LeftSideBar } from '../../Components/LeftSideBar';
import { RightSideBar } from '../../Components/RightSideBar';
import { SortICards } from '../../Components/SortICards';
import { SortByClass } from '../../Components/SortByClass';
import { ChangeCardLayout } from '../../Components/ChangeCardLayout';
import { ICardsContainer } from '../../Components/ICardsContainer';
import { DownloadMultipleBtn } from '../../Components/DownloadMultipleBtn';
import { CSVLink } from "react-csv";
import { ICardModal } from '../../Components/ICardModal';

import { AuthFooter } from '../../Components/AuthFooter';

class Dashboard extends Component {
    constructor(props){
        super(props);
        this.state = {
            // loading: true,
            mobileMode: false,
            showDetailModal: false,

            activeClassification: 'all',
            newClassification: false,
            sortBy: 'Recent',
            sortByOpen: false,
            layoutCards: true,

            iCardsToBeDownloaded: [],

            needSpace: false,
            extraSpace: null,

            aDropDownOpen: false,
            categories: 0,
            selections: {
                financialProduct: [],
                impactedIndustry: [],
                customerType: [],
                traffickingType: [],
                geographicalRegions: [],
                sourceFrom: []
            },

            financialOptions: {'Commercial Banking': false, 'Correspondent Banking': false, 'Credit Cards': false, 'Lending': false, 'Money Services Businesses':false, 'Prepaid Cards': false, 'Remote Deposit Capture':false, 'Retail Banking': false, 'Virtual Currency': false, 'Wire Transfer': false},
            allFinancial: false,
            financialProduct : false,

            industryOptions: {'Agriculture & Animal Husbandry': false, 'Arts & Entertainment': false, 'Bars, Strip Clubs & Cantinas': false, 'Carnivals': false, 'Commercial Cleaning Services': false,'Construction': false, 'Domestic Work': false, 'Escort Services': false, 'Factories & Manufacturing': false, 'Forestry & Logging': false, 'Health & Beauty Services': false, 'Health Care': false, 'Hotels & Hospitality': false, 'Illicit Massage, Health & Beauty': false, 'Landscaping': false, 'Illicit Activities': false, 'Outdoor Solicitation': false, 'Peddling & Begging': false,'Personal Sexual Servitude': false, 'Pornography': false, 'Recreational Facilities': false, 'Remote Interactive Sexual Acts': false, 'Residential': false, 'Restaurant & Food Services': false, 'Traveling Sales Crews': false },
            allIndustries: false,
            impactedIndustry: false,

            customerOptions: {'Labor Agency': false, 'Recruiter':  false, 'Transporter': false, 'Victim': false, 'Other Trafficker': false },
            allCustomers: false,
            customerType: false,

            traffickingOptions: {'Child Exploitation': false, 'Labor': false, 'Sex': false },
            allTrafficking: false,
            traffickingType: false,

            regionOptions: {'Africa': false, 'Asia': false, 'Canada': false, 'Caribbean': false, 'Europe': false, 'Latin America': false, 'Middle East': false, 'United States': false },
            allRegions: false,
            geographicalRegions: false,

            sources: {'Law Enforcement': false, 'Financial Institution': false, 'NGO': false},
            allSources: false,
            sourceFrom: false,
        }

        this._isMounted = false;
    }

    checkForMobileMode = () => {
        this.setState({ mobileMode: window.innerWidth <= 620 });
    }

    showDetailModal = (summary, id, date, source, financial, industry, customer, region, details, iType, transactionTime, traffickingType, views, downloads, fileUrl, bookmarked) => {
        this.setState({
            cardSummary: summary, 
            cardId: id, 
            cardDateSubmitted: date, 
            cardSource: source, 
            cardFinancial: financial, 
            cardIndustry: industry, 
            cardCustomer: customer, 
            cardRegion: region, 
            cardDetails: details, 
            cardiType: iType, 
            cardTransactionTime: transactionTime, 
            cardTraffickingType: traffickingType, 
            cardViews: views,
            cardDownloads: downloads,
            cardFileUrl: fileUrl,
            cardBookmarked: bookmarked,
        })

        setTimeout(() => {
            this.setState({
                showDetailModal: true,
            })
        }, 20)
    }

    closeDetailModal = () => {
        this.setState({
            showDetailModal: false,
        })
    }

    addExtraSpace = (e, item) => {
        let evt = e.target;

        setTimeout(() => {
            // console.log(evt.getAttribute('aria-expanded'))

            if (evt.getAttribute('aria-expanded') === 'true' ) {
                this.setState({ 
                    aDropDownOpen: true
                })

                if (this.state.mobileMode) {
                    // this.setState({ extraSpace: '21em' }) // Drop down Ul height + seeSelectionBtn height(4em)
                    if (item === 'financial') {
                        this.setState({ 
                            extraSpace: '0em' 
                        })
                        return
                    } else if (item === 'industry') {
                        this.setState({ 
                            
                            extraSpace: '0em' 
                        })
                        return
                    } else if (item === 'customer') {
                        this.setState({ 
                            
                            extraSpace: '0em' 
                        })
                        return
                    } else if (item === 'trafficking') {
                        this.setState({ 
                            
                            extraSpace: '0em' 
                        })
                        return
                    } else if (item === 'regions') {
                        this.setState({ 
                            
                            extraSpace: '0em' 
                        })
                        return
                    } else if (item === 'sources') {
                        this.setState({ 
                            
                            extraSpace: '0em' 
                        })
                        return
                    }
                } else {
                    if (item === 'financial') {
                        this.setState({ 
                            extraSpace: '0em' 
                        })
                        return
                    } else if (item === 'industry') {
                        this.setState({ 
                            
                            extraSpace: '5em' 
                        })
                        return
                    } else if (item === 'customer') {
                        this.setState({ 
                            
                            extraSpace: '0em' 
                        })
                        return
                    } else if (item === 'trafficking') {
                        this.setState({ 
                            
                            extraSpace: '0em' 
                        })
                        return
                    } else if (item === 'regions') {
                        this.setState({ 
                            
                            extraSpace: '0em' 
                        })
                        return
                    } else if (item === 'sources') {
                        this.setState({ 
                            
                            extraSpace: '0em' 
                        })
                        return
                    }
                }
            } else {
                this.setState({ 
                    aDropDownOpen: false,
                    extraSpace: null 
                })
            } 
        }, 50 );
    }

    clickOutside = (e) => {
        let evt = e.target;

        const refs = {
            financialOpen: this.fpOpenElement,
            inTypeOpen: this.itOpenElement,
            sourceOpen: this.sOpenElement,
            industriesOpen: this.aiOpenElement,
            customerOpen: this.ctOpenElement,
            traffickingOpen: this.ttOpenElement,
            regionOpen: this.grOpenElement
        }

        let currentRef;

        // setTimeout(() => {
        //     if(this.state.aDropDownOpen) {
        //         this.setState({
        //             aDropDownOpen: false,
        //             extraSpace: null
        //         })
        //     }
        // }, 200)
        
        Object.keys(refs).map(key => {
            
            
            setTimeout(() => {
                currentRef = refs[key];
                //console.log(currentRef)
                if(!currentRef.contains(evt)) {
                    //console.log(currentRef)
                    if (this.state.aDropDownOpen) {
                        this.setState({
                            aDropDownOpen: false,
                            extraSpace: null
                        })
                        return console.log();
                    }
                }
            }, 200 )
            return console.log();
        })
        
    }

    // aDropDownOpen = (e) => {
    //     // e.preventDefault();
    //     if (this.dropDown.attr('aria-expended') === true) {
    //         this.setState({
    //             aDropDownOpen: true
    //         })
            
    //     }
    // }
    

    getOptionHelper = (optionsHolder, allOptions, backend) => {
        if (this.state[allOptions]){
            this.setState({ [allOptions]: false });
        }
        
        let newArray = [];
        // let newBackend = [];
        let thisKey;
        let fullBackend = {...this.state[backend]};
        let newSelections = {...this.state.selections};

        setTimeout(() => {
            Object.keys(this.state[optionsHolder]).map(key => {
                if (this.state[optionsHolder][key] === true) {
                    thisKey = key;
                    newArray.push(thisKey)
                }
                return thisKey = '';
            })
            
        }, 100)
        setTimeout(() => {
            if (newArray.length >= 1) {
                fullBackend = newArray;
                newSelections[backend] = newArray;
                
                this.setState({ 
                        [backend]: fullBackend,
                        selections: newSelections
                })
            } else {
                newSelections[backend] = []

                this.setState({
                        [backend]: false,
                        selections: newSelections                  
                })
            }
        }, 200)
        setTimeout(() => {
            let newCategories = newSelections.financialProduct.length + newSelections.impactedIndustry.length + newSelections.customerType.length + newSelections.traffickingType.length + newSelections.geographicalRegions.length + newSelections.sourceFrom.length;
            this.setState({
                categories: newCategories
            })

        }, 300)
    }


    selectAllHelper = (options, backend, allOptions) => { // Reusable, select all question options
        let keyValues = {};
        let newArray = [];
        let newOptions = {...this.state[options]};
        let newSelections = {...this.state.selections};
        let thisKey;

        this.setState({ 
            [allOptions]: !this.state[allOptions]
        })

        setTimeout(() => {
            if(this.state[allOptions] === true) {
                Object.keys(newOptions).map(key => {
                    thisKey = key;
                    keyValues[thisKey] = true;
                    newArray.push(thisKey)
                    return thisKey = '';
                })
                newSelections[backend] = newArray;
                this.setState({ 
                    [options]: keyValues,
                    [backend]: newArray,
                    selections: newSelections
                    // categories: newArray.length
                })
            } else {
                Object.keys(newOptions).map(key => {
                    thisKey = key;
                    keyValues[thisKey] = false;
                    newArray.push(thisKey)
                    return thisKey = '';
                })
                newSelections[backend] = [];
                this.setState({ 
                    // [allOptions]: !this.state[allOptions],
                    [options]: keyValues,
                    [backend]: false,
                    selections: newSelections,
                    // categories: 0,
                })
            }
        }, 10)
        setTimeout(() => {
            let newCategories = newSelections.financialProduct.length + newSelections.impactedIndustry.length + newSelections.customerType.length + newSelections.traffickingType.length + newSelections.geographicalRegions.length + newSelections.sourceFrom.length;
            this.setState({
                categories: newCategories
            })

        }, 100)
    }

   

    //*** Financial Product
    getFinancialOptions = (name, optionsHolder, allOptions, backend) => { // Get the chosen financial product
        let financialOptions = {...this.state.financialOptions};
        financialOptions[name] = !this.state.financialOptions[name];
        this.setState({ financialOptions })
    
        this.getOptionHelper(optionsHolder, allOptions, backend);
    }

    
    onlyFinancial = (name, options, allOptions, backend) => {
    // Only text function when all options are true
        this.selectAllHelper(options, backend, allOptions);
    // Make all options false
        setTimeout(() => {
        this.getFinancialOptions(name, options, allOptions, backend);
        }, 100);
    // Then make this option true
    }


    //*** Industries Impacted
    getIndustryOptions = (name, optionsHolder, allOptions, backend) => { // Get the chosen impacted industries
        let industryOptions = {...this.state.industryOptions};
        industryOptions[name] = !this.state.industryOptions[name];
        this.setState({ industryOptions })
        this.props.location.history.push("/profile");
        this.getOptionHelper(optionsHolder, allOptions, backend);
    }

    onlyIndustry = (name, options, allOptions, backend) => {
    // Only text function when all options are true
        this.selectAllHelper(options, backend, allOptions);
    // Make all options false
        setTimeout(() => {
        this.getIndustryOptions(name, options, allOptions, backend);
        }, 100);
    // Then make this option true
    }


    //*** Customer Types
    getCustomerOptions = (name, optionsHolder, allOptions, backend) => { // Get the chosen customer types
        let customerOptions = {...this.state.customerOptions};
        customerOptions[name] = !this.state.customerOptions[name];
        this.setState({ customerOptions })
        
        this.getOptionHelper(optionsHolder, allOptions, backend);
    }

    onlyCustomer = (name, options, allOptions, backend) => {
    // Only text function when all options are true
        this.selectAllHelper(options, backend, allOptions);
    // Make all options false
        setTimeout(() => {
        this.getCustomerOptions(name, options, allOptions, backend);
        }, 100);
    // Then make this option true
    }


    //*** Trafficking Types 
    getTraffickingOptions = (name, optionsHolder, allOptions, backend) => { // Get the chosen Trafficking types
        let traffickingOptions = {...this.state.traffickingOptions};
        traffickingOptions[name] = !this.state.traffickingOptions[name];
        this.setState({ traffickingOptions })
        
        this.getOptionHelper(optionsHolder, allOptions, backend);
    }

    onlyTrafficking = (name, options, allOptions, backend) => {
    // Only text function when all options are true
        this.selectAllHelper(options, backend, allOptions);
    // Make all options false
        setTimeout(() => {
        this.getTraffickingOptions(name, options, allOptions, backend);
        }, 100);
    // Then make this option true
    }


    //*** Geographical Regions 
    getRegions = (name, optionsHolder, allOptions, backend) => { // Get the chosen Trafficking types
        let regionOptions = {...this.state.regionOptions};
        regionOptions[name] = !this.state.regionOptions[name];
        this.setState({ regionOptions })
        
        this.getOptionHelper(optionsHolder, allOptions, backend);
    }

    onlyRegions = (name, options, allOptions, backend) => {
    // Only text function when all options are true
        this.selectAllHelper(options, backend, allOptions);
    // Make all options false
        setTimeout(() => {
        this.getRegions(name, options, allOptions, backend);
        }, 100);
    // Then make this option true
    }


    //*** Sources
    getSources = (name, optionsHolder, allOptions, backend) => { // Get the choosen Sources 
        let sources = {...this.state.sources};
        sources[name] = !this.state.sources[name];
        this.setState({ sources })

        this.getOptionHelper(optionsHolder, allOptions, backend);
    }

    onlySources = (name, options, allOptions, backend) => {
    // Only text function when all options are true
        this.selectAllHelper(options, backend, allOptions);
    // Make all options false
        setTimeout(() => {
        this.getSources(name, options, allOptions, backend);
        }, 100);
    // Then make this option true
    }
    


    sortNewClass = (newClass) => { // Only show cards based off indicator type
        let newCards = [];

        this.state.indicators.map((item) => {
            let indicatorData = [];

            if (item.indicatorType) {
                indicatorData = item.indicatorType;
            }

            if (indicatorData.length) {
                if (indicatorData.includes(newClass)) { 
                    return newCards.push(item); // Push this indicator obj to newClassCards array
                }
            }
            return console.log();
        });

        this.setState({
            newClassification: true,
            newClassCards: newCards,
            activeClassification: newClass
        })
    }

    toggleSortBy = () => {
        this.setState({
            sortByOpen: !this.state.sortByOpen
        })
    }

    sortByDate = (type, newLoad) => {
        let cards = [];

        // if (newLoad === 'newLoadTrue') {
            // cards = this.state.indicators;
        // } else {
            // cards = this.state.threeMostRecent;
        // }

        if (newLoad === 'newLoadTrue') {  // Presorting helps first date sort work for some reason
            let preSorted = this.state.indicators.sort((a, b) => { // b - a = most views, descending 
                let viewA = Number(a.viewCount);
                let viewB = Number(b.viewCount);    
                return viewB - viewA; 
            });
            cards = preSorted;

        } else {
            cards = this.cards;
        }

        let sorted = cards.sort((a, b) => { // b - a = Recent, a - b = Oldest
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            let submittedA = a.dateIndicatorSubmitted;
            let submittedB = b.dateIndicatorSubmitted;
            let arrayA = submittedA.split('/');
            let arrayB = submittedB.split('/');

            let months = [ "Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec" ];
            let monthForA = Number(submittedA[1]) - 1;
            let monthForB = Number(submittedB[1]) - 1;
            const textMonthA = months[monthForA];
            const textMonthB = months[monthForB];

            let dayForA = arrayA[0];
            let dayForB = arrayB[0];
            let yearForA = arrayA[2];
            let yearForB = arrayB[2];
            let dateA = textMonthA + '/' + dayForA + '/' + yearForA;
            let dateB = textMonthB + ' ' + dayForB + ', ' + yearForB;
        

            let newDateB = new Date(dateB);
            let newDateA = new Date(dateA); 

            if (type === 'Recent') {
                return newDateB - newDateA;
            } else if (type === 'Oldest') {
                return newDateA - newDateB;
            }

            return console.log();
        });
        
        

        this.setState({
            sortBy: type
        })
        
        this.cards = sorted;

        if (newLoad === 'newLoadTrue'){
             return
        } else {
            this.toggleSortBy()
        } 

        // For threeMostRecent:
        // if (newLoad === 'newLoadTrue'){
            // this.setState({
                // sortBy: type,
        //         threeMostRecent: sorted.slice(0, 3), // initially set to the first 3 cards
            // })
            
            // this.cards = sorted.slice(0, 3);
            // console.log(this.cards)
            //  return
        // } else {
        //     this.setState({
        //         sortBy: type,
        //     })
        //     this.cards = sorted.slice(0, 3); 
        //     this.toggleSortBy()
        // }
    }

    mostViews = () => {
        let sorted = this.cards.sort((a, b) => { // b - a = most views, descending 
            let viewA = Number(a.viewCount);
            let viewB = Number(b.viewCount);    
            return viewB - viewA; 
        });
        this.setState({
            sortBy: 'Most viewed'
        })

        this.cards = sorted;

        this.toggleSortBy();
    }

    toggleCardLayout = () => {
        this.setState({
            layoutCards: !this.state.layoutCards
        })
    }


    selectToDownload = (obj) => {
        this.setState({
            iCardsToBeDownloaded: [...this.state.iCardsToBeDownloaded, obj]
        })
    }

    removeDownload = (obj) => {
        var array = [...this.state.iCardsToBeDownloaded]; 
        var index = array.indexOf(obj)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState(prevState => { 
                return { 
                    numToDownload: prevState.numToDownload - 1,
                    iCardsToBeDownloaded: array 
                }
            })
        }
        // console.log(this.state.numToDownload, this.state.iCardsToBeDownloaded)
    }

    downloadMultiple = () => {
        console.log(this.state.iCardsToBeDownloaded)
    }


    componentWillMount(){
        window.addEventListener('resize', this.checkForMobileMode.bind(this));
        this.checkForMobileMode();
    }

    componentDidMount(){
        this._isMounted = true;

        API.get("statmvp", "getindicatordata").then(response => {
            var indicatorArray = [];
            for (var i = 0; i < response.length; i++){
                if (response[i].approved){
                    indicatorArray.push(response[i])
                }
            }

            this.setState({
                indicators: indicatorArray
            })

            this.sortByDate('Recent', 'newLoadTrue');
        
            
        }).catch(e => {
            console.log(e)
        })
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.checkForMobileMode.bind(this));
    }

    render(){
        // this.indicatorArray = [];

        if(this.state.indicators){
            this.cards = this.state.indicators;
        }

        // if (this.state.threeMostRecent) {
        //     this.cards = this.state.threeMostRecent;
        // }

        if (this.state.newClassification) {
            this.cards = this.state.newClassCards;
        }
    //*** Financial Products  */
        let financialOptions = [];
        if (this.state.financialOptions){
            financialOptions = Object.keys(this.state.financialOptions).map(key => {
                return (                                 
                    <Checkbox
                        name='financialCheckbox' 
                        className={this.state.financialOptions[key] ? 'options dd color-pk selected checked' : 'options dd color-b'}
                        value={key}
                        key={key}
                        checked={this.state.financialOptions[key] ? 'checked' : ''}
                        readOnly
                        onClick={() => this.getFinancialOptions(key, 'financialOptions', 'allFinancial', 'financialProduct')}
                    >   
                        <span className='checkmark marginLeft'></span>
                        
                        <span className='color-b key'>{key}</span>  
                        
                        {this.state.allFinancial &&
                            <div 
                                className='onlyTextLink'
                                onClick={() => this.onlyFinancial(key, 'financialOptions', 'allFinancial', 'financialProduct')}
                                // to={{ 
                                //     pathname: '/seemyselections',
                                //     state: {
                                //         selections: {
                                //             financialProduct: [key],
                                //             impactedIndustry: [],
                                //             customerType: [],
                                //             traffickingType: [],
                                //             geographicalRegions: [],
                                //             sourceFrom: []
                                //         },
                                //     }
                                // }}
                            >
                                <span className= 'color-b uppercase onlyText'>only</span>
                            </div>
                        }
                    </Checkbox>
                )
            });
        }



    //*** Industries Impacted
        let industryOptions = [];
        if (this.state.industryOptions){
            industryOptions = Object.keys(this.state.industryOptions).map(key => {
                return (                                 
                    <Checkbox
                        name='industryCheckbox' 
                        className={this.state.industryOptions[key] ? 'options dd color-pk selected checked' : 'options dd color-b'}
                        value={key}
                        key={key}
                        checked={this.state.industryOptions[key] ? 'checked' : ''}
                        readOnly
                        onClick={() => this.getIndustryOptions(key, 'industryOptions', 'allIndustries', 'impactedIndustry')}
                    >   
                        <span className='checkmark marginLeft'></span>

                        <span className='color-b key'>{key}</span>  
                        
                        {this.state.allIndustries &&
                            <div 
                            className='onlyTextLink'
                            onClick={() => this.onlyIndustry(key, 'industryOptions', 'allIndustries', 'impactedIndustry')}
                                // to={{ 
                                //     pathname: '/seemyselections',
                                //     state: {
                                //         selections: {
                                //             financialProduct: [],
                                //             impactedIndustry: [key],
                                //             customerType: [],
                                //             traffickingType: [],
                                //             geographicalRegions: [],
                                //             sourceFrom: []
                                //         },
                                //     }
                                // }}
                            >
                                <span className= 'color-b uppercase onlyText'>only</span>
                            </div>
                        }
                    </Checkbox>
                )
            });
        }


    //*** Customer Types 
        let customerOptions = [];
        if (this.state.customerOptions){
            customerOptions = Object.keys(this.state.customerOptions).map(key => {
                return (                                 
                    <Checkbox
                        name='customerCheckbox' 
                        className={this.state.customerOptions[key] ? 'options dd color-pk selected checked' : 'options dd color-b'}
                        value={key}
                        key={key}
                        checked={this.state.customerOptions[key] ? 'checked' : ''}
                        readOnly
                        onClick={() => this.getCustomerOptions(key, 'customerOptions', 'allCustomers', 'customerType')}
                    >   
                        <span className='checkmark marginLeft'></span>

                        <span className='color-b key'>{key}</span>  
                        
                        {this.state.allCustomers &&
                            <div 
                                className='onlyTextLink'
                                onClick={() => this.onlyCustomer(key, 'customerOptions', 'allCustomers', 'customerType')}
                                // to={{ 
                                //     pathname: '/seemyselections',
                                //     state: {
                                //         selections: {
                                //             financialProduct: [],
                                //             impactedIndustry: [],
                                //             customerType: [key],
                                //             traffickingType: [],
                                //             geographicalRegions: [],
                                //             sourceFrom: []
                                //         },
                                //     }
                                // }}
                            >
                                <span className= 'color-b uppercase onlyText'>only</span>
                            </div>
                        } 
                    </Checkbox>
                )
            });
        }


    //*** Trafficking Types
        let traffickingOptions = [];
        if (this.state.traffickingOptions){
            traffickingOptions = Object.keys(this.state.traffickingOptions).map(key => {
                return (                                 
                    <Checkbox
                        name='customerCheckbox' 
                        className={this.state.traffickingOptions[key] ? 'options dd color-pk selected checked' : 'options dd color-b'}
                        value={key}
                        key={key}
                        checked={this.state.traffickingOptions[key] ? 'checked' : ''}
                        readOnly
                        onClick={() => this.getTraffickingOptions(key, 'traffickingOptions', 'allTrafficking', 'traffickingType')}
                    >   
                        <span className='checkmark marginLeft'></span>
                        
                        <span className='color-b key'>{key}</span>  
                        
                        {this.state.allTrafficking &&
                            <div 
                                className='onlyTextLink'
                                onClick={() => this.onlyTrafficking(key, 'traffickingOptions', 'allTrafficking', 'traffickingType')}
                                // to={{ 
                                //     pathname: '/seemyselections',
                                //     state: {
                                //         selections: {
                                //             financialProduct: [],
                                //             impactedIndustry: [],
                                //             customerType: [],
                                //             traffickingType: [key],
                                //             geographicalRegions: [],
                                //             sourceFrom: []
                                //         },
                                //     }
                                // }}
                            >
                                <span className= 'color-b uppercase onlyText'>only</span>
                            </div>
                        }
                    </Checkbox>
                )
            });
        }


    //*** Region Types
        let regionOptions = [];
        if (this.state.regionOptions){
            regionOptions = Object.keys(this.state.regionOptions).map(key => {
                return(                                 
                    <Checkbox
                        name='regionCheckbox' 
                        className={this.state.regionOptions[key] ? 'options dd color-pk selected checked' : 'options dd color-b'}
                        value={key}
                        key={key}
                        checked={this.state.regionOptions[key] ? 'checked' : ''}
                        readOnly
                        onClick={() => this.getRegions(key, 'regionOptions', 'allRegions', 'geographicalRegions')}
                    >   
                        <span className='checkmark marginLeft'></span>

                        <span className='color-b key'>{key}</span>  
                        
                        {this.state.allRegions &&
                            <div 
                                className='onlyTextLink'
                                onClick={() => this.onlyRegions(key, 'regionOptions', 'allRegions', 'geographicalRegions')}
                                // to={{ 
                                //     pathname: '/seemyselections',
                                //     state: {
                                //         selections: {
                                //             financialProduct: [],
                                //             impactedIndustry: [],
                                //             customerType: [],
                                //             traffickingType: [],
                                //             geographicalRegions: [key],
                                //             sourceFrom: []
                                //         },
                                //     }
                                // }}
                            >
                                <span className= 'color-b uppercase onlyText'>only</span>
                            </div>
                        }   
                    </Checkbox>
                )
            });
        }


    //*** Source From
        let sources = [];
        if (this.state.sources){
            sources = Object.keys(this.state.sources).map(key => {
                return(                                 
                    <Checkbox
                        name='sourceCheckbox' 
                        className={this.state.sources[key] ? 'options dd color-pk selected checked' : 'options dd color-b'}
                        value={key}
                        key={key}
                        checked={this.state.sources[key] ? 'checked' : ''}
                        readOnly
                        onClick={() => this.getSources(key, 'sources', 'allSources', 'sourceFrom')}
                    >   
                        <span className='checkmark marginLeft'></span>
                        
                        <span className='color-b key'>{key}</span>  
                        
                        {this.state.allSources &&
                            <div 
                                className='onlyTextLink'
                                onClick={() => this.onlySources(key, 'sources', 'allSources', 'sourceFrom')}
                                // to={{ 
                                //     pathname: '/seemyselections',
                                //     state: {
                                //         selections: {
                                //             financialProduct: [],
                                //             impactedIndustry: [],
                                //             customerType: [],
                                //             traffickingType: [],
                                //             geographicalRegions: [],
                                //             sourceFrom: [key]
                                //         },
                                //     }
                                // }}
                            >
                                <span className= 'color-b uppercase onlyText'>only</span>
                            </div>
                        }
                    </Checkbox>
                )
            });
        }


        return (
            <div id='dashboard'>
                <LeftSideBar page={'dashboard'} />
                <RightSideBar page={'dashboard'} userHasAuthenticated = {this.props.userHasAuthenticated} />

                <div className='innerPage dashboardInner'>
                    <p className='color-b bold pageTitle'>Explore indicators</p>
                    <p className='color-b'>Select from multiple categories</p>

                    <div className='dropDownsContainer'>
    {/* Financial Drop down */}
                        <DropdownButton 
                            id='financialBtn' 
                            className='dropDownBtns'
                            title='What Financial product'
                            onClick={(e) => this.addExtraSpace(e, 'financial') }
                            ref={(fpOpenElement) => this.fpOpenElement = fpOpenElement}
                            // onClick={this.aDropDownOpen}
                        >
                            <label className='selectAllWrap'>
                                <span className='color-b normalWeight'>Select all</span>
                                <Switch 
                                    name='financialRadio'
                                    className={this.state.allFinancial ? 'iFormSwitch allTrue' : 'iFormSwitch allFalse'}
                                    onChange={() => this.selectAllHelper('financialOptions', 'financialProduct', 'allFinancial')} 
                                    checked={this.state.allFinancial}
                                />
                            </label>
                            {financialOptions.length ? financialOptions : null}
                        </DropdownButton>
    {/* Industry Drop down */}
                        <DropdownButton 
                            id='industryBtn' 
                            className='dropDownBtns'
                            title={'Where Impacted industry'}
                            onClick={(e) => this.addExtraSpace(e, 'industry') }
                            ref={(aiOpenElement) => this.aiOpenElement = aiOpenElement}
                        >
                            <label className='selectAllWrap'>
                                <span className='color-b normalWeight'>Select all</span>
                                <Switch 
                                    name='industryRadio'
                                    className={this.state.allIndustries ? 'iFormSwitch allTrue' : 'iFormSwitch allFalse'}
                                    onChange={() => this.selectAllHelper('industryOptions', 'impactedIndustry', 'allIndustries')} 
                                    checked={this.state.allIndustries}
                                />
                            </label>
                            {industryOptions.length ? industryOptions : null}
                        </DropdownButton>
    {/* Customer Drop down */}
                        <DropdownButton 
                            id='customerBtn' 
                            className='dropDownBtns'
                            title={'Who Customer type'}
                            onClick={(e) => this.addExtraSpace(e, 'customer') }
                            ref={(ctOpenElement) => this.ctOpenElement = ctOpenElement}
                        >
                            <label className='selectAllWrap'>
                                <span className='color-b normalWeight'>Select all</span>
                                <Switch 
                                    name='customerRadio'
                                    className={this.state.allCustomers ? 'iFormSwitch allTrue' : 'iFormSwitch allFalse'}
                                    onChange={() => this.selectAllHelper('customerOptions', 'customerType', 'allCustomers')} 
                                    checked={this.state.allCustomers}
                                />
                            </label>
                            {customerOptions.length ? customerOptions : null}
                        </DropdownButton>
{/* Trafficking Drop down */}
                        <DropdownButton 
                            id='traffickingBtn' 
                            className='dropDownBtns'
                            title={'What Trafficking type'}
                            onClick={(e) => this.addExtraSpace(e, 'trafficking') }
                            ref={(ttOpenElement) => this.ttOpenElement = ttOpenElement}
                        >
                            <label className='selectAllWrap'>
                                <span className='color-b normalWeight'>Select all</span>
                                <Switch 
                                    name='traffickingRadio'
                                    className={this.state.allTrafficking ? 'iFormSwitch allTrue' : 'iFormSwitch allFalse'}
                                    onChange={() => this.selectAllHelper('traffickingOptions', 'traffickingType', 'allTrafficking')} 
                                    checked={this.state.allTrafficking}
                                />
                            </label>
                            {traffickingOptions.length ? traffickingOptions : null}
                        </DropdownButton>

    {/* Regions Drop down */}
                        <DropdownButton 
                            id='regionsBtn' 
                            className='dropDownBtns'
                            title={'Location Geographical region'}
                            onClick={(e) => this.addExtraSpace(e, 'regions') }
                            ref={(grOpenElement) => this.grOpenElement = grOpenElement}
                        >
                            <label className='selectAllWrap'>
                                <span className='color-b normalWeight'>Select all</span>
                                <Switch 
                                    name='regionsRadio'
                                    className={this.state.allRegions ? 'iFormSwitch allTrue' : 'iFormSwitch allFalse'}
                                    onChange={() => this.selectAllHelper('regionOptions', 'geographicalRegions', 'allRegions')} 
                                    checked={this.state.allRegions}
                                />
                            </label>
                            {regionOptions.length ? regionOptions : null}
                        </DropdownButton>

    {/* Sources Drop down */}
                        <DropdownButton 
                            id='sourcesBtn' 
                            className='dropDownBtns'
                            title={'From Source'}
                            onClick={(e) => this.addExtraSpace(e, 'sources') }
                            ref={(sOpenElement) => this.sOpenElement = sOpenElement}
                        >
                            <label className='selectAllWrap'>
                                <span className='color-b normalWeight'>Select all</span>
                                <Switch 
                                    name='sourcesRadio'
                                    className={this.state.allSources ? 'iFormSwitch allTrue' : 'iFormSwitch allFalse'}
                                    onChange={() => this.selectAllHelper('sources', 'sourceFrom', 'allSources')} 
                                    checked={this.state.allSources}
                                />
                            </label>
                            {sources.length ? sources : null}
                        </DropdownButton>

                    </div>

{/* See my selection btn */}
                    {this.state.categories >= 1 &&
                        <Link 
                            className='seeSelectionBtn'
                            to={{ 
                                pathname: '/seemyselections',
                                state: {
                                    selections: this.state.selections,
                                }
                            }}
                        >
                            <span className='color-w'>See my selection</span>
                            <Image
                                className='seeSelectionArrow'
                                width='auto'
                                height='auto'
                                src={require('../../Styles/Images/pinkRightArrow.png')}
                            />
                        </Link>
                    }

                    <div className='flexRow seeAllWrapper'>
                        <Link 
                            className='flexRow seeAllBtn'
                            to={{ pathname: '/allindicators' }}
                        >
                            <p className='color-b light noMargin text'>See all indicators</p>
                            <div className='seeAllArrow'></div>
                        </Link>
                    </div>

                    <div className='sortTypeWrapper'>
                        <p className='color-b light'>Sort by indicator classification</p>
                        
                        <SortByClass
                            size={'large'}
                            sortNewClass={this.sortNewClass}
                            activeClassification={this.state.activeClassification}
                        />
                    </div>


                    <div className='flexRow rowBetween smallSortRow'>
                        {/* <p className='color-b light'>Most recently uploaded indicators</p> */}
                        <p className='color-b light'>All indicators</p>

                        <div className='flexRow rowEnd smallSortWrapper'>
                            <SortByClass
                                size={'small'}
                                sortNewClass={this.sortNewClass}
                                activeClassification={this.state.activeClassification}
                            />

                            <SortICards 
                                mobileMode={this.state.mobileMode}
                                sortByOpen={this.state.sortByOpen}
                                sortBy={this.state.sortBy}
                                actions={{
                                    sortByDate: this.sortByDate,
                                    mostViews: this.mostViews,
                                    toggleSortBy: this.toggleSortBy,
                                }}
                            />

                            {!this.state.mobileMode &&
                                <ChangeCardLayout
                                    layoutCards={this.state.layoutCards}
                                    toggleCardLayout={this.toggleCardLayout}
                                />
                            }
                        </div> {/* .smallSortWrapper */}
                    </div>

                    <ICardsContainer
                        mobileMode={this.state.mobileMode}
                        cards={this.cards}
                        layoutCards={this.state.layoutCards}
                        // containerLoading={this.state.containerLoading}
                        actions={{
                            selectToDownload: this.selectToDownload,
                            removeDownload: this.removeDownload,
                            // downloadiCard: this.downloadiCard,
                            showDetailModal: this.showDetailModal,
                        }}
                    />
                    
{/* Extra Space for open drop downs  */}
                    {/* <div style={{ width: '100%', height: this.state.extraSpace }}></div> */}



{/* Download multiple iCards btn */}
                    {this.state.iCardsToBeDownloaded.length >= 1 &&
                        <CSVLink data={this.state.iCardsToBeDownloaded} target="_blank" filename={"stat-indicator.csv"}>
                            <DownloadMultipleBtn
                                numToDownload={this.state.iCardsToBeDownloaded.length}
                                downloadMultiple={this.downloadMultiple}
                            />
                        </CSVLink>
                    }
                </div> {/* .dashboardInner */}

                {this.state.showDetailModal &&
                    <ICardModal
                        // isOpen={this.state.showModal} 
                        // onRequestClose={this.closeModal}
                        actions={{
                            closeDetailModal: this.closeDetailModal,
                        }}
                        summary={this.state.cardSummary}
                        details={this.state.cardDetails}
                        id={this.state.cardId}
                        dateSubmitted={this.state.cardDateSubmitted}
                        source={this.state.cardSource}
                        iType={this.state.cardiType}
                        financial={this.state.cardFinancial}
                        customer={this.state.cardCustomer}
                        region={this.state.cardRegion}
                        industry={this.state.cardIndustry}
                        traffickingType={this.state.cardTraffickingType}
                        transactionTime={this.state.cardTransactionTime}
                        fileUrl={this.state.cardFileUrl}
                        views={this.state.cardViews}
                        downloads={this.state.cardDownloads}
                        bookmarked={this.state.cardBookmarked}
                    /> 
                }

                <AuthFooter />
            </div>
        )
    }
}

export default Dashboard;