import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { Navbar } from './Navbar';
import {
  HelpBlock,
  FormGroup,
  Glyphicon,
  FormControl,
  ControlLabel,
  Button,
  Overlay,
  Tooltip
} from "react-bootstrap";


export default class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: "",
      email: "",
      password: "",
      codeSent: false,
      confirmed: false,
      confirmPassword: "",
      isConfirming: false,
      isSendingCode: false,
      passwordError: false
    };
  }

  validateCodeForm() {
    return this.state.email.length > 0;
  }

  validateResetForm() {
    return (
      this.state.code.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  toggleToolTip = (e, toolTip) => {
    e.preventDefault();
      this.setState({
          [toolTip]: !this.state[toolTip] 
      })
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSendCodeClick = async event => {
    event.preventDefault();

    this.setState({ isSendingCode: true });

    try {
      await Auth.forgotPassword(this.state.email);
      this.setState({ codeSent: true });
    } catch (e) {
      alert(e.message);
      this.setState({ isSendingCode: false });
    }
  };

  handleConfirmClick = async event => {
    event.preventDefault();

    this.setState({ isConfirming: true });

    try {
      await Auth.forgotPasswordSubmit(
        this.state.email,
        this.state.code,
        this.state.password
      );
      this.setState({ confirmed: true });
    } catch (e) {
      console.log(e)
      if(e.code === "InvalidPasswordException"){
          this.setState({
            passwordError: true
        })
        this.setState({ isConfirming: false });
      } else if (e.code === "InvalidParameterException") {
          this.setState({
            passwordError: true
        })
        this.setState({ isConfirming: false });
      } else if (e.code ==="CodeMismatchException") {
          alert("Your confirmation code is incorrect, please type the correct code and try again.");
          this.setState({ isConfirming: false });
        } else {
        alert(e.message);
        this.setState({ isConfirming: false });
      } 
      
    }
  };

  renderRequestCodeForm() {
    return (
      <form className='form' onSubmit={this.handleSendCodeClick}>
        <FormGroup  controlId="email">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            autoFocus
            className={'actionInput blue'}
            type="email"
            value={this.state.email}
            onChange={this.handleChange}
          />
        </FormGroup>
        <Button
          block
          className='passwordResetBtn'
          type="submit"
          loadingText="Sending…"
          text="Send Confirmation"
          isLoading={this.state.isSendingCode}
          disabled={!this.validateCodeForm()}
          onClick={this.handleSendCodeClick}
        >Send Password</Button>
      </form>
    );
  }

  renderConfirmationForm() {
    return (
    
      <form className='form' onSubmit={this.handleConfirmClick}>
        <FormGroup controlId="code">
          <ControlLabel>Confirmation Code</ControlLabel>
          <FormControl
            className={'actionInput blue code'}
            autoFocus
            type="tel"
            value={this.state.code}
            onChange={this.handleChange}
          />
          <HelpBlock>
            Please check your email ({this.state.email}) for the confirmation
            code.
          </HelpBlock>
        </FormGroup>
        <hr />
        <FormGroup  controlId="password">
          <ControlLabel>New Password</ControlLabel>
          <FormControl
            className={'actionInput blue'}
            type="password"
            value={this.state.password}
            onChange={this.handleChange}
            ref={(passwordInput) => this.passwordInput = passwordInput} 
          />
        </FormGroup>
        <FormGroup  controlId="confirmPassword">
          <ControlLabel>Confirm Password</ControlLabel>
          <FormControl
            className={'actionInput blue'}
            type="password"
            onChange={this.handleChange}
            value={this.state.confirmPassword}
          />
        </FormGroup>
        <Overlay 
            target={this.passwordInput} 
            show={this.state.passwordError} 
            onHide={(e) => this.toggleToolTip(e, 'passwordError')}
            placement={'top'}
            rootClose={true}
        >
            <Tooltip 
                id={'passwordTooltip'} 
                className='toolTips forRequestAccess' 
            >
                <p>Your password should be a minimum of eight characters in length and contain at least one character from four of the following categories: One uppercase letter, One lowercase letter, One Digit, One special character</p>
            </Tooltip>
        </Overlay>
        <Button
          block
          className='passwordResetBtn'
          type="submit"
          text="Confirm"
          loadingText="Confirm…"
          isLoading={this.state.isConfirming}
          disabled={!this.validateResetForm()}
          onClick={this.handleConfirmClick}
        >Submit</Button>
      </form>
    );
  }

  renderSuccessMessage() {
    return (
      <div className="success">
        <div className='flexRow' style={{marginBottom: '1em'}}>
          <Glyphicon glyph="ok" style={{marginRight: '0.5em'}} />
          <p className='noMargin'>Your password has been reset.</p>
        </div>
        <p>
        <Link className='pLinks'
          to={{ 
            pathname: '/', 
            state: { requestAccess: false, login: "re-auth" } 
          }}>
            Click here to login with your new credentials.
          </Link>
        </p>
      </div>
    );
  }

  render() {
    return (
        <div id="resetPasswordWrapper">
            <Navbar />
            <div className="innerPage ResetPassword">
                {!this.state.codeSent
                ? this.renderRequestCodeForm()
                : !this.state.confirmed
                    ? this.renderConfirmationForm()
                    : this.renderSuccessMessage()}
            </div>
      </div>
    );
  }
}