import React, {Component} from 'react';
import { Link } from 'react-router-dom'
import { Grid, Row, Col } from 'react-bootstrap';

export class Navbar extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading: false
        }
    }

    componentWillMount() {
        if(this.props.color === 'white') {
            this.setState({ navColor: 'white' })
        } else {
            this.setState({ navColor: 'blue' })
        }
    }

render() {
    
    return (
      <div className='navbar'>
        <Grid fluid={true}>
            <Row className='show-grid'>
                <Col sm={0} md={1} className='emptyCol'>
                <p></p>
                </Col>
                <Col sm={12} md={11} className='logoCol'>
                    
                    <div className={this.state.navColor === 'white' ? 'box white' : 'box blue'}>
                        <Link to='/'>
                            <h3 className='title'>STAT</h3>
                            <p className='subTitle light'>Stand Together Against Trafficking<br></br>powered by <span className='xs-semi-bold'>enigma</span></p>
                        </Link>
                    </div>
                    
                </Col>
            </Row>
        </Grid>
      </div>
    );
   }
}
