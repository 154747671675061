import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Row, Col, Image } from 'react-bootstrap';

import { PrivacyPolicyModal } from './PrivacyPolicyModal';
import { TermsOfServiceModal } from './TermsOfServiceModal';
import { FooterFeedback } from './FooterFeedback';
import { AuthFooterFeedback } from './AuthFooterFeedback';
import { Auth } from 'aws-amplify';

export class AuthFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileMode: false,
            showPolicy: false,
            showTerms: false,
            feedbackOpen: false
        };

        this._isMounted = false;
    }

    //Auth.currentAuthenticatedUser sends a promise
    getCurrentUser = () => {
        window.scrollTo(0, 0);
    
        Auth.currentAuthenticatedUser()
        .then(response => {
            if (this._isMounted) {
                if (this.props.page === 'signup') {
                    this.setState({
                        user: false
                    });
                } else {
                    this.setState({
                        user: true
                    });
                }
            }
            
        })
        .catch(error => {
            if (this._isMounted) {
                this.setState({
                    user: false
                });
            }
        });
    }

    closeModal = name => {
        this.setState({
            [name]: false
        });
    };

    toggleFeedback = () => {
        this.setState({ feedbackOpen: !this.state.feedbackOpen });
    };

    checkForMobileMode = () => {
        this.setState({ mobileMode: window.innerWidth <= 736 }); // iPhone landscape
    }

    componentWillMount(){
        window.addEventListener('resize', this.checkForMobileMode.bind(this));
        this.checkForMobileMode();
    }

    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.getCurrentUser();
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.checkForMobileMode.bind(this));
    }

    render() {
        this.marginBottom = '0em';

        if (this.state.mobileMode && this.props.panelOpen) {
            this.marginBottom = '0em';
        } else if (this.state.mobileMode) {
            this.marginBottom = '3.6em';
        }  

        return (
            <div className={this.state.user ? 'footer authUser' : 'footer'} style={{marginBottom: this.marginBottom}}>
                <PrivacyPolicyModal
                    isOpen={this.state.showPolicy} 
                    onRequestClose={() => this.closeModal('showPolicy')}
                />

                <TermsOfServiceModal 
                    isOpen={this.state.showTerms} 
                    onRequestClose={() => this.closeModal('showTerms')}
                />

                {this.state.user === false ? 
                    <FooterFeedback
                        toggleFeedback={this.toggleFeedback}
                        feedbackOpen={this.state.feedbackOpen}
                        // showAttachments={this.state.showAttachments}
                    />
                : 
                    <AuthFooterFeedback
                        toggleFeedback={this.toggleFeedback}
                        feedbackOpen={this.state.feedbackOpen}
                        showAttachments={true}
                    />
                }

                <Grid className={this.state.mobileMode ? 'mobile' : ''} fluid={true}>
                    <Row className='show-grid row'>
                        <Col xs={12} sm={6} md={6} className='col one'>
                            <Link className='exploreBtn' to='/statsupportingOrgs'>
                                <div>
                                    <p className='color-w exploreText'>Explore our<br></br>supporting organizations</p>
                                    <Image 
                                        className='exploreArrow'
                                        width='auto'
                                        height='auto'
                                        src={require('../Styles/Images/pinkRightArrow.png')}
                                    />
                                </div>
                            </Link>
                            {this.state.mobileMode ?
                                <div className='contactDiv'>
                                    <p className='color-w'>Powered by 
                                        <a href='https://www.enigma.com/' target='_blank' rel='noopener noreferrer'>
                                            <Image
                                            className='footerInlineLogo'
                                            width='auto'
                                            height='auto'
                                            src={require('../Styles/Images/enigmaWhite.png')}
                                            />
                                        </a>
                                    </p>
                                    <Link className='color-w uppercase links' to='/statfaqs'>FAQ<span style={{textTransform: 'lowercase'}}>s</span></Link>
                                    <p className='color-w uppercase links middleLink' onClick={this.toggleFeedback}>CONTACT US</p>
                                    <Link className='color-w uppercase links' to='/stataboutus'>ABOUT US</Link>
                                </div>
                            :
                                <div className='copyRight'>
                                    <p>© Enigma Technologies, Inc.</p>
                                    <p className='links' onClick={() => this.setState({ showPolicy: true })}>Privacy Policy</p>
                                    <p className='links' onClick={() => this.setState({ showTerms: true })}>User Agreement</p>
                                </div>
                            }
                        </Col>

                        <Col xs={12} sm={6} md={6} className='col two'> 
                            {this.state.mobileMode ?
                                <div className='copyRight'>
                                    <p>© Enigma Technologies, Inc.</p>
                                    <p className='links' onClick={() => this.setState({ showPolicy: true })}>Privacy Policy</p>
                                    <p className='links' onClick={() => this.setState({ showTerms: true })}>User Agreement</p>
                                </div>
                            :
                                <div>
                                    <div className='divider'>
                                    </div>

                                    <div className='contactDiv'>
                                        <p className='color-w flexOne'>Powered by 
                                            <a href='https://www.enigma.com/' target='_blank' rel='noopener noreferrer'>
                                                <Image
                                                className='footerInlineLogo'
                                                width='auto'
                                                height='auto'
                                                src={require('../Styles/Images/enigmaWhite.png')}
                                                />
                                            </a>
                                        </p>
                                        <div className='contactInner flexOne'>
                                            <Link className='color-w uppercase links' to='/statfaqs'>FAQ<span style={{textTransform: 'lowercase'}}>s</span></Link>
                                            <p className='color-w uppercase links' onClick={this.toggleFeedback}>CONTACT US</p>
                                            <Link className='color-w uppercase links' to='/stataboutus'>ABOUT US</Link>
                                        </div>
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }
}
