import React, { Component } from 'react';
import { Checkbox, Button, FormGroup, FormControl, Overlay, Tooltip } from 'react-bootstrap';

import { LeftSideBar } from '../../Components/LeftSideBar';
import { PrivacyPolicyModal } from '../../Components/PrivacyPolicyModal';
import { TermsOfServiceModal } from '../../Components/TermsOfServiceModal';
import { Footer } from '../../Components/Footer';
import { API, Auth } from 'aws-amplify';

export class SignInAndAgree extends Component {
    constructor(props){
        super(props);
        this.state = {
            mobileMode: false,
            showPolicy: false,
            showTerms: false,
            newsletter: true,
            name: '',
            passwordError: false,
            password: '',
        }
    }

    checkForMobileMode = () => {
        this.setState({ mobileMode: window.innerWidth <= 414 });
    }

    toggleNewsletter = () => {
        this.setState({
            newsletter: !this.state.newsletter
        })
    }

    toggleToolTip = (e, toolTip) => {
        e.preventDefault();
        this.setState({
            [toolTip]: !this.state[toolTip] 
        })
    }

    closeModal = (name) => {
        this.setState({
            [name]: false
        });
    };

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        var username = this.state.email.toLowerCase();
        var citystate = this.state.citystate;
        var country = this.state.country;
        var password = this.state.tempPassword;
        var newPassword = this.state.newPassword;

        if (username.length > 5 && password.length > 5) {


        Auth.signIn(username, password).then(user => {
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                Auth.completeNewPassword(
                    user,               
                    newPassword       
                ).then(user => {
                    API.post("statmvp", "adduser", {
                        body: {
                            name: this.state.name,
                            citystate: citystate,
                            country: country
                        }
                    }).catch(error => {
                        console.log(error)
                    })  
                    API.post("statmvp", "adduseremail", {
                        body: {
                            email: username,
                            receiveNewsletter: this.state.newsletter
                        }
                    })
                    this.props.userHasAuthenticated(true);
                }).catch(e => {
                    if (e.code === "InvalidPasswordException") {
                        this.setState({
                            passwordError: true
                        })
                    } else if (e.code === "InvalidParameterException"){
                        this.setState({
                            passwordError: true
                        })
                    } else {
                        alert(e.message)
                    }
                });
            } 
        }).catch(e => {
            if (e.code === 'UserNotConfirmedException') {
                alert("Please wait for a confirmation email from the STAT  team with your log in information.")
            } else if (e.code === 'NotAuthorizedException') {
                alert("Your temporary password is incorrect, please type the correct password and try again.")
            } else if (e.code === "InvalidParameterException"){
                this.setState({
                    passwordError: true
                })
            } else if (e.code === 'UserNotFoundException') {
                alert("We do not recognize your email address. If you want access to the platform, please click on Be a part of the community link and fill the form.")
            } else if (e.code === "InvalidPasswordException") {
                this.setState({
                    passwordError: true
                })
            } else {
                alert("Please contact the STAT team for support")
                console.log(e);
            }
            
        });
    } else {
        alert("Please fill in all fields.");
    }

    }

    componentWillMount(){
        window.addEventListener('resize', this.checkForMobileMode.bind(this));
        this.checkForMobileMode();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkForMobileMode.bind(this));
    }

    render() {
        return (
            <div className='signInAndAgree'>
                <PrivacyPolicyModal
                    isOpen={this.state.showPolicy} 
                    onRequestClose={() => this.closeModal('showPolicy')}
                />

                <TermsOfServiceModal 
                    isOpen={this.state.showTerms} 
                    onRequestClose={() => this.closeModal('showTerms')}
                />

                <LeftSideBar page={'signup'} />

                <div className='innerPage'>
                    <p className='color-b pageTitle'>Sign in</p>
                    <p className='color-b mainText'>By signing in for the first time you are agreeing to the 
                        <span className='color-pk agreeModalLinks' onClick={() => this.setState({ showTerms: true })}> user agreement </span> 
                        and 
                        <span className='color-pk agreeModalLinks' onClick={() => this.setState({ showPolicy: true })}> privacy policy </span>
                    </p>
                    
                    <form className='form' onSubmit={this.handleSubmit}>

                        <FormGroup controlId='name' bsSize='large'>
                            <FormControl
                                className={'actionInput blue email'}
                                type='text'
                                value={this.state.name}
                                placeholder={'Name'}
                                onChange={this.handleChange}
                            />
                        </FormGroup>

                        <FormGroup controlId='email' bsSize='large'>
                            <FormControl
                                className={'actionInput blue email'}
                                type='email'
                                value={this.state.email}
                                placeholder={'Email'}
                                onChange={this.handleChange}
                            />
                        </FormGroup>

                        <FormGroup controlId='citystate' bsSize='large'>
                            <FormControl
                                className={'actionInput blue email'}
                                type='text'
                                value={this.state.citystate}
                                placeholder={'City and State'}
                                onChange={this.handleChange}
                            />
                        </FormGroup>

                        <FormGroup controlId='country' bsSize='large'>
                            <FormControl
                                className={'actionInput blue email'}
                                type='text'
                                value={this.state.country}
                                placeholder={'Country'}
                                onChange={this.handleChange}
                            />
                        </FormGroup>

                        <FormGroup controlId='tempPassword' bsSize='large'>
                        <FormControl
                            className={this.state.passwordError ? 'actionInput blue error' : 'actionInput blue'}
                            type='password'
                            value={this.state.tempPassword}
                            placeholder={'Type your temporary STAT password here'}
                            onChange={this.handleChange}
                            ref={(passwordInput) => this.passwordInput = passwordInput} 
                            />
                        </FormGroup>

                        <FormGroup controlId='newPassword' bsSize='large'>
                        <FormControl
                            className={this.state.passwordError ? 'actionInput blue error' : 'actionInput blue'}
                            type='password'
                            value={this.state.newPassword}
                            placeholder={'Type your NEW password here'}
                            onChange={this.handleChange}
                            ref={(passwordInput) => this.passwordInput = passwordInput} 
                            />
                        </FormGroup>

                        <Overlay 
                            target={this.passwordInput} 
                            show={this.state.passwordError} 
                            onHide={(e) => this.toggleToolTip(e, 'passwordError')}
                            placement={'top'}
                            rootClose={true}
                        >
                            <Tooltip 
                                id={'passwordTooltip'} 
                                className='toolTips forRequestAccess' 
                            >
                                <p>Your password should be a minimum of eight characters in length and contain at least one character from four of the following categories: One uppercase letter, One lowercase letter, One Digit, One special character</p>
                            </Tooltip>
                        </Overlay>

                        <Checkbox
                            name='newsletterCheckbox' 
                            className={this.state.newsletter ? 'options color-pk selected checked' : 'options color-b'}
                            value={this.state.newsletter}
                            // key={''}
                            checked={this.state.newsletter ? 'checked' : ''}
                            readOnly
                            onClick={this.toggleNewsletter}
                        >   
                            <span className='checkmark marginLeft'></span>
                            
                            <span className='color-b light key'>Sign up for newsletter and notifications</span>  
                        </Checkbox>

                        <Button type='submit' className='signInAgreeBtn' onClick={this.handleSubmit}><span>Continue</span></Button>
                    </form>
                </div>
                <Footer page={'signup'} />
            </div>
        )
    }
}