import React, { Component } from 'react';
import { Button } from 'react-bootstrap'

export class ChangeCardLayout extends Component {

    render() {
        return (
                <Button 
                    className={this.props.layoutCards ? 'changeCardLayout cards' : 'changeCardLayout list'}
                    onClick={this.props.toggleCardLayout}
                />
        )
    }
}