import React, { Component } from 'react';

export class FooSidebar extends Component {
    render(){
        return(
            <div className='fooSidebar'>
                <ul className='ul'>
                    <li className='wrappers'>
                        <div className='colors green'></div>
                    </li>
                    <li className='wrappers'>
                        <div className='colors orange'></div>
                    </li>
                    <li className='wrappers'>
                        <div className='colors purple'></div>
                    </li>
                </ul>
            </div>
        )
    }
}
