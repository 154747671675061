import React, { Component } from 'react';
import { LeftSideBar } from '../../Components/LeftSideBar';
import { RightSideBar } from '../../Components/RightSideBar';
import { Footer } from '../../Components/Footer';

const breaks = <span><br></br><br></br></span>;

export class AboutUs extends Component {
    constructor(props){
        super(props);
        this.state = {
            mobileMode: false,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render(){
        return(
            <div id='aboutUs'>
                <LeftSideBar page={'aboutus'} />
                <RightSideBar page={'aboutus'} userHasAuthenticated = {this.props.userHasAuthenticated} />
                
                <div className='innerPage aboutInner'>
                    <p className='title'>About us</p>
                    <p className='color-b bodyText'>STAT stands for Standing Together Against Trafficking.  The STAT platform unites the financial crime community in disrupting the financial networks of human trafficking.
                    {breaks}
                    The platform provides a central repository of vetted human trafficking indicators. As the go-to source for financial institutions, law enforcement and NGOs, the platform allows us to share a common language and access up-to-date resources around isolating human trafficking evidence.
                    {breaks}
                    Within the platform, financial indicators are organized and mapped in a way that is tailored to financial crime professionals. In addition to exploring existing indicators, users can also contribute indicators to the platform as well. 
                    {breaks}
                    Human trafficking is a tragedy and a $150 billion business (ilo.org).  Financial institutions are well-placed to fight the scourge. Illicit industries like human trafficking rely on financial institutions to launder their profits. Many financial professionals are aware of their potential impact and join efforts to fight against it, by organizing trainings, developing typologies and more. During a public/private working group organized by Polaris and ACAMS, the concept of a technology platform that facilitates and enriches typology sharing, red flags and indicators was developed. Thus the concept of the STAT platform was born. 
                    {breaks}
                    As a data linking company committed to using data for good, Enigma built the platform that brings together valuable human trafficking indicators from many sources, including:  outcomes from typology working groups, published reports about human trafficking methodologies, intelligence from law enforcement and research groups, public data and more.
                    {breaks}
                    STAT’s ultimate goal is to put effectively-formatted, up-to-date human trafficking indicator information into the hands of financial institutions, law enforcement and NGOs, providing critical insights so they can act quickly to save lives.
                    {breaks}
                    “Alone we can do so little. Together we can do so much.” – Helen Keller
                    </p>
                </div>
    
                <Footer />
            </div>
        )
    }
}