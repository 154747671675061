const prod = {
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://ewts9hpvcl.execute-api.us-east-1.amazonaws.com/prod/"
    },
    s3: {
      REGION: "us-east-1",
      BUCKET: "application-stat-backend-pro-statindicatorsbucket-5mcq0fqt0s3"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_uREF91XDg",
      APP_CLIENT_ID: "6om1dchs27c4rp3d637gh7p0ct",
      IDENTITY_POOL_ID: "us-east-1:92a187d0-5324-4058-a9a4-2a7454b92b1b"
    }
  };
  
  const config = prod;
  
  export default {
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
  };
  