import React, { Component } from 'react';
import { LeftSideBar } from '../../Components/LeftSideBar';
import { RightSideBar } from '../../Components/RightSideBar';
import { ICardsContainer } from '../../Components/ICardsContainer';
import { ICardModal } from '../../Components/ICardModal';
import { SortICards } from '../../Components/SortICards';
import { SortByClass } from '../../Components/SortByClass';
import { ChangeCardLayout } from '../../Components/ChangeCardLayout';
import { DownloadMultipleBtn } from '../../Components/DownloadMultipleBtn';
import { AuthFooter } from '../../Components/AuthFooter';

import { API } from 'aws-amplify';
import { CSVLink } from "react-csv";

export class Bookmarks extends Component {
    constructor(props){
        super(props);
        this.state = {
            mobileMode: false,
            containerLoading: true,

            activeClassification: 'all',
            newClassification: false,
            categoriesUpdated: false,
            sortBy: 'Recent',
            sortByOpen: false,
            layoutCards: true,

            infoFromModal: false,
            showDetailModal: false,
            iCardsToBeDownloaded: [],

            currentPage: 1,
            cardsPerPage: 6,
        }

        this._isMounted = false
    }

    checkForMobileMode = () => {
        this.setState({ mobileMode: window.innerWidth <= 620 });
    }


    sortNewClass = (newClass) => { // Only show cards based off indicator type
        let newCards = [];

        this.allCards.map((item) => {
            let indicatorData = [];

            if (item.indicatorType) {
                indicatorData = item.indicatorType;
            }

            if (indicatorData.length) {
                if (indicatorData.includes(newClass)) { 
                    return newCards.push(item); // Push this indicator obj to newClassCards array
                }
            }
            return console.log();
        });

        this.setState({
            newClassification: true,
            newClassCards: newCards,
            activeClassification: newClass
        })
    }

    toggleSortBy = () => {
        this.setState({
            sortByOpen: !this.state.sortByOpen
        })
    }

    sortByDate = (type, newLoad) => {
        let sorted = this.sortedCards.sort((a, b) => { // b - a = Recent, a - b = Oldest
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            let submittedA = a.dateIndicatorSubmitted;
            let submittedB = b.dateIndicatorSubmitted;
            let arrayA = submittedA.split('/');
            let arrayB = submittedB.split('/');

            let months = [ "Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec" ];
            let monthForA = Number(submittedA[1]) - 1;
            let monthForB = Number(submittedB[1]) - 1;
            const textMonthA = months[monthForA];
            const textMonthB = months[monthForB];

            let dayForA = arrayA[0];
            let dayForB = arrayB[0];
            let yearForA = arrayA[2];
            let yearForB = arrayB[2];
            let dateA = textMonthA + '/' + dayForA + '/' + yearForA;
            let dateB = textMonthB + ' ' + dayForB + ', ' + yearForB;
        

            let newDateB = new Date(dateB);
            let newDateA = new Date(dateA); 

            if (type === 'Recent') {
                return newDateB - newDateA;
            } else if (type === 'Oldest') {
                return newDateA - newDateB;
            }

            return console.log();
        });

        this.setState({
            sortBy: type
        })

        this.sortedCards = sorted;

        if (newLoad === 'newLoadTrue'){
             return
        } else {
            this.toggleSortBy()
        }
    }

    mostViews = () => {
        let sorted = this.sortedCards.sort((a, b) => { // b - a = most views, descending 
            let viewA = Number(a.viewCount);
            let viewB = Number(b.viewCount);    
            return viewB - viewA; 
        });
        this.setState({
            sortBy: 'Most viewed'
        })

        this.sortedCards = sorted;

        this.toggleSortBy();
    }

    toggleCardLayout = () => {
        this.setState({
            layoutCards: !this.state.layoutCards
        })
    }



    selectToDownload = (obj) => {
        this.setState({
            iCardsToBeDownloaded: [...this.state.iCardsToBeDownloaded, obj]
        })
        // let addCard = this.state.iCardsToBeDownloaded.concat(obj)
       
        // this.setState(prevState => { 
        //     return { 
        //         numToDownload: prevState.numToDownload + 1,
        //         iCardsToBeDownloaded: addCard
        //     }
        // })
        console.log(this.state.iCardsToBeDownloaded)
    }

    removeDownload = (obj) => {
        var array = [...this.state.iCardsToBeDownloaded]; 
        var index = array.indexOf(obj)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({
                iCardsToBeDownloaded: array 
            })
        }
        console.log(this.state.iCardsToBeDownloaded)
    }

    downloadMultiple = () => {
        console.log(this.state.iCardsToBeDownloaded)
    }

    downloadiCard = (obj) => {
        console.log('download this one')
    }



    showDetailModal = (summary, id, date, source, financial, industry, customer, region, details, iType, transactionTime, traffickingType, views, downloads, fileUrl, bookmarked) => {
        this.setState({
            cardSummary: summary, 
            cardId: id, 
            cardDateSubmitted: date, 
            cardSource: source, 
            cardFinancial: financial, 
            cardIndustry: industry, 
            cardCustomer: customer, 
            cardRegion: region, 
            cardDetails: details, 
            cardiType: iType, 
            cardTransactionTime: transactionTime, 
            cardTraffickingType: traffickingType, 
            cardViews: views,
            cardDownloads: downloads,
            cardFileUrl: fileUrl,
            cardBookmarked: bookmarked,
        })

        setTimeout(() => {
            this.setState({
                showDetailModal: true,
            })
        }, 20)
    }

    closeDetailModal = () => {
        this.setState({
            showDetailModal: false,
        })
    }
    

    componentWillMount(){
        window.addEventListener('resize', this.checkForMobileMode.bind(this));
        this.checkForMobileMode();
    }


    componentDidMount () {
        this._isMounted = true;
        window.scrollTo(0, 0);

        API.get("statmvp", "getuserdata").then(response => {
            // console.log(response);
            const indicatorArray  = response.savedIndicators;
  
            if(indicatorArray && indicatorArray.length){
                // let indicators = indicatorArray;
                let indicators = indicatorArray.sort((a, b) => { // b - a = Recent
                    return new Date(b.dateIndicatorSubmitted) - new Date(a.dateIndicatorSubmitted); 
                });

                if (this._isMounted) {
                     this.setState({
                        cards: indicators,
                    })
                }
            }
        })

        setTimeout(() => {
            if(this._isMounted){
                this.setState({
                    containerLoading: false
                })
            }
        }, 3000);
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.checkForMobileMode.bind(this));
    }


    render() {
        
        if ( this.state.cards) {
            this.allCards = this.state.cards; // extra array for all cards to be sorted by the user
            this.sortedCards = this.state.cards;
        } else {
            this.sortedCards = [];
        }
        

        if (this.state.newClassification) {
            this.sortedCards = this.state.newClassCards;
        } 

        return (
            <div id='bookmarks'>
                <LeftSideBar page={'bookmarks'} />
                <RightSideBar page={'bookmarks'} userHasAuthenticated = {this.props.userHasAuthenticated} />

                <div className='innerPage bookmarksInner'>
                    <p className='color-b bold pageTitle'>Saved indicators</p>
                    
                    <div className='flexRow rowEnd smallSortWrapper'>
                        <SortByClass
                            size={'small'}
                            sortNewClass={this.sortNewClass}
                            activeClassification={this.state.activeClassification}
                        />

                        <SortICards 
                            mobileMode={this.state.mobileMode}
                            sortByOpen={this.state.sortByOpen}
                            sortBy={this.state.sortBy}
                            actions={{
                                sortByDate: this.sortByDate,
                                mostViews: this.mostViews,
                                toggleSortBy: this.toggleSortBy,
                            }}
                        />

                        {!this.state.mobileMode &&
                            <ChangeCardLayout
                                layoutCards={this.state.layoutCards}
                                toggleCardLayout={this.toggleCardLayout}
                            />
                        }
                    </div> {/* .smallSortWrapper */}


                    {this.sortedCards && this.sortedCards.length === 0 ?
                        <div>
                            <p className='color-b light'>You currently do not have any saved indicators.</p>
                        </div>
                    :
                        <div>
                            <ICardsContainer
                                mobileMode={this.state.mobileMode}
                                cards={this.sortedCards}
                                containerLoading={this.state.containerLoading}
                                bookmarksPage={true}
                                layoutCards={this.state.layoutCards}
                                actions={{
                                    selectToDownload: this.selectToDownload,
                                    removeDownload: this.removeDownload,
                                    downloadiCard: this.downloadiCard,
                                    showDetailModal: this.showDetailModal,
                                }}
                            />

                            {this.state.iCardsToBeDownloaded.length >= 1 &&
                                <CSVLink data={this.state.iCardsToBeDownloaded} target="_blank" filename={"stat-indicator.csv"}>
                                    <DownloadMultipleBtn
                                        numToDownload={this.state.iCardsToBeDownloaded.length}
                                        downloadMultiple={this.downloadMultiple}
                                    />
                                </CSVLink>
                            }
                        </div>
                    }
                </div> {/* .bookmarksInner */}

                {this.state.showDetailModal &&
                    <ICardModal
                        // isOpen={this.state.showModal} 
                        // onRequestClose={this.closeModal}
                        actions={{
                            closeDetailModal: this.closeDetailModal,
                        }}
                        summary={this.state.cardSummary}
                        details={this.state.cardDetails}
                        id={this.state.cardId}
                        dateSubmitted={this.state.cardDateSubmitted}
                        source={this.state.cardSource}
                        iType={this.state.cardiType}
                        financial={this.state.cardFinancial}
                        customer={this.state.cardCustomer}
                        region={this.state.cardRegion}
                        industry={this.state.cardIndustry}
                        traffickingType={this.state.cardTraffickingType}
                        transactionTime={this.state.cardTransactionTime}
                        docs={this.state.cardFileUrl}
                        views={this.state.cardViews}
                        downloads={this.state.cardDownloads}
                        fileUrl={this.state.cardFileUrl}
                        bookmarked={this.state.cardBookmarked}
                    /> 
                }

                <AuthFooter />
            </div>
        )
    }
}