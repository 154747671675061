import React, { Component } from 'react';
// import { 
//     Grid, 
//     Row, 
//     Col } from 'react-bootstrap';
import { resourcesData } from './ResourcesData';
import { ResourcesCard } from './ResourcesCard';
import { PaginationBtns } from './PaginationBtns';

export class ResourcesContainer extends Component {
    constructor(props){
        super(props);
        this.state = {
            mobileMode: false,
            containerLoading: true,
            currentPage: 1,
            cardsPerPage: 6,
        }
        this._isMounted = false;
    }

    checkForMobileMode = () => {
        this.setState({ mobileMode: window.innerWidth <= 620 });
    }


    getResourcesHeight = () => {
        // Wait 3 second for resources to load so we can get the full length of the container and divide that number by 1.5 to create 2 columns
        if (this.state.mobileMode) {
            this.setState({
                resourcesHeight: '100%',
                containerLoading: false
            })
        } else {
            setTimeout(function () { 
                if (this._isMounted) {
                    let height = this.rElement.clientHeight / 1.80;
                    this.setState({ resourcesHeight: height });
                } else {
                    return
                }
            }.bind(this), 2000)
    
            setTimeout(function () { 
                if (this._isMounted) {
                    this.setState({ 
                        containerLoading: false
                    })
                } else {
                    return
                }
            }.bind(this), 2200)
        }
        
    }


    prevPage = () => {
        if (this.state.currentPage === 1){
            return
        } else {
            let number = this.state.currentPage - 1;
            this.setState({
                currentPage: number,
                resourcesHeight: '100%',
            });
        }

        if (this.state.mobileMode) {
            return
        } else {
            setTimeout(() => { 
                let height = this.rElement.clientHeight / 1.80;
                this.setState({ resourcesHeight: height });
            }, 100)
        }
        
    }

    changePage = (e) => {
        // let lastPage; // need if container display is set to flex column

        // if (this.cards) {
        //     lastPage = Math.ceil(this.cards.length / this.state.cardsPerPage)
        // }
        
        this.setState({
            currentPage: Number(e.target.id),
            resourcesHeight: '100%',
        });

        if (this.state.mobileMode) {
            return
        } else {
            setTimeout(() => { 
                let height = this.rElement.clientHeight / 1.80;
                this.setState({ resourcesHeight: height });
                // console.log(height);
            }, 100)
        }
       
    //     setTimeout(() => {
    // // need if container display is set to flex column
    //     // // If current cards <= 3 change the height of the container
    //         let indexOfLastCard = this.state.currentPage * this.state.cardsPerPage;
    //         let indexOfFirstCard = indexOfLastCard - this.state.cardsPerPage;
    //         let currentCards = resourcesData.slice(indexOfFirstCard, indexOfLastCard)

    //         if (this.state.currentPage === lastPage && currentCards.length <= 3) {
                
    //             let height = this.cardsContainer.clientHeight / 1.7;
    //             this.setState({ 
    //                 cardsContainerHeight: height
    //             });
    //         } 
    //     }, 10)
    }

    nextPage = () => {
        if (this.pageNumbers){
            if (this.state.currentPage < this.pageNumbers.length){
                let number = this.state.currentPage + 1;
                this.setState({
                    currentPage: number,
                    resourcesHeight: '100%'
                });
            } else {
                return
            }
        }

        if (this.state.mobileMode) {
            return
        } else {
            setTimeout(() => { 
                let height = this.rElement.clientHeight / 1.80;
                this.setState({ resourcesHeight: height });
            }, 100)
        }
        
    }

    componentWillMount(){
        window.addEventListener('resize', this.checkForMobileMode.bind(this));
        this.checkForMobileMode();
    }

    componentDidMount(){
        this._isMounted = true;
        this._isMounted && this.getResourcesHeight();
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.checkForMobileMode.bind(this));
    }


    render() {
        this.pageNumbers = [];
        let resources = [];
    //*** Logic for displaying 6 Indicators on page at a time */
        if(resourcesData && resourcesData.length) {
            let indexOfLastCard = this.state.currentPage * this.state.cardsPerPage;
            let indexOfFirstCard = indexOfLastCard - this.state.cardsPerPage;
            let currentCards = resourcesData.slice(indexOfFirstCard, indexOfLastCard);

            resources = currentCards.map((item, index) => { 
                return (
                    <div className='resourcesWrappers' key={item.id}>
                        <ResourcesCard
                            id={item.id}
                            obj={item}
                            placement={this.props.placement}
                            // desktopMode={this.state.desktopMode}
                        />
                    </div>
                )
            })
        }

        if (resourcesData && resourcesData.length) {
            for (let i = 1; i <= Math.ceil(resourcesData.length / this.state.cardsPerPage); i++) {
                this.pageNumbers.push(i);
            }
        } else {
                this.pageNumbers = [];
        }

        return (
            <div>
                {this.state.containerLoading && 
                    <span className='color-b'>loading...</span>
                } 

                <div 
                    id='resourcesContainer' 
                    className={this.state.containerLoading ? 'resourcesContainer loading' : 'resourcesContainer'}
                    ref={ (rElement) => this.rElement = rElement} 
                    style={{ maxHeight: this.state.resourcesHeight }}
                >
                    {resources.length ? resources : null}
                </div>

                <PaginationBtns
                    mobileMode={this.state.mobileMode}
                    pageNumbers={this.pageNumbers}
                    currentPage={this.state.currentPage}
                    actions={{
                        prevPage: this.prevPage,
                        nextPage: this.nextPage,
                        changePage: this.changePage,
                        getPaginationNumbers: this.getPaginationNumbers
                    }} 
                />
            </div>
        )
    }
}