import React, { Component } from 'react';
import { 
    Button,
    OverlayTrigger, // shows and hides on/off hover
    Tooltip } from 'react-bootstrap';


export class SortByClass extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }


    render() {
        if(this.props.size === 'small') {
            return (
            
                <div className='flexRow smallBtn'>
                    
                    <OverlayTrigger 
                        placement={'bottom'}
                        className='pointer'
                        overlay={
                            <Tooltip 
                                id={'iTypeTooltip-t'} 
                                className='iTypeTooltip'
                            >
                                <p className='color-w light noMargin'>Transactional</p>
                            </Tooltip>
                        }
                    >
                        <Button 
                            id={'t'} 
                            className={this.props.activeClassification === 'Transactional' || this.props.activeClassification === 'all' ? 'flexRow typePills pointer active' : 'flexRow typePills pointer inactive'} 
                            onClick={() => this.props.sortNewClass('Transactional')}
                        >
                            {/* {this.props.size === 'large' ? */}
                                {/* <span className='color-w'>Transactional</span> */}
                            {/* : */}
                                <span className='color-w uppercase'>T</span>
                            {/* } */}
                        </Button>
                    </OverlayTrigger>
    
                    <OverlayTrigger 
                        className='pointer'
                        placement={'bottom'}
                        overlay={
                            <Tooltip 
                                id={'iTypeTooltip-d'} 
                                className='iTypeTooltip'
                            >
                                <p className='color-w light noMargin'>Demographic</p>
                            </Tooltip>
                        }
                    >
                        <Button 
                            id={'d'} 
                            className={this.props.activeClassification === 'Demographic' || this.props.activeClassification === 'all' ? 'flexRow typePills pointer active' : 'flexRow typePills pointer inactive'}  
                            onClick={() => this.props.sortNewClass('Demographic')}
                        >
                            {/* {this.props.size === 'large' ? */}
                                {/* <span className='color-w'>Demographic</span> */}
                            {/* : */}
                                <span className='color-w uppercase'>D</span>
                            {/* } */}
                        </Button>
                    </OverlayTrigger>
    
                    <OverlayTrigger 
                        placement={'bottom'}
                        overlay={
                            <Tooltip 
                                id={'iTypeTooltip-b'} 
                                className='iTypeTooltip'
                            >
                                <p className='color-w light noMargin'>Behavioral</p>
                            </Tooltip>
                        }
                    >
                        <Button 
                            id={'b'}
                            className={this.props.activeClassification === 'Behavioral' || this.props.activeClassification === 'all' ? 'flexRow typePills pointer active' : 'flexRow typePills pointer inactive'}  
                            onClick={() => this.props.sortNewClass('Behavioral')}
                        >
                            {/* {this.props.size === 'large' ? */}
                                {/* <span className='color-w'>Behavioral</span> */}
                            {/* : */}
                                <span className='color-w uppercase'>B</span>
                            {/* } */}
                        </Button>
                    </OverlayTrigger>
                </div>
            )
        } else if (this.props.size === 'large') {
            return (
                <div className='flexRow largeBtn'>
                    <Button 
                        id={'t'} 
                        className={this.props.activeClassification === 'Transactional' || this.props.activeClassification === 'all' ? 'flexRow typePills pointer active' : 'flexRow typePills pointer inactive'} 
                        onClick={() => this.props.sortNewClass('Transactional')}
                    >
                        {/* {this.props.size === 'large' ? */}
                            <span className='color-w'>Transactional</span>
                        {/* // : */}
                            {/* // <span className='color-w uppercase'>T</span> */}
                        {/* // } */}
                    </Button>

                    <Button 
                        id={'d'} 
                        className={this.props.activeClassification === 'Demographic' || this.props.activeClassification === 'all' ? 'flexRow typePills pointer active' : 'flexRow typePills pointer inactive'}  
                        onClick={() => this.props.sortNewClass('Demographic')}
                    >
                        {/* {this.props.size === 'large' ? */}
                            <span className='color-w'>Demographic</span>
                        {/* : */}
                            {/* <span className='color-w uppercase'>D</span> */}
                        {/* } */}
                    </Button>
                    
                    <Button 
                        id={'b'}
                        className={this.props.activeClassification === 'Behavioral' || this.props.activeClassification === 'all' ? 'flexRow typePills pointer active' : 'flexRow typePills pointer inactive'}  
                        onClick={() => this.props.sortNewClass('Behavioral')}
                    >
                        {/* {this.props.size === 'large' ? */}
                            <span className='color-w'>Behavioral</span>
                        {/* : */}
                            {/* <span className='color-w uppercase'>B</span> */}
                        {/* } */}
                    </Button>
                </div>
            )
        }
    }
}