import React, { Component } from 'react';
import Modal from 'react-modal';
import { Image } from 'react-bootstrap';

const breaks = <span><br></br><br></br></span>;

export class TermsOfServiceModal extends Component {
    // constructor(props){
    //     super(props);

    // }

    closePolicy = () => {
        this.props.onRequestClose();
    }

    componentWillMount(){
        Modal.setAppElement('body');// So screen readers can see modals
    }

    render(){
        return(
            <div className='termsOfService'>
                <Modal
                    {...this.props}
                    // onAfterOpen={this.afterOpenModal}
                    // shouldCloseOnOverlayClick={true}
                    className='innerModal termsModal'
                >
                    <div className='termsInner'>
                        <div className='closeWrapper'>
                            <Image
                                className='closeIcon'
                                width='auto'
                                height='auto'
                                src={require('../Styles/Images/pinkClose.png')}
                                onClick={this.closePolicy}
                            />
                        </div>
                        <p className='color-b bold title'>User agreement</p>
                        <p className='bodyText'>
                        <span className='bold'>Disclaimer: This user agreement form has been executed by your organization’s super administrator. If you have any questions about the terms herein please speak to your administrator.</span>
                        {breaks}
                        This STAT User Agreement ("Agreement") is entered into between Enigma Technologies Inc., (“Enigma”) a Delaware corporation with principal offices at 245 Fifth Avenue, 17th Floor, New York, NY, 10016 and ______________________________________________________________ (“User”), a _________________________________________________  ________________________________________ with principal offices located at ____________________________________________________. This Agreement is effective as of __________________, (the “Effective Date”).  
                        {breaks}

                        Now, for good and valuable consideration, the receipt and sufficiency of which is hereby acknowledged, the parties hereby agree as follows:
                       {breaks}
                    <span style={{fontWeight: 'bold'}}>1. 	Access and License Grant</span>
                       {breaks}
                        (a)	Subject to the terms of this Agreement, Enigma agrees to provide to User access to and use of the Enigma STAT (Standing Together Against Trafficking) software collaboration tool (“STAT”), a software-as-a-service (SaaS) platform designed to help institutions combat human trafficking. In connection therewith, Enigma hereby grants to User (and those User employees whom User has authorized to be end users of STAT) (each such individual authorized user referred to as “Authorized User” and collectively as “Authorized Users”) a limited, non-transferable, revocable, and non-exclusive license to use STAT strictly for User’s own internal business purposes subject to the terms and conditions of this Agreement. Enigma reserves all rights not expressly granted in this Agreement, and no licenses are granted by Enigma to User under this Agreement, whether by implication, estoppel or otherwise, except as expressly set forth in this Agreement. In addition to providing access to STAT, Enigma agrees establish User’s STAT account and to perform certain ongoing hosting and maintenance services related thereto.
                       {breaks}
                        (b)	By using STAT and contributing User data to the STAT system (“User Data”), User hereby grants to Enigma a non-exclusive, perpetual, irrevocable, worldwide, royalty-free right and license to use such User Data for purposes of providing the STAT service to User and other users, for inclusion on an aggregated basis within the STAT Data, and for internal research and development activities only. Enigma agrees that User Data will not be provided to any third party for any commercial purpose.
                       {breaks}
                        (c)	Access to STAT is currently provided at no charge to User. In the event that Enigma elects to establish a fee to users associated with the provision and maintenance of STAT, Enigma will provide User with no less than ninety (90) days’ advance written notice of its intention, during which time User may elect to discontinue its access to STAT with no further obligation to Enigma by providing written notice of same.
                       {breaks}
                        (c) Enigma may modify STAT and its associated features and functionality in its sole discretion at any time.
                       {breaks}
                       <span style={{fontWeight: 'bold'}}>2. 	Conditions of Access</span>
                       {breaks}
                        (a) User shall ensure that all of its Authorized Users understand and fully abide by the conditions of access, ownership and confidentiality obligations set forth in this Agreement, and User assumes full responsibility and liability for any damages or losses caused by any Authorized User’s failure to comply therewith or any Authorized User’s misuse in any respect of the access and use rights granted herein. In the event that User suspects or becomes aware that any unauthorized users are accessing STAT, User shall immediately notify Enigma and promptly undertake all appropriate security and remedial measures to halt such unauthorized access.
                       {breaks}
                        (b) User agrees to safeguard all user authorization or identification codes or numbers, passwords, authentication keys and other security devices relating to access to STAT (collectively, “Passwords”) with the highest degree of care and confidentiality, and to use any Passwords permitting access to STAT only in accordance with Enigma’s instructions. User also agrees that (i) passwords are non-transferable and intended for exclusive use by User; and (ii) passwords shall be issued to only Authorized Users.  Prior to being permitted access to STAT or to receiving any set up support for User’s use of STAT, User shall be required to complete the Institution Set Up Form (“Set Up Form”) attached hereto as Appendix 1. In order to make any changes to the Set Up Form Enigma has on file, including, without limitation, a change to the Super Administrator, the signatory of the Set Up Form or an individual having equivalent authorization on behalf of User (the “User Signatory”) must approve such changes in writing by completing, signing and submitting to Enigma a Set Up Change Request Form, which Enigma shall furnish to User upon request. 
                       {breaks}
                        (c) User shall bear all expenses arising out of or relating to its access or use of STAT, including, but not limited to, internet access and computer hardware, software, or any other equipment. Enigma is not responsible for the reliability or continued availability of any telephone lines or communications equipment, hardware or software used by User in accessing and interacting with STAT. 
                       {breaks}
                        (d) User shall not remove any of Enigma’s or its licensors’ or contractors’ copyrights, patents, trademarks, or other proprietary notices contained in STAT or in any documentation or materials made available through use of STAT. 
                       {breaks}
                        (e) User and its Authorized Users may not access or use STAT in any manner that (i) materially interferes with or harms STAT, its associated hosting environment, or any third parties, including any contractors or other customers of Enigma or any other users of STAT, (ii) introduces any viruses or other malicious code into STAT or its associated hosting environment, (iii) is fraudulent, defamatory or otherwise tortious, or (iv) violates any applicable law, regulation or rule or third party right, including, without limitation, the intellectual property rights of any third party. User agrees to defend, indemnify and hold Enigma and its contractors harmless from and against third party claims, losses, damages, liabilities, costs and expenses, including, without limitation, reasonable attorneys' fees arising from non-compliance with any or all of the obligations and restrictions set forth in the preceding sentence.
                       {breaks}
                        (f) User agrees not to sell, resell, sublicense, rent, copy, distribute, modify or create derivative works of, reverse engineer, reverse compile, disassemble, or in any way attempt to derive the source code of STAT, any data contained in or redistributed via STAT (the “STAT Data”), or any other software, data, or other proprietary information owned by Enigma or any third party. 
                       {breaks}
                       <span style={{fontWeight: 'bold'}}>3. 	Ownership</span>
                       {breaks}
                        (a)	As between Enigma and User, Enigma and/or its licensors or contractors, as applicable, exclusively retain and hold all rights, title and interest in and to STAT the STAT Data (except to the extent it contains identifiable User Data), and any derivative works and improvements thereon (as each of those terms is defined and applied under Title 17 and Title 35 U.S.C., respectively,) including all intellectual property and proprietary rights therein (including patents, trademarks, copyrights and trade secrets) throughout the world.
                       {breaks} 
                        (b)	User shall maintain and hold all right, title, and interest in and to the User Data, subject to the license granted herein.
                       {breaks}
                       <span style={{fontWeight: 'bold'}}>4. 	Disclaimers</span>
                       {breaks}
                        USER ACKNOWLEDGES AND AGREES THAT STAT, THE STAT DATA, AND ANY SERVICES PROVIDED BY ENIGMA IN CONNECTION THEREWITH ARE PROVIDED "AS IS," “AS AVAILABLE,” AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, OR AS TO ANY RESULTS TO BE ACHIEVED THEREFROM.  ENIGMA DOES NOT REPRESENT OR WARRANT THAT STAT, THE STAT DATA, OR ANY ASSOCIATED SERVICES ARE OR WILL BE ERROR-FREE, INTERRUPTION-FREE, COMPLETE, OR FREE FROM LOSS OR DAMAGE ARISING OUT OF HACKING OR SIMILAR MALICIOUS ACTIVITY, OR ARISING OUT OF ANY ACT OR OMISSION OF USER, INCLUDING WITH RESPECT TO ANY USER INFORMATION THAT IS NOT ENCRYPTED BY USER, WHETHER IN TRANSIT OR AT REST.  USER EXPRESSLY AGREES THAT ITS CHOICE TO PROVIDE ANY DATA TO ENIGMA IN CONNECTION WITH ITS USE OF STAT IS AT USER’S SOLE RISK AND ENIGMA BEARS NO RESPONSIBILITY FOR ANY LOSS RESULTING FROM ITS USE WITHIN STAT. USER IS SOLELY RESPONSIBLE FOR ITS DATA AND RESULTS VALIDATION IN ANY REPORTS OR OTHER OUTPUT GENERATED BY OR ON BEHALF OF USER AND FOR RETAINING BACKUP COPIES OF ALL DATA OR OTHER INFORMATION STORED OR PROCESSED VIA STAT.
                       {breaks}
                        WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, USER FURTHER AGREES THAT USER, AND NOT ENIGMA, IS RESPONSIBLE FOR ANY DECISIONS MADE USING STAT OR THE STAT DATA, INCLUDING, WITHOUT LIMITATION, ANY DECISIONS WITH RESPECT TO USER ACCOUNTS, USER CLIENTS, USER REGULATORY OBLIGATIONS OR REPORTING, AND ANY OTHER BUSINESS OUTCOME INFORMED BY OR EFFECTUATED AS A RESULT OF STAT, THE STAT DATA, OR REPORTS GENERATED THEREIN OR THEREBY. NEITHER ENIGMA NOR ANY OTHER USER OF STAT BEARS ANY RESPONSIBILITY FOR ANY SUCH DECISION OR OUTCOME.
                       {breaks}
                       <span style={{fontWeight: 'bold'}}>5. 	Limitation of Liability</span>
                       {breaks}
                        IN NO EVENT SHALL ENIGMA OR ANY OTHER USER OF STAT BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, TORT, ECONOMIC, COVER, OR CONSEQUENTIAL DAMAGES, OR ANY DIRECT OR OTHER FORM OF DAMAGES FOR LOSS OF PROFITS, BUSINESS, OR GOODWILL, ARISING OUT OF THIS AGREEMENT OR USER’S USE OF, OR INABILITY TO USE, STAT, THE STAT DATA, OR ANY OF THE SERVICES RELATED THERETO, EVEN IF ENIGMA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  USER ACKNOWLEDGES AND AGREES THAT THE DISCLAIMERS AND LIMITATION ON DAMAGES AND LIABILITY PROVISIONS OF THIS AGREEMENT ARE FAIR AND REASONABLE.
                       {breaks}
                       <span style={{fontWeight: 'bold'}}>6. 	Term and Termination</span>
                       {breaks}
                        (a)	The term of this Agreement (“Term”) will commence on the Effective Date and continue in perpetuity until and unless terminated in accordance with this Agreement. 
                       {breaks} 
                        (b)	Enigma or User may terminate this Agreement and the access provided hereunder at any time for their convenience by providing ten (10) days prior written notice to the other party.  In the event of a material breach of this Agreement and/or any breach of Section 2 (Conditions of Access), Enigma may suspend or terminate User’s access to STAT immediately, without prior notice, but shall provide written notice as soon as practicable thereafter. Upon any such termination or expiration, User: (a) shall immediately discontinue all access to and use of STAT; and (b) immediately return or destroy all Enigma Confidential Information in its possession, together with all copies, and certify to such return or destruction in writing. Notwithstanding the foregoing, User agrees that any User Data provided by User to Enigma for use within STAT shall remain within the STAT system and available to other users in perpetuity.
                       {breaks}
                        (c)	Sections 2, 3, 4, 5, 6 (according to its terms), 7, and 8 of this Agreement shall survive the expiration or termination of this Agreement. 
                       {breaks}
                       <span style={{fontWeight: 'bold'}}>7. 	Confidentiality; Security and Compliance</span>
                       {breaks}
                        (a) For purposes of this Agreement, “Confidential Information” shall mean any information of a confidential or proprietary nature which is disclosed to the receiving party by the disclosing party pursuant to this Agreement, including, but not limited to, all technical and non-technical data, engineering information, know-how, software (including source code, object code, models and algorithms), ideas, marketing plans, financial projections, methodologies, passwords and any other information that may be non-public, confidential or proprietary in nature or of significant commercial value. Confidential Information may be disclosed in writing, electronically, orally, by visual presentation, demonstration or by other means. Confidential Information shall not include any information or data that: (i) is in or becomes part of the public domain by any means other than the receiving party’s breach of its obligations hereunder, or (ii) was known to the receiving party at the time of disclosure by the disclosing party as evidenced by the receiving party’s records, or (iii) is, at any time, disclosed to the receiving party by any third party having the right to disclose the same, or (iv) is independently developed by the receiving party without use of or reference to any Confidential Information disclosed by the disclosing party pursuant to this Agreement.
                       {breaks}
                        (b)	Each party shall preserve the Confidential Information of the other party and shall not, without first obtaining the other party’s written consent, disclose to any person or organization (unless such person or organization has a need to know in order for the receiving party to perform its obligations or exercise its rights under this Agreement), or use for any purpose other than in connection with this Agreement, any such Confidential Information during the Term and for two (2) years following the expiration or termination hereof (except for trade secrets as to which the confidentiality obligation shall survive indefinitely). Each party will take all reasonable precautions to protect the other party's Confidential Information. If Confidential Information is required to be disclosed by law, regulations or court order by either party, such disclosure shall be permitted to the extent legally required, provided that the non-disclosing party has been given reasonable prior notice to enable it to seek a protective order or confidential treatment prior to such disclosure.  For the avoidance of doubt, User Data is Confidential Information of User but may be shared with any other user of STAT subject to the terms of this Agreement.
                       {breaks}
                        (c) 	Each party shall comply with all laws and regulations applicable to the provision (in the case of Enigma) and access to and use of (in the case of User) of STAT, including all applicable privacy laws and regulations.  If and to the extent Confidential Information consists of personally identifiable information related to User’s customers, including without limitation any “nonpublic personal information” as defined under the Gramm-Leach-Bliley Act of 1999 (Public Law 106-102, 113 Stat. 1338), as amended from time to time and regulations promulgated thereunder in any form.    
                       {breaks}
                       <span style={{fontWeight: 'bold'}}>8. 	Miscellaneous</span>
                       {breaks}
                        This Agreement shall be governed by and construed in accordance with the laws of the State of New York without regard to principles of conflicts of laws. All claims or actions arising hereunder shall be brought exclusively in the appropriate state or federal court located in New York County, State of New York. This Agreement, including all exhibits or other attachments hereto and all Subscription Orders, is the entire agreement of the parties with respect to User’s and its Authorized Users’ access to and use of STAT. If any provision of this Agreement is held unenforceable, the enforceability of the remaining provisions shall not be affected. Neither party's failure to insist upon strict performance of any provision of this Agreement shall be construed as a waiver of any of its rights hereunder. In no event shall this Agreement be governed by the United Nations Convention on Contracts for the International Sale of Goods. To the maximum extent permitted by the governing law, no transactions called for herein shall be governed or affected by any version of the Uniform Computer Information Transactions Act enacted in any jurisdiction. Neither party shall be liable for any delay or failure in performing hereunder if such delay or failure is caused by conditions beyond its control including, but not limited to, Acts of God, government restrictions, terrorism, wars, insurrections and/or any other cause beyond the reasonable control of the party whose performance is affected.
                        {breaks}
                        <span style={{fontWeight: 'bold'}}>IN WITNESS WHEREOF</span>, the parties hereto have executed this Agreement as of the Effective Date.
                        {breaks}
                        <div className='signInfoWrapper'>
                            <div className='signInfo one bold'>
                                ENIGMA TECHNOLOGIES, INC.
                                {breaks}
                                By:
                                {breaks}
                                _______________________
                                {breaks}
                                Printed Name
                                {breaks}
                                _______________________ 
                                {breaks}
                                Title  
                                {breaks}                                                   			
                                _______________________ 
                            </div>
                            <div className='signInfo bold'>
                                [USER]
                                {breaks}
                                By:
                                {breaks}
                                _______________________
                                {breaks}
                                Printed Name
                                {breaks}
                                _______________________
                                {breaks}
                                Title
                                {breaks}
                                _______________________
                            </div>
                        </div>
                        </p>
                    </div>
                </Modal>
            </div>
        )
    }
}