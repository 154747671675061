import React, { Component } from 'react';
import { LeftSideBar } from '../../Components/LeftSideBar';
import { RightSideBar } from '../../Components/RightSideBar';
import { ProfileFeedback } from './ProfileFeedback';
import { AuthFooter } from '../../Components/AuthFooter';
import { API, Auth } from 'aws-amplify';
// import {
//     FormGroup,
//     FormControl,
//     Image,
// } from 'react-bootstrap';


export class Profile extends Component {

    constructor(){
        super();
        this.state = {
            deleteModalOpen: false,
            unsubscribeModalOpen: false,
            name: null,
            email: null,
            industry: null,
            organization: null,
            userCityAndState: null,
            usreCountry: null,
            cityStateFocus: false,
            newCityAndState: '',
            countryFocus: false,
            newCountry: '',
        }

        this._isMounted = false;
    }

    cityStateFocus = () => {
        this.setState({
            cityStateFocus: true
        })
    }

    clickOutsideCityState = (e) => { 
        if (this._isMounted && this.state.cityStateFocus) {
            if(!this.cityStateElement.contains(e.target) && !this.state.newCityAndState.length) {
               this.setState({ cityStateFocus: false }); 
            }
        }
    }

    getCityState = (e) => {
        this.setState({
            newCityAndState: e.target.value
        })
    }

    countryFocus = () => {
        this.setState({
            countryFocus: true
        })
    }

    clickOutsideCountry = (e) => { 
        if (this._isMounted && this.state.countryFocus) {
            if(!this.countryElement.contains(e.target) && !this.state.newCountry.length) {
               this.setState({ countryFocus: false }); 
            }
        }
    }

    getCountry = (e) => {
        this.setState({
            newCountry: e.target.value
        })
    }

    openDeleteModal = () => {
        this.setState({
            deleteModalOpen: true
        })
    }

    closeDeleteModal = () => {
        this.setState({
            deleteModalOpen: false
        })
    }

    openUnsubscribeModal = () => {
        this.setState({
            unsubscribeModalOpen: true
        })
    }

    closeUnsubscribeModal = () => {
        this.setState({
            unsubscribeModalOpen: false
        })
    }


    componentDidMount () {
        this._isMounted = true;
        document.addEventListener('mousedown', this.clickOutsideCityState);
        document.addEventListener('mousedown', this.clickOutsideCountry);

        Auth.currentAuthenticatedUser().then(user => {
            this.setState({
                email: user.attributes.email,
            })
            API.get("statmvp", "getuserdata").then(response => {
                this.setState({
                    industry: response.industry,
                    organization: response.organization,
                    name: response.userName,
                    userCityAndState: response.citystate,
                    userCountry: response.country
                })
            }).catch(error => {
                console.log(error)
            });

             API.get("statmvp", "getsubmittedindicators").then(response => {
                this.setState({
                    submittedIndicators: response 
                })
            }).catch(error => {
                console.log(error)
            });

        }).catch(error => {
            console.log(error)
        });
        window.scrollTo(0, 0);
    }
    
    componentWillUnmount() {
        this._isMounted = false;
        document.removeEventListener('mousedown', this.clickOutsideCityState);
        document.removeEventListener('mousedown', this.clickOutsideCountry);
    }

    render() {

        return (
            <div id='profile'>
                <LeftSideBar page={'profile'} />
                <RightSideBar page={'profile'} userHasAuthenticated = {this.props.userHasAuthenticated} />

                <div className='innerPage profileInner'>
                    <p className='pageTitle'>My profile</p>
                    
                    {this.state.name &&
                        <div className='infoDivider name'>
                            <p className=' color-b smallText'>Name</p>
                            <p className='color-pk noMargin'>{this.state.name}</p>
                        </div>
                    }

                    {this.state.email &&
                        <div className='infoDivider email'>
                            <p className=' color-b smallText'>Email</p>
                            <p className='color-pk noMargin'>{this.state.email}</p>
                        </div>
                    }
                    {this.state.organization &&
                        <div className='infoDivider organization'>
                            <p className=' color-b smallText'>Organization</p>
                            <p className='color-pk noMargin'>{this.state.organization}</p>
                        </div>
                    }
                    {this.state.industry &&
                        <div className='infoDivider city'>
                            <p className=' color-b smallText'>Industry</p>
                            <p className='color-pk noMargin'>{this.state.industry}</p>
                        </div>
                    }
{/* City and state */}
                    {this.state.userCityAndState ?
                        <div className='infoDivider city'>
                            <p className=' color-b smallText'>City and state</p>
                            <p className='color-pk noMargin'>{this.state.userCityAndState}</p>
                        </div>
                    : null
                        // <div 
                        //     className='controlGroup city' 
                        //     ref={(cityStateElement) => this.cityStateElement = cityStateElement}
                        // >
                        //     <p className={this.state.cityStateFocus ? 'color-b noMargin absolutePlaceholders focus' : 'color-b noMargin absolutePlaceholders'}>City and state</p>
                        //     <form>
                        //         <FormGroup 
                        //             controlId='newCityAndState'
                        //         >
                        //             <FormControl
                        //                 data-lpignore='true'
                        //                 className='updateInput'
                        //                 type='text'
                        //                 value={this.state.newCityAndState}
                        //                 placeholder=''
                        //                 onClick={this.cityStateFocus}
                        //                 onChange={(e) => this.getCityState(e)}
                        //             />
                        //         </FormGroup>
                        //     </form>
                        // </div>
                    }
{/* Country */}
                    {this.state.userCountry ?
                        <div className='infoDivider country'>
                            <p className=' color-b smallText'>Country</p>
                            <p className='color-pk noMargin'>{this.state.userCountry}</p>
                        </div>
                        : null
                    }
                    {/* //     <div 
                    //         className='controlGroup country' 
                    //         ref={(countryElement) => this.countryElement = countryElement}
                    //     >
                    //         <p className={this.state.countryFocus ? 'color-b noMargin absolutePlaceholders focus' : 'color-b noMargin absolutePlaceholders'}>Country</p>
                    //         <form>
                    //             <FormGroup 
                    //                 controlId='newCountry'
                    //             >
                    //                 <FormControl
                    //                     data-lpignore='true'
                    //                     className='updateInput'
                    //                     type='text'
                    //                     value={this.state.newCountry}
                    //                     placeholder=''
                    //                     onClick={this.countryFocus}
                    //                     onChange={(e) => this.getCountry(e)}
                    //                 />
                    //             </FormGroup>
                    //         </form>
                    //     </div>
                    // } */}
                
                
                    <p className='color-b deleteNote light'>Contact us to <span className='color-pk pointer profileModalLinks' onClick={this.openDeleteModal}>delete your account</span>, or to <span className='color-pk pointer profileModalLinks' onClick={this.openUnsubscribeModal}>unsubscribe </span>from STAT notifications and newsletter emails.</p>

                    {/* {this.state.newCityAndState.length + this.state.newCountry.length > 1 &&
                        <div className='saveProfileBtn'>
                            <span className='color-w'>Save changes</span>
                            <Image
                                className='saveProfileArrow'
                                width='auto'
                                height='auto'
                                src={require('../../Styles/Images/pinkRightArrow.png')}
                            />
                        </div>
                    } */}
                </div> {/* .profileInner */}
            
                <ProfileFeedback 
                    isOpen={this.state.deleteModalOpen} 
                    onRequestClose={this.closeDeleteModal}
                    forWhat={'delete'}
                    userHasAuthenticated={this.props.userHasAuthenticated}
                    title={'delete your account'}
                    bodyText={'If you would like to permanently delete your STAT account please enter your email address above to notify us. Your account will be promptly deleted. Thank you'}
                />

                <ProfileFeedback 
                    isOpen={this.state.unsubscribeModalOpen} 
                    onRequestClose={this.closeUnsubscribeModal}
                    forWhat={'unsubscribe'}
                    userHasAuthenticated={this.props.userHasAuthenticated}
                    title={'unsubscribe'}
                    bodyText={'If you would like to unsubscribe from the STAT newsletter please enter your email address above to notify us. You will be unsubscribed from our mailing list. Thank you.'}
                />

                <AuthFooter />
            </div>
        )
    }
}