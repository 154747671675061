import React, { Component } from 'react';
import { 
    // Image,
    // Button,
    Grid, 
    Row, 
    Col } from 'react-bootstrap';
import { Navbar } from '../../Components/Navbar';
import { FooSidebar } from '../../Components/FooSidebar';
import { resourcesData } from '../../Components/ResourcesData';
import { ResourcesCard } from '../../Components/ResourcesCard';
import { Footer } from '../../Components/Footer';

export class ResourcesPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false
        }
    }

    getResourcesHeight = () => {
        // Wait 1 second for resources to load so we can get the full length of the container and divide that number by 1.5 to create 2 columns
        setTimeout(function () { 
            let height = this.rElement.clientHeight / 1.5;
            this.setState({ resourcesHeight: height });
            // console.log(height);
        }.bind(this), 1000)
    }

    componentDidMount(){
        this.getResourcesHeight();
    }

    render() {

        let resources =  resourcesData.map((item, index) => {
            return (
                <div className='resourcesWrappers' key={item.id}>
                    <ResourcesCard
                        id={item.id}
                        obj={item}
                        // desktopMode={this.state.desktopMode}
                    />
                </div>
            )
        });

        return (
            <div className='resourcesPage'>
                <Navbar color='blue'/> 
                <FooSidebar />

                <Grid className='section1' fluid={true}>
                    <Row className='show-grid row'>
                        <Col sm={12} className='titleCol col index4'>
                            <p>Learn about how organizations are taking a stand</p>
                            <h2>Broaden Your View</h2>
                        </Col>
                    </Row>
                    <Row className='show-grid '>
                        <Col sm={12} className='col index4'>
                            <div 
                                id='resourcesContainer' 
                                className='resourcesContainer'
                                ref={ (rElement) => this.rElement = rElement} 
                                style={{ maxHeight: this.state.resourcesHeight }}
                            >
                              {resources.length ? resources : null}
                            </div>
                        </Col>
                    </Row>
                </Grid>
    
                <Footer /> 
            </div>
        )
    }
}