import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { LeftSideBar } from '../../Components/LeftSideBar';
import { RightSideBar } from '../../Components/RightSideBar';
import { CategoriesPanel } from '../../Components/CategoriesPanel';
import { SelectionPills } from './SelectionPills';
import { ICardsContainer } from '../../Components/ICardsContainer';
import { ICardModal } from '../../Components/ICardModal';
import { SortICards } from '../../Components/SortICards';
import { SortByClass } from '../../Components/SortByClass';
import { ChangeCardLayout } from '../../Components/ChangeCardLayout';
import { DownloadMultipleBtn } from '../../Components/DownloadMultipleBtn';
import { CSVLink } from "react-csv";

import { AuthFooter } from '../../Components/AuthFooter';
import { API } from 'aws-amplify';

export class SeeMySelections extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false, 
            mobileMode: false,
            // containerLoading: true,
            categoriesPanelOpen: false,

            // allCards: [],
            selectionsUpdated: false,
            activeClassification: 'all',
            newClassification: false,
            categoriesUpdated: false,
            sortBy: 'Recent',
            sortByOpen: false,
            layoutCards: true,

            infoFromModal: false,
            showDetailModal: false,
            iCardsToBeDownloaded: [],

            currentPage: 1,
            cardsPerPage: 6,
            everySelector: [
                'Commercial Banking','Correspondent Banking', 'Credit Cards', 'Lending', 'Money Services Businesses', 'Prepaid Cards', 'Remote Deposit Capture', 'Retail Banking', 'Virtual Currency', 'Wire Transfer', 'Law Enforcement', 'Financial Institution', 'NGO', 'Agriculture & Animal Husbandry', 'Arts & Entertainment', 'Bars, Strip Clubs & Cantinas', 'Carnivals', 'Commercial Cleaning Services','Construction', 'Domestic Work', 'Escort Services', 'Factories & Manufacturing', 'Forestry & Logging', 'Health & Beauty Services', 'Health Care', 'Hotels & Hospitality', 'Illicit Massage, Health & Beauty', 'Landscaping', 'Illicit Activities', 'Outdoor Solicitation', 'Peddling & Begging','Personal Sexual Servitude', 'Pornography', 'Recreational Facilities', 'Remote Interactive Sexual Acts', 'Residential', 'Restaurant & Food Services', 'Traveling Sales Crews', 'Labor Agency', 'Recruiter', 'Transporter', 'Victim', 'Other Trafficker', 'Child Exploitation', 'Labor', 'Sex', 'Africa', 'Asia', 'Canada', 'Carribean', 'Europe', 'Latin America', 'Middle East', 'United States'
            ]
        }
        this._isMounted = false;
    }

    checkForMobileMode = () => {
        this.setState({ mobileMode: window.innerWidth <= 620 });
    }

    removeCat = (name) => {
        this.activeCategories.splice( this.activeCategories.indexOf(name), 1 );
        this.newCats = this.activeCategories;

        setTimeout(() => {
            this.setState({
                categoriesUpdated: false
            })
        }, 10)

       setTimeout(() => {
            this.setState({
                categoriesUpdated: true
            })
        }, 20)   
    }

    sortNewClass = (newClass) => { // Only show cards based off indicator type
        let newCards = [];

        this.state.allCards.map((item) => {
            let indicatorData = [];

            if (item.indicatorType) {
                indicatorData = item.indicatorType;
            }

            if (indicatorData.length) {
                if (indicatorData.includes(newClass)) { 
                    return newCards.push(item); // Push this indicator obj to newClassCards array
                }
            }
            return console.log();
        });

        this.cards = newCards;

        this.setState({
            newClassification: true,
            newClassCards: newCards,
            activeClassification: newClass,
            // allCards: newCards
        })
    }

    toggleSortBy = () => {
        this.setState({
            sortByOpen: !this.state.sortByOpen
        })
    }

    sortByDate = (type, newLoad) => {
        let cards = [];

        if (newLoad === 'newLoadTrue') {
            let preSorted = this.state.indicators.sort((a, b) => { // b - a = most views, descending 
                let viewA = Number(a.viewCount);
                let viewB = Number(b.viewCount);    
                return viewA - viewB; 
            });
            cards = preSorted;
        } else {
            cards = this.cards;
        }

        let sorted = cards.sort((a, b) => { // b - a = Recent, a - b = Oldest
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            let submittedA = a.dateIndicatorSubmitted;
            let submittedB = b.dateIndicatorSubmitted;
            let arrayA = submittedA.split('/');
            let arrayB = submittedB.split('/');

            let months = [ "Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec" ];
            let monthForA = Number(submittedA[1]) - 1;
            let monthForB = Number(submittedB[1]) - 1;
            const textMonthA = months[monthForA];
            const textMonthB = months[monthForB];

            let dayForA = arrayA[0];
            let dayForB = arrayB[0];
            let yearForA = arrayA[2];
            let yearForB = arrayB[2];
            let dateA = textMonthA + '/' + dayForA + '/' + yearForA;
            let dateB = textMonthB + ' ' + dayForB + ', ' + yearForB;
        

            let newDateB = new Date(dateB);
            let newDateA = new Date(dateA); 

            if (type === 'Recent') {
                return newDateB - newDateA;
            } else if (type === 'Oldest') {
                return newDateA - newDateB;
            }

            return console.log();
        });

        this.setState({
            sortBy: type,
        })

        if (newLoad === 'newLoadTrue'){
            this.setState({
                allCards: sorted,
            })
            return
        } else {
            this.cards = sorted;
            this.toggleSortBy()
        }
    }

    mostViews = () => {
        let sorted = this.cards.sort((a, b) => { // b - a = most views, descending 
            let viewA = Number(a.viewCount);
            let viewB = Number(b.viewCount);    
            return viewB - viewA; 
        });

        this.cards = sorted;

        this.setState({
            sortBy: 'Most viewed',
        })

        this.toggleSortBy();
    }

    toggleCardLayout = () => {
        this.setState({
            layoutCards: !this.state.layoutCards
        })
    }



    selectToDownload = (obj) => {
        this.setState({
            iCardsToBeDownloaded: [...this.state.iCardsToBeDownloaded, obj]
        })
    }

    removeDownload = (obj) => {
        var array = [...this.state.iCardsToBeDownloaded]; 
        var index = array.indexOf(obj)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({
                iCardsToBeDownloaded: array 
            })
        }
    }

    downloadMultiple = () => {
        console.log(this.state.iCardsToBeDownloaded)
    }

    // downloadiCard = (obj) => {
    //     console.log('download this one')
    // }



    showDetailModal = (summary, id, date, source, financial, industry, customer, region, details, iType, transactionTime, traffickingType, views, downloads, fileUrl, bookmarked) => {
        this.setState({
            cardSummary: summary, 
            cardId: id, 
            cardDateSubmitted: date, 
            cardSource: source, 
            cardFinancial: financial, 
            cardIndustry: industry, 
            cardCustomer: customer, 
            cardRegion: region, 
            cardDetails: details, 
            cardiType: iType, 
            cardTransactionTime: transactionTime, 
            cardTraffickingType: traffickingType, 
            cardViews: views,
            cardDownloads: downloads,
            cardFileUrl: fileUrl,
            cardBookmarked: bookmarked,
        })

        setTimeout(() => {
            this.setState({
                showDetailModal: true,
            })
        }, 20)
    }

    closeDetailModal = () => {
        this.setState({
            showDetailModal: false,
        })
    }


    openCategoriesPanel = () => {
        window.scrollTo(0, 0);
        this.setState({
            categoriesPanelOpen: true,
        })
    }

    closePanel = () => {
        window.scrollTo(0, 0);
        this.setState({
            categoriesPanelOpen: false,
        })
    }

    updateSelections = (obj) => { // new selections from categories panel
        console.log(obj)
        let newSelections = obj.financialProduct.concat(obj.impactedIndustry, obj.customerType, obj.traffickingType, obj.geographicalRegions, obj.sourceFrom);
        this.newCats = newSelections;

        setTimeout(() => {
            this.setState({
                categoriesUpdated: false
            })
        }, 10)

        setTimeout(() => {
            this.setState({
                categoriesUpdated: true
            })
        }, 20)

        
    }
    

    componentWillMount(){
        window.addEventListener('resize', this.checkForMobileMode.bind(this));
        this.checkForMobileMode();
    }


    componentDidMount() {
        this._isMounted = true;
        window.scrollTo(0, 0);

        API.get("statmvp", "getindicatordata").then(response => {
            var indicatorArray = [];
            for (var i = 0; i < response.length; i++){
                if (response[i].approved){
                    indicatorArray.push(response[i])
                }
            }
            this.setState({
                indicators: indicatorArray,
            })

            this.sortByDate('Recent', 'newLoadTrue');
        }).catch(e => {
            console.log(e)
        })

        API.get("statmvp", "getindicatortotal").then(response => {
            this.setState({
                indicatorTotal: response
            })
        }).catch(error => {
            console.log(error)
        })
    }


    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.checkForMobileMode.bind(this));
    }


    render() {
    
        let { selections } = this.props.location.state;
        
        if (this.state.categoriesUpdated) {
            this.activeCategories = this.newCats;
        } else {
            this.activeCategories = selections.financialProduct.concat(selections.impactedIndustry, selections.customerType, selections.traffickingType, selections.geographicalRegions, selections.sourceFrom);
        }

        // this.allCards = [];
        this.cards = [];

        // if (this.state.newClassification) {
        //     this._allCards = this.state.newClassCards;
        // }

        let showCategories = [];
        // let allInstances = []; // not working. Remove when have pill numbers

        if (this.activeCategories.length && this.state.allCards){
            
            this.state.allCards.map((item) => {
                // console.log(item)
                let indicatorData = [];
                let holder = [];

                if(item.financialProduct) {
                    holder = holder.concat(item.financialProduct)
                } 
                if (item.impactedIndustry){
                    holder = holder.concat(item.impactedIndustry)
                } 
                if (item.customerType) {
                    holder = holder.concat(item.customerType)
                } 
                if (item.traffickingType) {
                    holder = holder.concat(item.traffickingType)
                } 
                if (item.geographicalRegions) {
                    holder = holder.concat(item.geographicalRegions[0])
                }
                if (item.sourceFrom) {
                    holder = holder.concat(item.sourceFrom[0])
                }

                indicatorData = indicatorData.concat(holder);
                // console.log(indicatorData)
                if (indicatorData.length) {
                    if (this.activeCategories.every(x => indicatorData.includes(x))) { // old: indicatorData.some
                        return this.cards.push(item);// Push this indicator obj to cards array
                    }
                }
                
                return console.log();
            })
        } 

        // Show selection pills at the top of the page 
        if (this.activeCategories && this.state.indicatorTotal) {
            let total = 0;
            showCategories = this.activeCategories.map((item) => {
                if (this.state.indicatorTotal.hasOwnProperty([item])) {
                    total = this.state.indicatorTotal[item];
                }
                return (
                    <SelectionPills 
                        id={item}
                        key={item}
                        pillName={item}
                        totalNumber={total}
                        actions={{
                            removeCat: this.removeCat,
                        }}
                        
                    />
                )
            })
        }
console.log(this.cards, this.cards.length)

        return (
            <div id='seeMySelections'>
                {!this.state.categoriesPanelOpen &&
                    <div>
                        <LeftSideBar page={'seeMySelections'} openPanel={this.openCategoriesPanel} />
                        <RightSideBar page={'dashboard-sub'} userHasAuthenticated = {this.props.userHasAuthenticated} />
                    </div>
                }

                <CategoriesPanel 
                    open={this.state.categoriesPanelOpen}
                    actions={{
                        closePanel: this.closePanel,
                        updateSelections: this.updateSelections
                    }}
                />

                <div className={this.state.categoriesPanelOpen ? 'innerPage seeSelectionsInner panelOpen' : 'innerPage seeSelectionsInner panelClosed'}>
                    <p className='color-b bold pageTitle'>
                        <span>Indicators found </span>
                        <span>({this.cards.length})</span>
                    </p>

                    <div className='pillsContainer'>
                        <Button className='addMoreCategories' onClick={this.openCategoriesPanel}>
                            <p className='noMargin'>Add</p>
                        </Button>

                        {showCategories.length ? showCategories : null}
                    </div>

                    <div className='flexRow rowEnd smallSortWrapper'>
                        <SortByClass
                            size={'small'}
                            sortNewClass={this.sortNewClass}
                            activeClassification={this.state.activeClassification}
                        />

                        <SortICards 
                            mobileMode={this.state.mobileMode}
                            sortByOpen={this.state.sortByOpen}
                            sortBy={this.state.sortBy}
                            actions={{
                                sortByDate: this.sortByDate,
                                mostViews: this.mostViews,
                                toggleSortBy: this.toggleSortBy,
                            }}
                        />

                        {!this.state.mobileMode &&
                            <ChangeCardLayout
                                layoutCards={this.state.layoutCards}
                                toggleCardLayout={this.toggleCardLayout}
                            />
                        }
                    </div> {/* .smallSortWrapper */}

                    {this.cards && this.cards.length > 0 ?
                        <div>
                            <ICardsContainer
                                mobileMode={this.state.mobileMode}
                                cards={this.cards}
                                layoutCards={this.state.layoutCards}
                                bookmarksPage={false}
                                actions={{
                                    selectToDownload: this.selectToDownload,
                                    removeDownload: this.removeDownload,
                                    showDetailModal: this.showDetailModal,
                                }}
                            />

                            {this.state.iCardsToBeDownloaded.length >= 1 &&
                                <CSVLink data={this.state.iCardsToBeDownloaded} target="_blank" filename={"stat-indicator.csv"}>
                                    <DownloadMultipleBtn
                                        numToDownload={this.state.iCardsToBeDownloaded.length}
                                        downloadMultiple={this.downloadMultiple}
                                    />
                                </CSVLink>
                            }
                        </div>
                    :
                        <div>
                            <p className='color-b light'>Sorry, we could not find any indicators based on your selections.</p>
                            <p className='color-b light'>Please click <Link className='color-pk' to='/dashboard'>here</Link> to start a new selection.</p>
                        </div>
                    }
                </div> {/* .seeSelectionsInner */}

                {this.state.showDetailModal &&
                    <ICardModal
                        // isOpen={this.state.showModal} 
                        // onRequestClose={this.closeModal}
                        actions={{
                            closeDetailModal: this.closeDetailModal,
                        }}
                        summary={this.state.cardSummary}
                        details={this.state.cardDetails}
                        id={this.state.cardId}
                        dateSubmitted={this.state.cardDateSubmitted}
                        source={this.state.cardSource}
                        iType={this.state.cardiType}
                        financial={this.state.cardFinancial}
                        customer={this.state.cardCustomer}
                        region={this.state.cardRegion}
                        industry={this.state.cardIndustry}
                        traffickingType={this.state.cardTraffickingType}
                        transactionTime={this.state.cardTransactionTime}
                        docs={this.state.cardFileUrl}
                        views={this.state.cardViews}
                        downloads={this.state.cardDownloads}
                        fileUrl={this.state.cardFileUrl}
                        bookmarked={this.state.cardBookmarked}
                    /> 
                }

                <AuthFooter panelOpen={this.state.categoriesPanelOpen} />
            </div>
        )
    }
}