import React, { Component } from 'react';
import { Pagination } from 'react-bootstrap';

export class PaginationBtns extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
        }
    }

    // componentDidMount(){
    //     this.props.actions.getPaginationNumbers();
    // }

    render(){
    //*** Render pagination buttons */
        let subPages = [];
        if (this.props.pageNumbers) {
            subPages = this.props.pageNumbers.map(number => {
                return (
                    <Pagination.Item 
                        key={number} 
                        id={number} 
                        className='pageBtns'
                        active={this.props.currentPage === number} 
                        onClick={this.props.actions.changePage}
                    >
                        {number}
                    </Pagination.Item>
                )
            })
        }

        return(
            <div className='pageNumberWrapper'>
                <Pagination>
                    <Pagination.Prev className='pageBtns outerLeft' onClick={this.props.actions.prevPage}/>
                    {subPages.length ? subPages : null}
                    <Pagination.Next className='pageBtns outerRight' onClick={this.props.actions.nextPage} />
                </Pagination>
            </div>
        )
    }
}