import React, { Component } from 'react';
import { 
    Button,
    Checkbox,
    Overlay,
    OverlayTrigger, // shows and hides on/off hover
    Tooltip } from 'react-bootstrap';
import { API } from 'aws-amplify';
import { CSVLink } from "react-csv";

export class IndicatorCard extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            showModal: false,
            checked: false,
            nowSaved: false,
            otherInfoTool: false,
            showListViewMoreInfo: false,
        }

        this._isMounted = false;
    }

    selectToDownload = (e) => { // Checkmarks 
        this.setState({
            checked: !this.state.checked
        })

        setTimeout(() => {
            if (this.state.checked) {
                this.props.actions.selectToDownload(this.props.obj)
            }  else {
                this.props.actions.removeDownload(this.props.obj)
            }
        }, 100)
    }

    saveIndicator = () => {
        if (!this.bookmarked) {
            API.post("statmvp", "addsavedindicator", {
                body: {
                    indicator: this.obj
                }
            }).then(response => {
                console.log(response);

                this.nowBookmarked = true;

                this.setState({
                    nowSaved: true,
                })
            }).catch(error => {
                console.log(error)
            })
        }
    }

    downloadiCard = () => { // Download icon
        //this.props.actions.downloadiCard(this.obj);

        //code to increase download count in database
        API.post("statmvp", "adddownloadcount", {
            body: {
                indicatorId: this.id,
                dateIndicatorSubmitted: this.props.obj.dateIndicatorSubmitted,
                downloads: this.downloads
            }
        })
    }

    showDetailModal = () => {
        this.props.actions.showDetailModal(this.summary, this.id, this.dateSubmitted, this.source, this.financialModal, this.industryModal, this.customerModal, this.region, this.details, this.iType, this.transactionTime, this.traffickingTypeModal, this.views, this.downloads, this.fileUrl, this.bookmarked)

        //code to increase view count in database
        API.post("statmvp", "addviewcount", {
            body: {
                indicatorId: this.id,
                dateIndicatorSubmitted: this.props.obj.dateIndicatorSubmitted,
                viewCount: this.views
            }
        }).then(response => {
            console.log()
        }).catch(error => {
            console.log(error)
        })
    }

    toggleToolTip = (e, toolTip) => {
        e.preventDefault();
        this.setState({
            [toolTip]: !this.state[toolTip] 
        })
    }

    showListViewMoreInfo = () => {
        this.setState({
            showListViewMoreInfo: !this.state.showListViewMoreInfo
        })
    }


    componentDidMount() {
        this._isMounted = true;

        this.props.actions.addToCounter(); // Get the number of iCards that have fully loaded onto the page for masonary layout to work properly
    }


    render() {
        this.bookmarked = '';
        this.obj = '';
        this.summary = '';
        this.id = '';
        this.dateSubmitted = '';
        this.source = '';
        this.financial = '';
        this.financialModal ='';
        this.industry = '';
        this.industryModal = '';
        this.customer = '';
        this.customerModal = '';
        this.region = '';
        this.details = '';
        this.iType = '';
        this.transactionTime = '';
        this.traffickingType = '';
        this.traffickingTypeModal = '';
        this.fileUrl = '';
        this.views = '';
        this.downloads = '';
        
        if (this.props.saved === true) {
            this.bookmarked = true;
        } else {
            this.bookmarked = false;
        }

        if(this.state.nowSaved) {
            this.bookmarked = this.nowBookmarked;
        } 

        if(this.props.obj) {
            this.obj = this.props.obj;
            //console.log(this.obj)

            if (this.obj.hasOwnProperty('supportingDocs')) {
                //console.log(this.obj.supportingDocs)
            }

            if (this.obj.hasOwnProperty('indicatorSummary')) {
                if (this.obj.indicatorSummary === 'n/a' || this.obj.indicatorSummary === false) {
                    return
                } else {
                    this.summary = this.obj.indicatorSummary;
                }
            }

            if (this.obj.hasOwnProperty('indicatorId')) {
                if(this.obj.indicatorId === 'n/a' || this.obj.indicatorId === false) {
                    this.id = false;
                } else {
                    this.id = this.obj.indicatorId;
                }
            }

            if (this.obj.hasOwnProperty('dateIndicatorSubmitted')) {
                if(this.obj.dateIndicatorSubmitted === 'n/a' || this.obj.dateIndicatorSubmitted === false) {
                    this.dateSubmitted = false;
                } else {
                    let string = this.obj.dateIndicatorSubmitted;
                    let array = string.split('/');

                    var months = [ "Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec" ];
                    var month = Number(array[1]) - 1;
                    const textMonth = months[month];

                    let day = array[0];
                    let year = array[2];
                    let date = textMonth + ' ' + day + ', ' + year;
                    this.dateSubmitted = date;
                }
            }

            if (this.obj.hasOwnProperty('sourceFrom')) { // TODO: missing !!!
                if (this.obj.sourceFrom[0] === 'n/a' || this.obj.sourceFrom === false) {
                    this.source = false;
                } else {
                    //this.source = this.obj.sourceFrom[0].join(', ');
                    this.source = this.obj.sourceFrom;
                }
            }

            if (this.obj.hasOwnProperty('financialProduct')) {
                if (this.obj.financialProduct[0] === 'n/a' || this.obj.financialProduct === false) {
                    this.financial = false;
                } else if (this.obj.financialProduct[0] === false){
                        this.financial = this.obj.financialProduct[1];
                        this.financialModal = this.obj.financialProduct[1];
                } 
                else if (this.obj.financialProduct[this.obj.financialProduct.length-1].Other){
                    const length = this.obj.financialProduct[0].length;    
                    this.financial = this.obj.financialProduct[0];
                        this.financial[length] = this.obj.financialProduct[1].Other;
                        this.financial = this.financial.filter((item, index) => this.financial.indexOf(item) === index)
                        this.financialModal = this.financial;
                        this.financial = this.financial.join(", ")
                    } 
                 else {
                        this.financial = this.obj.financialProduct.join(', ');
                    this.financialModal = this.obj.financialProduct;
                }
                
            }

            if (this.obj.hasOwnProperty('impactedIndustry')) {
                if (this.obj.impactedIndustry[0] === 'n/a' || this.obj.impactedIndustry === false) {
                    this.industry = false;
                } else {
                    this.industry = this.obj.impactedIndustry.join(', ');
                    this.industryModal = this.obj.impactedIndustry
                }
            }

            if (this.obj.hasOwnProperty('customerType')) {
                if (this.obj.customerType[0] === 'n/a' || this.obj.customerType === false) {
                    this.customer = false;
                } else {
                    this.customer = this.obj.customerType.join(', ');
                    this.customerModal = this.obj.customerType;
                }
            }

            if (this.obj.hasOwnProperty('geographicalRegions')) {
                if (this.obj.geographicalRegions[0] === 'n/a' || this.obj.geographicalRegions === false) {
                    this.region = false;
                } else {
                    this.region = this.obj.geographicalRegions;
                }
            }

            if (this.obj.hasOwnProperty('indicatorDetail')) {
                if (this.obj.indicatorDetail === 'n/a' || this.obj.indicatorDetail === false) {
                    this.details = false;
                } else {
                    this.details = this.obj.indicatorDetail;
                }
            }

            if (this.obj.hasOwnProperty('indicatorType')) {
                if (this.obj.indicatorType[0] === 'n/a' || this.obj.indicatorType === false) {
                    this.iType = false;
                } else {
                    this.iType = this.obj.indicatorType;
                }
            }

            if (this.obj.hasOwnProperty('periodOfTransactionTime')) {
                if (this.obj.periodOfTransactionTime[0] === 'n/a' || this.obj.periodOfTransactionTime === false) {
                    this.transactionTime = false;
                } else {
                    this.transactionTime = this.obj.periodOfTransactionTime;
                }
            }

            if (this.obj.hasOwnProperty('traffickingType')) {
                if (this.obj.traffickingType[0] === 'n/a' || this.obj.traffickingType === false) {
                    this.traffickingType = false;
                } else {
                    this.traffickingType = this.obj.traffickingType.join(', ');
                    this.traffickingTypeModal = this.obj.traffickingType;
                }
            }

            if (this.obj.hasOwnProperty('viewCount')) {
                if (this.obj.viewCount === 'n/a' || this.obj.viewCount === false) {
                    this.views = 0;
                } else {
                    this.views = this.obj.viewCount;
                }
            }

            if (this.obj.hasOwnProperty('downloads')) {
                if (this.obj.downloads === 'n/a' || this.obj.downloads === false) {
                    this.downloads = 0;
                } else {
                    this.downloads = this.obj.downloads;
                }
            }

            if (this.obj.hasOwnProperty('fileUrl')) {
                if (this.obj.fileUrl === false) {
                    this.fileUrl = false;
                } else {
                    this.fileUrl = this.obj.fileUrl;
                }
            }
        }

        // show indicator types
        let indicatorTypes = [];
        if(this.iType && this.iType.length) {
            indicatorTypes = this.iType.map((item, index) => {
                let letter = item.charAt(0).toLowerCase();
                let thisId = 'iTypeTooltip-' + letter;
                return (
                    <div key={item}>
                        <OverlayTrigger 
                            placement={'bottom'}
                            overlay={
                                <Tooltip 
                                    id={thisId} 
                                    className='iTypeTooltip'
                                >
                                    <p className='color-w light noMargin'>{item}</p>
                                </Tooltip>
                            }
                        >
                            <div id={letter} className=' flexRow typePills'>
                                <span className='color-w uppercase'>{letter}</span>
                            </div>
                        </OverlayTrigger>
                    </div>
                )
            })
        }

                // For Indicator Download
                var csvData = [
                    { summary: this.summary,
                        indicatorId: this.id,
                        dateSubmitted: this.dateSubmitted,
                        sourceFrom: this.source,
                        financialProduct: this.financial,
                        impactedIndustry: this.industry,
                        customerType: this.customer,
                        geographicalRegion: this.region,
                        indicatorDetails: this.details,
                        indicatorType: this.iType,
                        transactionTime: this.transactionTime,
                        traffickingType: this.traffickingType,
                        fileUrl: this.fileUrl,
                        viewCount: this.views }
                  ]; 
        
        return (
            <div className={!this.props.layoutCards ? 'iCards listView' : ' iCards'}> 
                <div className='iCardsInner'>  
                    <div className='flexRow infoGroup rowOne'>
                        <p className='color-b smallText cardTitles'>Summary</p>
                        <Checkbox
                            name='selectToDownload' 
                            className={this.state.checked ? 'options checked' : 'options'}
                            checked={this.state.checked ? 'checked' : ''}
                            readOnly
                            onClick={(e) => this.selectToDownload(e)}
                        >   
                            <span className='checkmark marginLeft'></span> 
                        </Checkbox>
                    </div>
                
                    <div className='iCardModalBtn' onClick={this.showDetailModal}>
                        <div className='infoGroup rowTwo'>
                            {this.summary && this.summary.length &&
                            <p className='color-b bold summaryText'>{this.summary}</p>
                            }
                        </div>
                        
                        {this.id && this.id.length &&
                            <div className='infoGroup cardTitles'>
                                <p className='color-b uppercase infoText'>ID# {this.id}</p>
                            </div>
                        }

                        {this.dateSubmitted && this.dateSubmitted.length &&
                        <div className='infoGroup date'>
                            <p className='color-b smallText cardTitles'>Date uploaded</p>
                            <p className='color-b infoText'>{this.dateSubmitted}</p>
                        </div>
                        }
                    
                        {this.source && this.source.length &&
                            <div className='infoGroup source'>
                                <p className='color-b smallText cardTitles'>Source</p>
                                <p className='color-b infoText'>{this.source}</p>
                            </div>
                        }

                        {this.financial && this.financial.length &&
                        <div className='infoGroup cardTitles'>
                            <p className='color-b smallText'>Financial product</p>
                            <p className='color-b infoText'>{this.financial}</p>
                        </div>
                        }

                        {this.industry && this.industry.length &&
                        <div className='infoGroup cardTitles'>
                            <p className='color-b smallText'>Impacted industry</p>
                            <p className='color-b infoText'>{this.industry}</p>
                        </div>
                        }
                    </div>
                    <div className='bottomActions'>
                        <div className='flexRow left classes'>
                            {indicatorTypes.length ? indicatorTypes : null}
                        </div>

                        <div className='flexRow right actionBtns'>
                            <div className='iconOuter'> {/* download icon */}
                                <CSVLink data={csvData} target="_blank"  filename={"stat-indicator.csv"} className='downloadLink'>
                                    <Button 
                                        className='bottomIconBtn download' 
                                        onClick={this.downloadiCard}
                                    />
                                </CSVLink>
                            </div>

                            <div className='iconOuter'> {/* bookMark icon */}
                                <Button 
                                    className={this.bookmarked ? 'bottomIconBtn addBookmark active' : 'bottomIconBtn addBookmark'}
                                    onClick={this.saveIndicator}
                                />
                            </div>

                            {!this.props.layoutCards ?
                                <div className='iconOuter'>
                                    <Button
                                        className='bottomIconBtn otherInfo' 
                                        onClick={this.showListViewMoreInfo}
                                    />
                                </div>
                            :
                                <div className='iconOuter'> {/* "more" icon */}
                                    <Button 
                                        className='bottomIconBtn otherInfo' 
                                        ref={(otherInfoBtn) => this.otherInfoBtn = otherInfoBtn} 
                                        onClick={(e) => this.toggleToolTip(e, 'otherInfoTool')}
                                    />
                                    <Overlay 
                                        target={this.otherInfoBtn} 
                                        show={this.state.otherInfoTool} 
                                        onHide={(e) => this.toggleToolTip(e, 'otherInfoTool')}
                                        placement={this.props.mobileMode ? 'bottom' : 'right'}
                                        rootClose={true}
                                    >
                                        <Tooltip 
                                            id={'otherInfoTooltip'} 
                                            className='toolTips foriCards' 
                                        >
                                            {this.customer && this.customer.length &&
                                                <div className='infoSections'>
                                                    <p className='color-b smallText'>Customer type</p>
                                                    <p className='color-b infoText'>{this.customer}</p>
                                                </div>
                                            }

                                            {this.traffickingType && this.traffickingType.length &&
                                                <div className='infoSections'>
                                                    <p className='color-b smallText'>Trafficking type</p>
                                                    <p className='color-b infoText'>{this.traffickingType}</p>
                                                </div>
                                            }
                                            {this.region && this.region.length &&
                                                <div className='infoSections'>
                                                    <p className='color-b smallText'>Geographical Region</p>
                                                    <p className='color-b infoText'>{this.region}</p>
                                                </div>
                                            }
                                        </Tooltip>
                                    </Overlay>
                                </div>
                            }
                        </div> {/* .right */}
                    </div> {/* .bottomAction */}
                </div> {/* .iCardsInner */}

                {!this.props.layoutCards &&
                    <div className={this.state.showListViewMoreInfo ? 'listViewMoreInfo open' : 'listViewMoreInfo'}>
                        <div className='col one'>
                            {this.financial && this.financial.length &&
                            <div className='infoGroup'>
                                <p className='color-b smallText'>Financial product</p>
                                <p className='color-b infoText'>{this.financial}</p>
                            </div>
                            }

                            {this.industry && this.industry.length &&
                            <div className='infoGroup'>
                                <p className='color-b smallText'>Impacted industry</p>
                                <p className='color-b infoText'>{this.industry}</p>
                            </div>
                            }
                        </div>

                        <div className='col two'>
                            {this.customer && this.customer.length &&
                                <div className='infoGroup'>
                                    <p className='color-b smallText'>Customer type</p>
                                    <p className='color-b infoText'>{this.customer}</p>
                                </div>
                            }

                            {this.traffickingType && this.traffickingType.length &&
                                <div className='infoGroup'>
                                    <p className='color-b smallText'>Trafficking type</p>
                                    <p className='color-b infoText'>{this.traffickingType}</p>
                                </div>
                            }
                            {this.region && this.region.length &&
                                <div className='infoGroup'>
                                    <p className='color-b smallText'>Geographical Region</p>
                                    <p className='color-b infoText'>{this.region}</p>
                                </div>
                            }
                        </div>
                    </div> //* .listViewMoreInfo */}
                }

            </div> // .iCards*/}
        )
    }
}