import maturityModel from '../Styles/Images/maturity-model.png';
import visualizeMap from '../Styles/Images/visualize-map.png';

export const resourcesData = [
    {
        id: 12,
        imgUrl: visualizeMap,
        title: 'Visualize High-Risk Demographics',
        // intro: 'Macaroon apple pie jelly beans icing cupcake bear claw muffin danish.',
        // siteName: 'cupcakeipsum.com',
        siteUrl: 'https://enigma.codeqa.com/stat/at-risk-geographies'
    },
    {
        id: 1,
        // imgUrl: '',
        title: 'Access Online Resources',
        // intro: 'Macaroon apple pie jelly beans icing cupcake bear claw muffin danish.',
        // siteName: 'cupcakeipsum.com',
        siteUrl: 'https://enigma.codeqa.com/stat/foundational-resources'
    },
    {
        id: 2,
        title: 'Access Typologies to Identify Human Trafficking',
        // intro: 'Macaroon apple pie jelly beans icing cupcake bear claw muffin danish.',
        // siteName: 'cupcakeipsum.com',
        siteUrl: 'https://enigma.codeqa.com/stat/antitrafficking-typologies-resources' 
    },
    {
        id: 3,
        title: 'Anti-Human Trafficking Technology Innovations',
        // intro: 'Macaroon apple pie jelly beans icing cupcake bear claw muffin danish.',
        // siteName: 'cupcakeipsum.com',
        siteUrl: 'https://enigma.codeqa.com/stat/tech-innovations-to-combat-trafficking' 
    },
    {
        id: 9,
        imgUrl: maturityModel,
        title: 'Maturity Model: How advanced is your trafficking program?',
        // intro: 'Macaroon apple pie jelly beans icing cupcake bear claw muffin danish.',
        // siteName: 'cupcakeipsum.com',
        siteUrl: 'https://enigma.codeqa.com/stat/maturity-model'
    },
    {
        id: 4,
        // imgUrl:'https://i.pinimg.com/originals/b7/00/2e/b7002e29c8a349a4f5244da17d6ca4dd.jpg',
        title: 'Attend Industry Conferences',
        // intro: 'Macaroon apple pie jelly beans icing cupcake bear claw muffin danish.',
        // siteName: 'cupcakeipsum.com',
        siteUrl: 'https://enigma.codeqa.com/stat/foundational-events' 
    },
    {
        id: 5,
        // imgUrl:'https://i.pinimg.com/originals/b7/00/2e/b7002e29c8a349a4f5244da17d6ca4dd.jpg',
        title: 'Explore How Public Data Can Help You Detect Human Trafficking',
        // intro: 'Macaroon apple pie jelly beans icing cupcake bear claw muffin danish.',
        // siteName: 'cupcakeipsum.com',
        siteUrl: 'https://enigma.codeqa.com/stat/public-datasets-to-fight-trafficking' 
    },
    {
        id: 6,
        // imgUrl:'https://i.pinimg.com/originals/b7/00/2e/b7002e29c8a349a4f5244da17d6ca4dd.jpg',
        title: 'Five Anti-Trafficking Lessons From Your Peers',
        // intro: 'Macaroon apple pie jelly beans icing cupcake bear claw muffin danish.',
        // siteName: 'cupcakeipsum.com',
        siteUrl: 'https://enigma.codeqa.com/stat/five-lessons-from-peers' 
    },
    {
        id: 7,
        // imgUrl:'https://i.pinimg.com/originals/b7/00/2e/b7002e29c8a349a4f5244da17d6ca4dd.jpg',
        title: 'Interactive: Combat Trafficking in Agriculture with Public Data',
        // intro: 'Macaroon apple pie jelly beans icing cupcake bear claw muffin danish.',
        // siteName: 'cupcakeipsum.com',
        siteUrl: 'https://enigma.codeqa.com/stat/public-data-to-combat-labor-trafficking' 
    },
    {
        id: 8,
        // imgUrl:'https://i.pinimg.com/originals/b7/00/2e/b7002e29c8a349a4f5244da17d6ca4dd.jpg',
        title: 'Join Anti-Trafficking Working Groups',
        // intro: 'Macaroon apple pie jelly beans icing cupcake bear claw muffin danish.',
        // siteName: 'cupcakeipsum.com',
        siteUrl: 'https://enigma.codeqa.com/stat/antitrafficking-leadership-resources'
    },
    {
        id: 10,
        // imgUrl:'https://i.pinimg.com/originals/b7/00/2e/b7002e29c8a349a4f5244da17d6ca4dd.jpg',
        title: 'Train Your Team to Spot Human Trafficking',
        // intro: 'Macaroon apple pie jelly beans icing cupcake bear claw muffin danish.',
        // siteName: 'cupcakeipsum.com',
        siteUrl: 'https://enigma.codeqa.com/stat/antitrafficking-training-resources'
    },
    {
        id: 11,
        // imgUrl:'https://i.pinimg.com/originals/b7/00/2e/b7002e29c8a349a4f5244da17d6ca4dd.jpg',
        title: 'Understand Finance’s Role in Stopping Human Trafficking',
        // intro: 'Macaroon apple pie jelly beans icing cupcake bear claw muffin danish.',
        // siteName: 'cupcakeipsum.com',
        siteUrl: 'https://enigma.codeqa.com/stat/why-now-why-fi'
    },
    
]