import React, { Component } from 'react';
import { 
    Button,
    Image,
    FormGroup,
    ControlLabel,
    FormControl,
    Checkbox } from 'react-bootstrap';
import Switch from "react-switch";
import TextareaAutosize from 'react-autosize-textarea';
import doc from '../../Styles/Images/doc.png';
import pdf from '../../Styles/Images/pdf.png';
import excel from '../../Styles/Images/excel.png';

 

export class IndicatorForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            extraSpace: null,
            //indicatorDetail: '', // for/to the backend
            descriptionFocus: true,
            descriptionAccess: true, // Starts at true!!
            openDescription: false,
            dTextAreaEmpty: true,

            financialProduct: false, // for/to the backend
            financialAccess: false,
            fpHasSelection: false,
            financialOpen: false,
            financialOptions: {'Commercial Banking': false, 'Correspondent Banking': false, 'Credit Cards': false, 'Lending': false, 'Money Services Businesses':false, 'Prepaid Cards': false, 'Remote Deposit Capture':false, 'Retail Banking': false, 'Virtual Currency': false, 'Wire Transfer': false },
            allFinancial: false,
            financialOther: '', // Other text

            periodOfTransactionTime: false, // for/to the backend

            indicatorType: false, // for/to the backend
            inTypeAccess: false,
            itHasSelection: false,
            inTypeOpen: false,
            inTypes: {'Behavioral': false, 'Demographic': false, 'Transactional': false },
            allInTypes: false,

            sourceFrom: false, // for/to the backend,
            sources: {'Law Enforcement': false, 'Financial Institution': false, 'NGO': false},
            sourceAccess: false,
            sourceOpen: false,
            sHasSelection: false,
            allSources: false,
            sourceOther: '', // To be controlled input

            docs: [],
            newFiles: [],
            uploadDocsAccess: false, // Starts at false
            showUploadBtn: true, // Starts at true

            additionalOpen: false,
            // additionalAccess: false,
            trackMoreInfo: 0,

            //indicatorSummary: '', // For backend
            sTextAreaEmpty: true, // Starts at true
            summaryFocus: true, // Starts at True
            summaryOpen: false,
            showSummarySkipBtn: false,
            summaryRows: 1,

            impactedIndustry: false, // for/to the backend
            industriesAccess: false,
            industryOptions: {'Agriculture & Animal Husbandry': false, 'Arts & Entertainment': false, 'Bars, Strip Clubs & Cantinas': false, 'Carnivals': false, 'Commercial Cleaning Services': false,'Construction': false, 'Domestic Work': false, 'Escort Services': false, 'Factories & Manufacturing': false, 'Forestry & Logging': false, 'Health & Beauty Services': false, 'Health Care': false, 'Hotels & Hospitality': false, 'Illicit Massage, Health & Beauty': false, 'Landscaping': false, 'Illicit Activities': false, 'Outdoor Solicitation': false, 'Peddling & Begging': false,'Personal Sexual Servitude': false, 'Pornography': false, 'Recreational Facilities': false, 'Remote Interactive Sexual Acts': false, 'Residential': false, 'Restaurant & Food Services': false, 'Traveling Sales Crews': false },
            iiHasSelection: false,
            allIndustries: false,
            industriesOpen: false,

            customerType: false, // for/to the backend
            customerOptions: {'Labor Agency': false, 'Recruiter':  false, 'Transporter': false, 'Victim': false, 'Other Trafficker': false },
            customerAccess: false,
            customerOpen: false,
            ctHasSelection: false,
            allCustomers: false,

            traffickingType: false, // for/to the backend
            traffickingOptions: {'Child Exploitation': false, 'Labor': false, 'Sex': false },
            traffickingAccess: false,
            traffickingOpen: false,
            ttHasSelection: false,
            allTrafficking: false,

            geographicalRegions: false, // for/to the backend
            regionOptions: {'Africa': false, 'Asia': false, 'Canada': false, 'Caribbean': false, 'Europe': false, 'Latin America': false, 'Middle East': false, 'United States': false },
            regionAccess: false,
            regionOpen: false,
            grHasSelection: false,
            allRegions: false,

            continueAccess: false,

            sourceDescriptionforFI: false, // for/to the backend ????????

            approvedStatus: false, // for/to the backend

        }
    }

    addExtraSpace = (item) => {
        if (this.props.mobileMode) {
            // this.setState({ extraSpace: '21em' }) // Drop down Ul height + seeSelectionBtn height(4em)
            if (item === 'financial') {
                this.setState({ 
                    extraSpace: '23em' 
                })
                return
            } else if (item === 'industry') {
                this.setState({ 
                    
                    extraSpace: '51em' 
                })
                return
            } else if (item === 'customer') {
                this.setState({ 
                    
                    extraSpace: '5em' 
                })
                return
            } else if (item === 'trafficking') {
                this.setState({ 
                    
                    extraSpace: '5em' 
                })
                return
            } else if (item === 'regions') {
                this.setState({ 
                    
                    extraSpace: '23em' 
                })
                return
            } else {
                return
            }
        } else {
            if (item === 'financial') {
                this.setState({ 
                    extraSpace: '25em' 
                })
                return
            } else if (item === 'industry') {
                this.setState({ 
                    
                    extraSpace: '57em' 
                })
                return
            } else if (item === 'customer') {
                this.setState({ 
                    
                    extraSpace: '10em' 
                })
                return
            } else if (item === 'trafficking') {
                this.setState({ 
                    
                    extraSpace: '10em' 
                })
                return
            } else if (item === 'regions') {
                this.setState({ 
                    extraSpace: '23em' 
                })
                return
            } else {
                return
            }
        }
    }

    getInfo = (e, property) => { // Reusable, get textArea value
        this.setState({ [property]: e.target.value });
    }

    openDivHelper = (div, name) => {
        if (this.state.descriptionFocus || this.state.summaryFocus) {
            this.setState({ 
                [div] : true,
                descriptionFocus: false,
                summaryFocus: false
            });
        } else {
            this.setState({ [div] : true })
        }

        this.addExtraSpace(name);
    }

    closeDiv = (div) => {
        this.setState({ 
            [div] : false,
            extraSpace: null
         })
    }

    clickOutside = (e) => {
        const refs = {
            financialOpen: this.fpOpenElement,
            inTypeOpen: this.itOpenElement,
            sourceOpen: this.sOpenElement,
            industriesOpen: this.aiOpenElement,
            customerOpen: this.ctOpenElement,
            traffickingOpen: this.ttOpenElement,
            regionOpen: this.grOpenElement
        }

        let currentRef = false;
        let openDiv;

        Object.keys(refs).map(key => {
            if(this.state[key] === true) {
                currentRef = refs[key];
                if (currentRef && !currentRef.contains(e.target)) {
                    openDiv = key;
                    this.setState({
                        [openDiv]: false,
                        extraSpace: null
                    })
                    return openDiv = false;
                }
            }
            
            return currentRef = false;
        })
    }

    selectAllHelper = (options, backend, allOptions, nextQuestion) => { // Reusable, select all question options
        let keyValues = {};
        let newArray = [];
        let newOptions = {...this.state[options]};
        let thisKey;
        this.setState({ 
            [allOptions]: !this.state[allOptions]
        })

        setTimeout(() => {
            if(this.state[allOptions] === true) {
                Object.keys(newOptions).map(key => {
                    thisKey = key;
                    keyValues[thisKey] = true;
                    newArray.push(thisKey)
                    return thisKey = '';
                })
                this.setState({ 
                    [options]: keyValues ,
                    [nextQuestion]: true,
                    [backend]: newArray
                })
            } else {
                Object.keys(newOptions).map(key => {
                    thisKey = key;
                    keyValues[thisKey] = false;
                    newArray.push(thisKey)
                    return thisKey = '';
                })
                this.setState({ 
                    // [allOptions]: !this.state[allOptions],
                    [options]: keyValues ,
                    [nextQuestion]: false,
                    [backend]: false
                })
            }
        }, 10)
    }

    getOptionHelper = (optionsHolder, allOptions, backend, nextQuestion) => {
        if (this.state[allOptions] === true){
            this.setState({ [allOptions]: false });
        }
        
        let newArray = [];
        // let newBackend = [];
        let thisKey;
        let fullBackend = {...this.state[backend]};
        setTimeout(() => {
            Object.keys(this.state[optionsHolder]).map(key => {
                if (this.state[optionsHolder][key] === true) {
                    thisKey = key;
                    newArray.push(thisKey)
                } 
                return thisKey = '';
            })
        }, 100)
        setTimeout(() => {
            if (newArray.length >= 1) {
                fullBackend = newArray;

                this.setState({ 
                    [backend]: fullBackend,
                    [nextQuestion]: true
                })
                
                if(backend === 'impactedIndustry'){
                    this.setState(prevState => { 
                        return { trackMoreInfo: prevState.trackMoreInfo + 1 }
                    })
                } else if (backend === 'customerType') {
                    this.setState(prevState => { 
                        return { trackMoreInfo: prevState.trackMoreInfo + 1 }
                    })
                } else if (backend === 'traffickingType'){
                    this.setState(prevState => { 
                        return { trackMoreInfo: prevState.trackMoreInfo + 1 }
                    })
                }
                
            } else {
                if (backend === 'financialProduct') {
                // Edit for financial product "Other" field to allow access to next question
                    if (this.state.financialOther.length > 2) {
                        this.setState({ 
                            [backend]: false,
                            [nextQuestion]: true
                        })
                    } else {
                        this.setState({ 
                            [backend]: false,
                            [nextQuestion]: false
                        })
                    }
                } else {
                    this.setState({ 
                        [backend]: false,
                        [nextQuestion]: false
                    })
                }

                if(backend === 'impactedIndustry'){
                    this.setState(prevState => { 
                        return { trackMoreInfo: prevState.trackMoreInfo - 1 }
                    })
                } else if (backend === 'customerType') {
                    this.setState(prevState => { 
                        return { trackMoreInfo: prevState.trackMoreInfo - 1 }
                    })
                } else if (backend === 'traffickingType'){
                    this.setState(prevState => { 
                        return { trackMoreInfo: prevState.trackMoreInfo - 1 }
                    })
                }
            }
        }, 200)
    }

    // applyHelper = (options, backend, thisDivOpen, nextQuestion) => { 
    // // Reusable, save slections for push to backend, close this div and unlock next question
    //     let newArray = [];
    //     let thisKey;
    //     Object.keys(this.state[options]).map(key => {
    //         if (this.state[options][key] === true) {
    //             thisKey = key;
    //             newArray.push(thisKey)
    //         }
    //         return thisKey = '';
    //     })
    //     this.setState({ 
    //         [backend]: newArray,
    //         [thisDivOpen]: false,
    //         [nextQuestion]: true,
    //         addPageSpacing: '0em'
    //     });

    //     if(backend === 'impactedIndustry'){
    //         this.setState(prevState => { 
    //             return { trackMoreInfo: prevState.trackMoreInfo + 1 }
    //         })
    //     } else if (backend === 'customerType') {
    //         this.setState(prevState => { 
    //             return { trackMoreInfo: prevState.trackMoreInfo + 1 }
    //         })
    //     } else if (backend === 'traffickingType'){
    //         this.setState(prevState => { 
    //             return { trackMoreInfo: prevState.trackMoreInfo + 1 }
    //         })
    //     }
    // }

    // applyOneOptionOnly = (name, options, backend, thisDivOpen, nextQuestion) => {
    //     if (this.state[name]){
    //         this.setState({
    //             [backend]: this.state[name],
    //             [thisDivOpen]: false,
    //             [nextQuestion]: true
    //         })
    //     } else {
    //         let newArray = [];
    //         let thisKey;
    //         Object.keys(this.state[options]).map(key => {
    //             if (this.state[options][key] === true) {
    //                 thisKey = key;
    //                 newArray.push(thisKey)
    //             }
    //             return thisKey = '';
    //         })
    //         this.setState({ 
    //             [backend]: newArray,
    //             [thisDivOpen]: false,
    //             [nextQuestion]: true,
    //             addPageSpacing: '0em'
    //         });
    //     }
    // }

//*** Detailed Description
    description = (e) => { // Get detailed description
        this.getInfo(e, 'indicatorDetail');
        // Next question is accessable
        if (e.target.value.length >= 3){
            this.setState({ financialAccess: true });
        } else if (e.target.value.length === 0){
            this.setState({ 
                financialAccess: false,
                dTextAreaEmpty: true
            });
        }
    }

    descriptionClick = () => { // Show more rows for description textarea
        this.setState({ 
            openDescription: true,
            dTextAreaEmpty: false,
            descriptionFocus: true
        })
    }

//*** Financial Product
    getFinancialOptions = (name, optionsHolder, allOptions, backend, nextQuestion) => { 
        // Get the chosen financial product
        let financialOptions = {...this.state.financialOptions};
        financialOptions[name] = !this.state.financialOptions[name];
        this.setState({ financialOptions })
    
        this.getOptionHelper(optionsHolder, allOptions, backend, nextQuestion);
    }

    getOtherFinancial = (e, backend, nextQuestion) => {
        // Get "other" text field
        if (e.target.value.length > 2) {
            this.setState({ 
                financialOther: e.target.value,
                [nextQuestion]: true
            }); 
        } else {
            if (this.state.financialProduct) {
                this.setState({ 
                    financialOther: e.target.value,
                    [nextQuestion]: true
                }); 
            } else {
                this.setState({ 
                    financialOther: e.target.value,
                    [nextQuestion]: false
                }); 
            }
            
        }
       
    }

//*** Indicator Types
    getInTypes = (name, optionsHolder, allOptions, backend, nextQuestion) => { // Indicator Types
        let inTypes = {...this.state.inTypes};
        inTypes[name] = !this.state.inTypes[name];
        this.setState({ inTypes })
        
        this.getOptionHelper(optionsHolder, allOptions, backend, nextQuestion);
    }

//*** Source
    getSource = (name, options, backend) => { // Get one source 
        let sources = {...this.state.sources};
        sources[name] = !this.state.sources[name];
        this.setState({ sources })

        let newObj = {};
        let newOptions = {...this.state[options]};
        let thisKey;
        let newBackend = [];

        setTimeout(() => {
            if (sources[name] === true){
                Object.keys(newOptions).map(key => { 
                    thisKey = key;
                    newObj[thisKey] = false;
                    return thisKey = '';
                })
                newObj[name] = true;
                newBackend.push([name])
                this.setState({ 
                    [options]: newObj,
                    [backend]: newBackend,
                    sourceOther: '',
                    sourceOpen: false,
                    uploadDocsAccess: true,
                })
            } else {
                this.setState({
                    [backend]: false,
                    uploadDocsAccess: false,
                })
            }
        }, 10)
    }

    getOtherSource = (e, options, backend, openDiv) => {
        this.setState({ 
            sourceOther: e.target.value
        });

        let newObj = {};
        let newOptions = {...this.state[options]};
        let thisKey;
        Object.keys(newOptions).map(key => {
            thisKey = key;
            newObj[thisKey] = false;
            return thisKey = '';
        })
        this.setState({ 
            [options]: newObj
        })

        if (e.target.value.length >= 3){
            this.setState({ 
                [backend]: e.target.value,
                uploadDocsAccess: true 
            })
        } else {
            this.setState({ 
                [backend]: false,
                uploadDocsAccess: false,
            })
        }
    }

    keyPress = (e, openDiv) => {
        // close open div if "other" text is in focus when enter key is pressed
        if(e.keyCode === 13){
            e.preventDefault();
            this.setState({ 
                [openDiv]: false,
            });
        }
     }

//**** Upload Documents 
    handleUploadingDoc = (event) => {
        let file = event.target.files[0];   
        
        if (file !== undefined) {
            this.setState({ 
                summaryFocus: true,
                newFiles: [...this.state.newFiles, file]
            })
        }
    }


    removeFile = (obj) => {
        let array = [...this.state.newFiles]; 
        let index = array.indexOf(obj);
        if (index !== -1) {
            array.splice(index, 1);

            this.setState({ 
                newFiles: array 
            })
        }
    }


    

//*** Summary
    summary = (e) => { // Get users summary
        this.getInfo(e, 'indicatorSummary');
        // Next question is accessable
        if (e.target.value.length >= 3){
            this.setState(prevState => { 
                return { 
                    industriesAccess: true,
                    showSummarySkipBtn: false,
                    trackMoreInfo: prevState.trackMoreInfo + 1 
                }
            })
        } else if (e.target.value.length === 0){
            this.setState({ 
                industriesAccess: false,
                sTextAreaEmpty: true 
            });
        }
    }

    summaryClick = () => { // Show more rows for summary textarea
        this.setState({ 
            summaryOpen: true,
            sTextAreaEmpty: false,
            summaryFocus: true,
        })
    }


//*** Skip Buttons
    // inputSkip = (e, backend, nextQuestion) => { // skip question and go to next
    //     e.preventDefault();
    //     if(backend === 'indicatorSummary'){
    //         this.setState({
    //             [backend]: ['None'],
    //             summary: ['None'],
    //             showSummarySkipBtn: false,
    //             [nextQuestion]: true
    //         })
    //     } else {
    //         this.setState({
    //             [backend]: ['None'],
    //             [nextQuestion]: true
    //         })
    //     }
        
    // }

//*** Industries Impacted
    getIndustryOptions = (name, optionsHolder, allOptions, backend, nextQuestion) => { // Get the chosen impacted industries
        let industryOptions = {...this.state.industryOptions};
        industryOptions[name] = !this.state.industryOptions[name];
        this.setState({ industryOptions })
        
        this.getOptionHelper(optionsHolder, allOptions, backend, nextQuestion);
    }


//*** Customer Types
    getCustomerOptions = (name, optionsHolder, allOptions, backend, nextQuestion) => { // Get the chosen customer type(s)
        let customerOptions = {...this.state.customerOptions};
        customerOptions[name] = !this.state.customerOptions[name];
        this.setState({ customerOptions })
        
        this.getOptionHelper(optionsHolder, allOptions, backend, nextQuestion);
    }

//*** Trafficking Types 
    getTraffickingOptions = (name, optionsHolder, allOptions, backend, nextQuestion) => { // Get the chosen Trafficking type(s)
        let traffickingOptions = {...this.state.traffickingOptions};
        traffickingOptions[name] = !this.state.traffickingOptions[name];
        this.setState({ traffickingOptions })
        
        this.getOptionHelper(optionsHolder, allOptions, backend, nextQuestion);
    }

//*** Region Type
    getRegions = (name, options, allOptions, backend, nextQuestion) => { // Get Region type(s)
        let regionOptions = {...this.state.regionOptions};
        regionOptions[name] = !this.state.regionOptions[name];
        this.setState({ regionOptions })

        this.getOptionHelper(options, allOptions, backend, nextQuestion);

        // GET ONLY ONE REGION OLD CODE
        // let newObj = {};
        // let newOptions = {...this.state[options]};
        // let thisKey;
        // let newBackend = [];

        // setTimeout(() => {
        //     if (regionOptions[name] === true){
        //         Object.keys(newOptions).map(key => { 
        //             thisKey = key;
        //             newObj[thisKey] = false;
        //             return thisKey = '';
        //         })
        //         newObj[name] = true;
        //         newBackend.push([name])
        //         this.setState({ 
        //             [options]: newObj,
        //             [backend]: newBackend,
        //             regionOpen: false                
        //         })
        //     } else {
        //         this.setState({
        //             [backend]: false,
        //         })
        //     }
        // }, 10)
    }

// Skip additional info
    skipAdditionalInfo = () => {
        let a = new Date().getMonth() + 1;
        let b = new Date().getDate();
        let c = new Date().getFullYear();
        let date;
        if (a < 10){
            date = '0' + a + '/' + b + '/' + c;
        } else {
            date = a + '/' + b + '/' + c;
        }

        let summary;

        if (this.state.indicatorSummary === undefined ){
            summary = false;
        } else {
            summary = this.state.indicatorSummary;
        }

        setTimeout(() => {
            let obj = {
                periodOfTransactionTime: date,
                indicatorDetail: this.state.indicatorDetail,
                financialProduct: [this.state.financialProduct, {"Other": this.state.financialOther}],
                indicatorType: this.state.indicatorType,
                sourceFrom:this.state.sourceFrom,
                newFiles: this.state.newFiles,
                indicatorSummary: summary,
                impactedIndustry: this.state.impactedIndustry,
                customerType: this.state.customerType,
                traffickingType: this.state.traffickingType,
                geographicalRegions: this.state.geographicalRegions,
                sourceDescriptionforFI: this.state.sourceDescriptionforFI,
                approvedStatus: this.state.approvedStatus
            }
            this.props.continueAccess(obj)
        }, 20)
    }



    submitIndicator = () => {
        let a = new Date().getMonth() + 1;
        let b = new Date().getDate();
        let c = new Date().getFullYear();
        let date;
        if (a < 10){
            date = '0' + a + '/' + b + '/' + c;
        } else {
            date = a + '/' + b + '/' + c;
        }

        let summary;

        if (this.state.indicatorSummary === undefined ){
            summary = false;
        } else {
            summary = this.state.indicatorSummary;
        }

        let newFiles;

        if (this.state.newFiles.length > 0) {
            newFiles = this.state.newFiles;
        } else {
            newFiles = false;
        }

        setTimeout(() => {
            let obj = {
                periodOfTransactionTime: date,
                indicatorDetail: this.state.indicatorDetail,
                financialProduct: [this.state.financialProduct, {"Other": this.state.financialOther}],
                indicatorType: this.state.indicatorType,
                sourceFrom:this.state.sourceFrom,
                newFiles: newFiles,
                indicatorSummary: summary,
                impactedIndustry: this.state.impactedIndustry,
                customerType: this.state.customerType,
                traffickingType: this.state.traffickingType,
                geographicalRegions: this.state.geographicalRegions,
                sourceDescriptionforFI: this.state.sourceDescriptionforFI,
                approvedStatus: this.state.approvedStatus
            }
            this.props.continueAccess(obj)
        }, 20)
    }


    componentDidMount() {
        window.scrollTo(0, 0);
            document.addEventListener('mousedown', this.clickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.clickOutside);
    }
    
    render(){

        let fileTable = [];

        if (this.state.newFiles && this.state.newFiles.length) {
            
            fileTable = this.state.newFiles.map((item, index) => {
                let fileIcon = false;
                let fileType = item.name;   
                let extension = fileType.split(".").pop().toLowerCase().toString();

                if (extension) {
                    if (extension === "pdf") {
                        fileIcon = pdf;
                    } else if (extension === "doc" || extension === "docx") {
                        fileIcon = doc;
                    } else if (extension === "xls" || extension === "csv" || extension === "xlsx") {
                        fileIcon = excel;
                    } else {
                        fileIcon = pdf;
                    }
                }

                return (
                    <div key={index} className={'flexRow fileTableRows'}>
                        {fileIcon !== false ?
                            <div className='fileIcon'>
                                <Image
                                    width='auto'
                                    height='100%'
                                    src={`${fileIcon}`}
                                />
                            </div>
                        : null}
                        <p className='noMargin fileName'>{item.name}</p>
                        <div className='removeFileIconDiv'>
                            <Image 
                                className={'removeFileIcon pointer'}
                                src={require('../../Styles/Images/redRemoveCircle.png')}
                                onClick={(e) => this.removeFile(item)}
                            />
                        </div>
                    </div>
                ) 
            })
        }

//*** Financial Products
        let financialOptions = [];
        if (this.state.financialOptions){
            financialOptions = Object.keys(this.state.financialOptions).map(key => {
                return (                                 
                    <Checkbox
                        name='financialCheckbox' 
                        className={this.state.financialOptions[key] ? 'options color-pk selected checked' : 'options color-b'}
                        value={key}
                        key={key}
                        checked={this.state.financialOptions[key] ? 'checked' : ''}
                        readOnly
                        onClick={() => this.getFinancialOptions(key, 'financialOptions', 'allFinancial', 'financialProduct', 'inTypeAccess')}
                    >   
                        <span className='checkmark marginLeft'></span>
                        {key}   
                    </Checkbox>
                )
            });
        }

        let financialBackendText = '' ;
        if (this.state.financialProduct){
            financialBackendText = this.state.financialProduct.map(item =>{
                return (
                    <p className='backendText' key={item}>
                        {item}
                    </p>
                )
            });
        }

//*** Indicator Types 
        let inTypes = [];
        if (this.state.inTypes){
            inTypes = Object.keys(this.state.inTypes).map(key => {
                return (                                 
                    <Checkbox
                        name='inTypeCheckbox' 
                        className={this.state.inTypes[key] ? 'options color-pk selected checked' : 'options color-b'}
                        value={key}
                        key={key}
                        checked={this.state.inTypes[key] ? 'checked' : ''}
                        readOnly
                        onClick={() => this.getInTypes(key, 'inTypes', 'allInTypes', 'indicatorType', 'sourceAccess')}
                    >   
                        <span className='checkmark marginLeft'></span>
                        {key}   
                    </Checkbox>
                )
            });
        }

        let inTypeBackendText = '' ;
        if (this.state.indicatorType){
            inTypeBackendText = this.state.indicatorType.map(item =>{
                return (
                    <p className='backendText' key={item}>
                        {item}
                    </p>
                )
            });
        }

//*** Source Types
        let sources = [];
        if (this.state.sources){
            sources = Object.keys(this.state.sources).map(key => {
                return(                                 
                    <Checkbox
                        name='sourceCheckbox' 
                        className={this.state.sources[key] ? 'options color-pk selected checked' : 'options color-b'}
                        value={key}
                        key={key}
                        checked={this.state.sources[key] ? 'checked' : ''}
                        readOnly
                        onClick={() => this.getSource(key, 'sources', 'sourceFrom')}
                    >   
                        <span className='checkmark marginLeft'></span>
                        {key}   
                    </Checkbox>
                )
            });
        }

        // let sourceBackendText = '' ;
        // if (this.state.sourceFrom){
        //     sourceBackendText = this.state.sourceFrom.map(item =>{
        //         return (
                    
        //         )
        //     });
        // }

//*** Industries Impacted
        let industryOptions = [];
        if (this.state.industryOptions){
            industryOptions = Object.keys(this.state.industryOptions).map(key => {
                return (                                 
                    <Checkbox
                        name='industryCheckbox' 
                        className={this.state.industryOptions[key] ? 'options color-pk selected checked' : 'options color-b'}
                        value={key}
                        key={key}
                        checked={this.state.industryOptions[key] ? 'checked' : ''}
                        readOnly
                        onClick={() => this.getIndustryOptions(key, 'industryOptions', 'allIndustries', 'impactedIndustry', 'customerAccess')}
                    >   
                        <span className='checkmark marginLeft'></span>
                        {key}   
                    </Checkbox>
                )
            });
        }

        let industryBackendText = '' ;
        if (this.state.impactedIndustry){
            industryBackendText = this.state.impactedIndustry.map(item =>{
                return (
                    <p className='backendText' key={item}>
                        {item}
                    </p>
                )
            });
        }

//*** Customer Types 
        let customerOptions = [];
        if (this.state.customerOptions){
            customerOptions = Object.keys(this.state.customerOptions).map(key => {
                return (                                 
                    <Checkbox
                        name='customerCheckbox' 
                        className={this.state.customerOptions[key] ? 'options color-pk selected checked' : 'options color-b'}
                        value={key}
                        key={key}
                        checked={this.state.customerOptions[key] ? 'checked' : ''}
                        readOnly
                        onClick={() => this.getCustomerOptions(key, 'customerOptions', 'allCustomers', 'customerType', 'traffickingAccess')}
                    >   
                        <span className='checkmark marginLeft'></span>
                        {key}   
                    </Checkbox>
                )
            });
        }

        let customerBackendText = '' ;
        if (this.state.customerType){
            customerBackendText = this.state.customerType.map(item =>{
                return (
                    <p className='backendText' key={item}>
                        {item}
                    </p>
                )
            });
        }

//*** Trafficking Types
        let traffickingOptions = [];
        if (this.state.traffickingOptions){
            traffickingOptions = Object.keys(this.state.traffickingOptions).map(key => {
                return (                                 
                    <Checkbox
                        name='customerCheckbox' 
                        className={this.state.traffickingOptions[key] ? 'options color-pk selected checked' : 'options color-b'}
                        value={key}
                        key={key}
                        checked={this.state.traffickingOptions[key] ? 'checked' : ''}
                        readOnly
                        onClick={() => this.getTraffickingOptions(key, 'traffickingOptions', 'allTrafficking', 'traffickingType', 'regionAccess')}
                    >   
                        <span className='checkmark marginLeft'></span>
                        {key}   
                    </Checkbox>
                )
            });
        }

        let traffickingBackendText = '' ;
        if (this.state.traffickingType){
            traffickingBackendText = this.state.traffickingType.map(item => {
                return (
                    <p className='backendText' key={item}>
                        {item}
                    </p>
                )
            });
        }


//*** Region Type
        let regionOptions = [];
        if (this.state.regionOptions){
            regionOptions = Object.keys(this.state.regionOptions).map(key => {
                return(                                 
                    <Checkbox
                        name='regionCheckbox' 
                        className={this.state.regionOptions[key] ? 'options color-pk selected checked' : 'options color-b'}
                        value={key}
                        key={key}
                        checked={this.state.regionOptions[key] ? 'checked' : ''}
                        readOnly
                        onClick={() => this.getRegions(key, 'regionOptions', 'allRegions', 'geographicalRegions', 'none')}
                    >   
                        <span className='checkmark marginLeft'></span>
                        {key}   
                    </Checkbox>
                )
            });
        }

        let regionsBackendText = '' ;
        if (this.state.geographicalRegions){
            regionsBackendText = this.state.geographicalRegions.map(item =>{
                return (
                    <p className='backendText' key={item}>
                        {item}
                    </p>
                )
            });
        }

//***********************   

        return(
            <div className='indicatorForm'>
                <form>
{/* Description */}
                    <div className='descriptionWrapper'>
                        <FormGroup
                            controlId='description'
                            className={this.state.descriptionFocus ? 'detailFormGroup textAreaGroup active': 'detailFormGroup textAreaGroup inActive'}
                            // validationState={this.getValidationState()}
                        >
                            <ControlLabel className={this.state.dTextAreaEmpty ? 'textAreaTitle' : 'textAreaTitle full'}>The description of the indicator is</ControlLabel>
                           
                            <TextareaAutosize
                                className={this.state.dTextAreaEmpty ? 'textArea leftLine' : 'textArea'}
                                value={this.state.indicatorDetail}
                                rows={1} // min rows
                                onChange={this.description}
                                onClick={this.descriptionClick}
                            />

                           {this.state.dTextAreaEmpty ?
                                <div className='taUnderLineOne'></div>
                            :
                                <div className='taUnderLineTwo'></div>
                            }
                        </FormGroup>
                    </div>

{/* Financial Products */}
                    <div className='qWrappers financialWrapper'>
                        <Button 
                            className={this.state.financialAccess ? 'color-b bold qBtns access' : 'color-b bold qBtns deny'} 
                            disabled={!this.state.financialAccess} 
                            onClick={() => this.openDivHelper('financialOpen', 'financial')}
                            href='#2'
                        >
                            {this.state.financialProduct ?
                                <span className='color-pk opacityText'>
                                    The financial products associated with this indicator are
                                    <Image
                                        className='carrot'
                                        width={'auto'}
                                        height={'auto'}
                                        src={require('../../Styles/Images/pinkDownArrow.png')}
                                    />
                                </span>
                            :
                                <span className='color-b qBtnTitle'>
                                    The financial products associated with this indicator are
                                    <Image
                                        className='carrot'
                                        width={'auto'}
                                        height={'auto'}
                                        src={require('../../Styles/Images/blueDownArrow.png')}
                                    />
                                </span>
                            }
                        </Button>
                        <div className='backendDisplay'>
                            {financialBackendText.length ? financialBackendText : null}
                            {this.state.financialOther &&
                                <p className='backendText'>
                                    <span>Other: </span>
                                    {this.state.financialOther}
                                </p>
                            }
                        </div>
            {/* Open div */}
                        {this.state.financialOpen &&
                            <div 
                                id='2'
                                className={this.state.financialOpen ? 'financial openDiv' : 'financial openDiv closed'}
                                ref={(fpOpenElement) => this.fpOpenElement = fpOpenElement}
                            >
                                <p className='color-b bold headers paddingLeft' style={{cursor: 'pointer'}} onClick={() => this.closeDiv('financialOpen')}>Which financial products are associated with this indicator?</p>
                                <FormGroup controlId='financial'>
                                    <label className='selectAllWrap'>
                                        <span className='color-b normalWeight'>Select all</span>
                                        <Switch 
                                            name='financialRadio'
                                            className={this.state.allFinancial ? 'iFormSwitch allTrue' : 'iFormSwitch allFalse'}
                                            onChange={() => this.selectAllHelper('financialOptions', 'financialProduct', 'allFinancial', 'inTypeAccess')} 
                                            checked={this.state.allFinancial}
                                        />
                                    </label>
                                    
                                    {financialOptions.length ? financialOptions : null}

                                    <FormControl
                                        className='otherText'
                                        type='text'
                                        value={this.state.financialOther}
                                        placeholder='Other (type here)'
                                        maxLength={50}
                                        onChange={(e) => this.getOtherFinancial(e, 'financialProduct', 'inTypeAccess')}
                                        onKeyDown={(e) => this.keyPress(e, 'financialOpen')}
                                        // onClick={this.focusText}
                                    />
                                </FormGroup>

                                {/* <div className='applyWrapper'>
                                    <Button 
                                        type='button'
                                        className={this.state.fpHasSelection ? 'applyBtns marginLeft access' : 'applyBtns marginLeft deny'}
                                        disabled={!this.state.fpHasSelection}
                                        onClick={() => this.applyHelper('financialOptions', 'financialProduct', 'financialOpen', 'inTypeAccess')}
                                    >
                                        APPLY
                                    </Button>
                                </div> */}
                            </div>
                        }
                    </div> {/* .financialWrapper */}
{/* Indicator Type */}
                    <div className='qWrappers inTypesWrapper'>
                        <Button
                            className={this.state.inTypeAccess ? 'color-b bold qBtns access' : 'color-b bold qBtns deny'}
                            disabled={!this.state.inTypeAccess}
                            onClick={() => this.openDivHelper('inTypeOpen', 'inType')}
                        >
                        {this.state.indicatorType ?
                            <span className='color-pk opacityText'>
                                The indicator types are
                                <Image
                                    className='carrot'
                                    width={'auto'}
                                    height={'auto'}
                                    src={require('../../Styles/Images/pinkDownArrow.png')}
                                />
                            </span>
                        :
                            <span className='color-b qBtnTitle'>
                                The indicator types are
                                <Image
                                    className='carrot'
                                    width={'auto'}
                                    height={'auto'}
                                    src={require('../../Styles/Images/blueDownArrow.png')}
                                />
                            </span>
                        }
                        </Button>

                        <div className='backendDisplay'>
                            {inTypeBackendText.length ? inTypeBackendText : null}
                        </div>
                {/* Open div */}
                        {this.state.inTypeOpen &&
                            <div 
                                className={this.state.inTypeOpen ? 'inTypesOpenDiv openDiv' : 'inTypesOpenDiv openDiv closed'}
                                ref={(itOpenElement) => this.itOpenElement = itOpenElement}
                            >
                                <p className='color-b bold headers paddingLeft' style={{cursor: 'pointer'}} onClick={() => this.closeDiv('inTypeOpen')}>Choose indicator type(s)?</p>
                                <FormGroup controlId='inTypes'>
                                    <label className='selectAllWrap'>
                                        <span className='color-b normalWeight'>Select all</span>
                                        <Switch 
                                            name='inTypeRadio'
                                            className={this.state.allInTypes ? 'iFormSwitch allTrue' : 'iFormSwitch allFalse'}
                                            onChange={() => this.selectAllHelper('inTypes', 'indicatorType', 'allInTypes', 'sourceAccess')} 
                                            checked={this.state.allInTypes}
                                        />
                                    </label>
                                    
                                    {inTypes.length ? inTypes : null}
                                </FormGroup>
                                {/* <div className='applyWrapper'>
                                    <Button 
                                        type='button'
                                        className={this.state.itHasSelection ? 'applyBtns marginLeft access' : 'applyBtns marginLeft deny'}
                                        disabled={!this.state.itHasSelection}
                                        onClick={() => this.applyHelper('inTypes', 'indicatorType', 'inTypeOpen', 'sourceAccess')}
                                    >
                                        APPLY
                                    </Button>
                                </div> */}
                            </div>
                        }
                    </div> {/* .inTypesWrapper */}
{/* Source */}
                    <div className='qWrappers sourceWrapper'>
                        <Button
                            className={this.state.sourceAccess ? 'color-b bold qBtns access' : 'color-b bold qBtns deny'}
                            disabled={!this.state.sourceAccess}
                            onClick={() => this.openDivHelper('sourceOpen', 'sources')}
                        >
                        {this.state.sourceFrom ?
                            <span className='color-pk opacityText'>
                                The source of indicator is
                                <Image
                                    className='carrot'
                                    width={'auto'}
                                    height={'auto'}
                                    src={require('../../Styles/Images/pinkDownArrow.png')}
                                />
                            </span>
                        :
                            <span className='color-b qBtnTitle'>
                                The source of indicator is
                                <Image
                                    className='carrot'
                                    width={'auto'}
                                    height={'auto'}
                                    src={require('../../Styles/Images/blueDownArrow.png')}
                                />
                            </span>
                        }
                        </Button>
                        {this.state.sourceFrom &&
                            <div className='backendDisplay'>
                                <p className='backendText'>
                                    {this.state.sourceOther &&
                                        <span>Other: </span>
                                    }
                                    {this.state.sourceFrom}
                                </p>
                            </div>
                        }
            {/* Open Div */}
                        {this.state.sourceOpen &&
                            <div 
                                className={this.state.sourceOpen ? 'sourceOpenDiv openDiv' : 'sourceOpenDiv openDiv closed'}
                                ref={(sOpenElement) => this.sOpenElement = sOpenElement}
                            >
                                <p className='color-b bold headers paddingLeft' style={{cursor: 'pointer'}} onClick={() => this.closeDiv('sourceOpen')}>Choose Source</p>
                                <FormGroup controlId='sources'>
                                    {sources.length ? sources : null}
                                    <FormControl
                                        className='otherText'
                                        type='text'
                                        value={this.state.sourceOther}
                                        placeholder='Other (type here)'
                                        maxLength={50}
                                        onChange={(e) => this.getOtherSource(e, 'sources', 'sourceFrom', 'sourceOpen')}
                                        onKeyDown={(e) => this.keyPress(e, 'sourceOpen')}
                                        // onClick={this.focusText}
                                    />
                                </FormGroup>
                                {/* <div className='applyWrapper'>
                                    <Button 
                                        type='button'
                                        className={this.state.sHasSelection ? 'applyBtns marginLeft access' : 'applyBtns marginLeft deny'}
                                        disabled={!this.state.sHasSelection}
                                        onClick={() => this.applyOneOptionOnly('sourceOther', 'sources', 'sourceFrom', 'sourceOpen', 'uploadDocsAccess')}
                                    >
                                        APPLY
                                    </Button>
                                </div> */}
                            </div>
                        }
                    </div> {/* .sourceWrapper */}
{/* Additional Info */}
                    {this.state.uploadDocsAccess &&
                    <div className='moreInfo access'>
                        {/* {this.state.additionalAccess === false &&
                            <div className='moreInfoDisabled'>
                            </div>
                        } */}
                        <p className='moreInfoTitle'>Additional information</p>
{/* Documents */}
                        <div className='qWrappers uploadDocsWrapper'>
                            <div className='flexRow'>
                                <p className='color-b uploadDocsTitle'>Supporting documentation</p>
                            </div>
                            
                            <div className='docsBackendDisplay'>
                                {fileTable.length ? fileTable : null}
                            </div> 

                            {this.state.showUploadBtn &&
                                <div className='flexRow'>
                                    {this.state.newFiles.length < 1 ?
                                        <Button className='docsBtns uploadFirstDocBtn pointer'>
                                            <span className='pointer'>Upload supporting documentation .pdf, .doc, .xls, .csv or .txt file(s) related to this indicator</span>
                                            <input
                                                title='Upload'
                                                className='hiddenChooseFile pointer'
                                                onChange={ e => this.handleUploadingDoc(e)}
                                                type="file"
                                            />
                                        </Button>
                                    :
                                        <div className='addDocsBtnWrapper'>
                                            <Button className='flexRow docsBtns addDocsBtn pointer'>
                                                <Image
                                                    className='addDocsIcon pointer'
                                                    src={require('../../Styles/Images/pinkPlusIcon.png')}
                                                />
                                                <span className='color-pk pointer'>Add more</span>
                                                <input
                                                    title='Upload'
                                                    className='hiddenChooseFile pointer'
                                                    onChange={ e => this.handleUploadingDoc(e)}
                                                    type="file"
                                                />
                                            </Button>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
{/* Brief Description/Summary */}
                        <div className='qWrappers flexRow briefDescription'>
                            <FormGroup
                                controlId='summary'
                                className={this.state.summaryFocus ? 'summaryFormGroup textAreaGroup active': 'summaryFormGroup textAreaGroup inActive'}
                                // validationState={this.getValidationState()}
                            > 
                                <ControlLabel className={this.state.sTextAreaEmpty ? 'textAreaTitle' : 'textAreaTitle full'}>The brief summary of the indicator is</ControlLabel>
                                
                                <TextareaAutosize
                                    className={this.state.sTextAreaEmpty ? 'textArea leftLine' : 'textArea'}
                                    value={this.state.indicatorSummary}
                                    placeholder='max. 275 characters'
                                    rows={1} // min rows
                                    maxLength={275}
                                    onChange={this.summary}
                                    onClick={this.summaryClick}
                                />
                                {this.state.sTextAreaEmpty ?
                                    <div className='taUnderLineOne leftLine'></div>
                                :
                                    <div className='taUnderLineTwo'></div>
                                }
                            </FormGroup>

                            {/* {this.state.showSummarySkipBtn &&
                                <Button 
                                    className='inputSkip' 
                                    onClick={(e) =>this.inputSkip(e, 'indicatorSummary', 'industriesAccess')}
                                >
                                    Skip
                                </Button>
                            } */}
                        </div>
{/* Associated Industries */}
                        <div className='qWrappers flexRow industriesWrapper'>
                            <div className='inline'>
                                <Button 
                                    className='color-b bold qBtns access'
                                    onClick={() => this.openDivHelper('industriesOpen', 'industry')}
                                    href='#6'
                                >
                                    {this.state.impactedIndustry ?
                                        <span className='color-pk opacityText'>
                                            Associated industries
                                            <Image
                                                className='carrot'
                                                width={'auto'}
                                                height={'auto'}
                                                src={require('../../Styles/Images/pinkDownArrow.png')}
                                            />
                                        </span>
                                    :
                                        <span className='color-b qBtnTitle'>
                                            Choose associated industries
                                            <Image
                                                className='carrot'
                                                width={'auto'}
                                                height={'auto'}
                                                src={require('../../Styles/Images/blueDownArrow.png')}
                                            />
                                        </span>
                                    }
                                </Button>
                                {/* {this.state.impactedIndustry === false &&
                                    <p 
                                        className='inputSkip' 
                                        onClick={(e) => this.inputSkip(e, 'impactedIndustry', 'customerAccess')}
                                    >
                                        skip
                                    </p>
                                } */}
                                
                                <div className='backendDisplay'>
                                    {industryBackendText.length ? industryBackendText : null}
                                </div>
                                
                            </div>
                    {/* Open Div */}
                            {this.state.industriesOpen &&
                                <div 
                                    id='6'
                                    className={this.state.industriesOpen ? 'industry openDiv' : 'industry openDiv closed'}
                                    ref={(aiOpenElement) => this.aiOpenElement = aiOpenElement}
                                >
                                    <p className='color-b bold headers paddingLeft' style={{cursor: 'pointer'}} onClick={() => this.closeDiv('industriesOpen', 'industry')}>Choose associated industries</p>
                                    <FormGroup controlId='industries'>
                                        <label className='selectAllWrap'>
                                            <span className='color-b normalWeight'>Select all</span>
                                            <Switch 
                                                name='industryRadio'
                                                className={this.state.allIndustries ? 'iFormSwitch allTrue' : 'iFormSwitch allFalse'}
                                                checked={this.state.allIndustries}
                                                onChange={() => this.selectAllHelper('industryOptions', 'impactedIndustry', 'allIndustries', 'customerAccess')} 
                                            />
                                        </label>

                                        {industryOptions.length ? industryOptions : null}
                                    </FormGroup>

                                    {/* <div className='applyWrapper'>
                                        <Button 
                                            type='button'
                                            className={this.state.iiHasSelection ? 'applyBtns marginLeft access' : 'applyBtns marginLeft deny'}
                                            disabled={!this.state.iiHasSelection}
                                            onClick={() => this.applyHelper('industryOptions', 'impactedIndustry', 'industriesOpen', 'customerAccess')}
                                        >
                                            APPLY
                                        </Button>
                                    </div> */}
                                </div>
                            }
                        </div>  {/* .industriesWrapper */}
{/* Customer Types */}
                        <div className='qWrappers flexRow customerWrapper'>
                            <div className='inline'>
                                <Button 
                                    className='color-b bold qBtns access'
                                    onClick={() => this.openDivHelper('customerOpen', 'customer')}
                                >
                                    {this.state.customerType ?
                                        <span className='color-pk opacityText'>
                                            Customer type(s)
                                            <Image
                                                className='carrot'
                                                width={'auto'}
                                                height={'auto'}
                                                src={require('../../Styles/Images/pinkDownArrow.png')}
                                            />
                                        </span>
                                    :
                                        <span className='color-b qBtnTitle'>
                                            Choose customer type(s)
                                            <Image
                                                className='carrot'
                                                width={'auto'}
                                                height={'auto'}
                                                src={require('../../Styles/Images/blueDownArrow.png')}
                                            />
                                        </span>
                                    }
                                </Button>

                                {/* {this.state.customerType === false  &&
                                    <p
                                        className='inputSkip' 
                                        onClick={(e) => this.inputSkip(e, 'customerType', 'traffickingAccess')}
                                    >
                                        skip
                                    </p>
                                } */}
                                
                                <div className='backendDisplay'>
                                    {customerBackendText.length ? customerBackendText : null}
                                </div>
                                
                            </div>
                    {/* Open Div */}
                            {this.state.customerOpen &&
                                <div 
                                    className={this.state.customerOpen ? 'customerOpenDiv openDiv' : 'customerOpenDiv openDiv closed'}
                                    ref={(ctOpenElement) => this.ctOpenElement = ctOpenElement}
                                >
                                    <p className='color-b bold headers paddingLeft' style={{cursor: 'pointer'}} onClick={() => this.closeDiv('customerOpen')}>Choose customer type(s)</p>
                                    <FormGroup controlId='customers'>
                                        <label className='selectAllWrap'>
                                            <span className='color-b normalWeight'>Select all</span>
                                            <Switch 
                                                name='customerRadio'
                                                className={this.state.allCustomers ? 'iFormSwitch allTrue' : 'iFormSwitch allFalse'}
                                                checked={this.state.allCustomers}
                                                onChange={() => this.selectAllHelper('customerOptions', 'customerType', 'allCustomers', 'traffickingAccess')} 
                                            />
                                        </label>
                                    
                                        {customerOptions.length ? customerOptions : null}
                                    </FormGroup>

                                    {/* <div className='applyWrapper'>
                                        <Button 
                                            type='button'
                                            className={this.state.ctHasSelection ? 'applyBtns marginLeft access' : 'applyBtns marginLeft deny'}
                                            disabled={!this.state.ctHasSelection}
                                            onClick={() => this.applyHelper('customerOptions', 'customerType', 'customerOpen', 'traffickingAccess')}
                                        >
                                            APPLY
                                        </Button>
                                    </div> */}
                                </div>
                            }
                        </div>  {/* .customerWrapper */}
{/* Trafficking Types */}
                        <div className='qWrappers traffickingWrapper'>
                            <div className='inline'>
                                <Button 
                                    className='color-b bold qBtns access'
                                    onClick={() => this.openDivHelper('traffickingOpen', 'trafficking')}
                                >
                                    {this.state.traffickingType ?
                                        <span className='color-pk opacityText'>
                                            Trafficking type(s)
                                            <Image
                                                className='carrot'
                                                width={'auto'}
                                                height={'auto'}
                                                src={require('../../Styles/Images/pinkDownArrow.png')}
                                            />
                                        </span>
                                    :
                                        <span className='color-b qBtnTitle'>
                                            Choose trafficking type(s)
                                            <Image
                                                className='carrot'
                                                width={'auto'}
                                                height={'auto'}
                                                src={require('../../Styles/Images/blueDownArrow.png')}
                                            />
                                        </span>
                                    }
                                </Button>

                                {/* {this.state.traffickingType === false &&
                                //     <p 
                                //         className='inputSkip' 
                                //         onClick={(e) => this.inputSkip(e, 'traffickingType', 'regionAccess')}
                                //     >
                                //         skip
                                //     </p>
                                // } */}
                                
                                <div className='backendDisplay'>
                                    {traffickingBackendText.length ? traffickingBackendText : null}
                                </div>
                                
                            </div>
                    {/* Open Div */}
                            {this.state.traffickingOpen &&
                                <div 
                                    className={this.state.traffickingOpen ? 'trafficking openDiv' : 'trafficking openDiv closed'}
                                    ref={(ttOpenElement) => this.ttOpenElement = ttOpenElement}
                                >
                                    <p className='color-b bold headers paddingLeft' style={{cursor: 'pointer'}} onClick={() => this.closeDiv('traffickingOpen')}>Choose trafficking type(s)</p>
                                    <FormGroup controlId='trafficking'>
                                        <label className='selectAllWrap'>
                                            <span className='color-b normalWeight'>Select all</span>
                                            <Switch 
                                                name='traffickingRadio'
                                                className={this.state.allTrafficking ? 'iFormSwitch allTrue' : 'iFormSwitch allFalse'}
                                                checked={this.state.allTrafficking}
                                                onChange={() => this.selectAllHelper('traffickingOptions', 'traffickingType', 'allTrafficking', 'regionAccess')} 
                                            />
                                        </label>
                                        
                                        {traffickingOptions.length ? traffickingOptions : null}
                                    </FormGroup>

                                    {/* <div className='applyWrapper'>
                                        <Button 
                                            type='button'
                                            className={this.state.ttHasSelection ? 'applyBtns marginLeft access' : 'applyBtns marginLeft deny'}
                                            disabled={!this.state.ttHasSelection}
                                            onClick={() => this.applyHelper('traffickingOptions', 'traffickingType', 'traffickingOpen', 'regionAccess')}
                                        >
                                            APPLY
                                        </Button>
                                    </div> */}
                                </div>
                            }
                        </div>  {/* .traffickingWrapper */}
{/* Geographical Region */}
                        <div className='qWrappers regionWrapper'>
                            <div className='inline'>
                                <Button
                                    className='color-b bold qBtns access'
                                    onClick={() => this.openDivHelper('regionOpen', 'regions')}
                                >
                                {this.state.geographicalRegions ?
                                    <span className='color-pk opacityText'>
                                        Geographical region
                                        <Image
                                            className='carrot'
                                            width={'auto'}
                                            height={'auto'}
                                            src={require('../../Styles/Images/pinkDownArrow.png')}
                                        />
                                    </span>
                                :
                                    <span className='color-b qBtnTitle'>
                                        Choose geographical region
                                        <Image
                                            className='carrot'
                                            width={'auto'}
                                            height={'auto'}
                                            src={require('../../Styles/Images/blueDownArrow.png')}
                                        />
                                    </span>
                                }
                                </Button>

                                {/* {this.state.geographicalRegions === false &&
                                    <p 
                                        className='inputSkip' 
                                        onClick={(e) => this.inputSkip(e, 'geographicalRegions', 'continueAccess')}
                                    >
                                        skip
                                    </p>
                                } */}

                                {/* For only one region to show */}
                                {/* {this.state.geographicalRegions &&
                                    <div className='backendDisplay'>
                                        <p className='backendText'>
                                            {this.state.geographicalRegions}
                                        </p>
                                    </div>
                                } */}
                                {/* For more than one region to show */}
                                <div className='backendDisplay'>
                                    {regionsBackendText.length ? regionsBackendText : null}
                                </div>
                            </div>
                {/* Open Div */}
                            {this.state.regionOpen &&
                                <div 
                                    className={this.state.regionOpen ? 'regionOpenDiv openDiv' : 'regionOpenDiv openDiv closed'}
                                    ref={(grOpenElement) => this.grOpenElement = grOpenElement}
                                >
                                    <p className='color-b bold headers paddingLeft' style={{cursor: 'pointer'}} onClick={() => this.closeDiv('regionOpen')}>Choose a Geographical Region</p>
                                    <FormGroup controlId='region'>
                                        <label className='selectAllWrap'>
                                            <span className='color-b normalWeight'>Select all</span>
                                            <Switch 
                                                name='regionRadio'
                                                className={this.state.allRegions ? 'iFormSwitch allTrue' : 'iFormSwitch allFalse'}
                                                checked={this.state.allRegions}
                                                onChange={() => this.selectAllHelper('regionOptions', 'geographicalRegions', 'allRegions', 'none')} 
                                            />
                                        </label>
                                        {regionOptions.length ? regionOptions : null}
                                        {/* <FormControl
                                            className='otherText'
                                            type='text'
                                            value={this.state.regionOther}
                                            placeholder='Other (type here)'
                                            maxLength={50}
                                            onChange={(e) => this.getOtherRegion('regionOptions', 'grHasSelection', e)}
                                            // onClick={this.focusText}
                                        /> */}
                                    </FormGroup>
                                    {/* <div className='applyWrapper'>
                                        <Button 
                                            type='button'
                                            className={this.state.grHasSelection ? 'applyBtns marginLeft access' : 'applyBtns marginLeft deny'}
                                            disabled={!this.state.grHasSelection}
                                            onClick={() => this.applyOneOptionOnly('regionOther', 'regionOptions', 'geographicalRegions', 'regionOpen', 'continueAccess')}
                                        >
                                            APPLY
                                        </Button>
                                    </div> */}
                                </div>
                            }
                        </div>  {/* .regionWrapper */}
                    </div> // .moreInfo
                    }
                    <div style={{ width: '100%', height: this.state.extraSpace }}></div>
{/* Skip Additional Info and Submit */}
                    {this.state.uploadDocsAccess &&
                        <div>
    {/* Skip And Submit btns */}
                        {this.state.trackMoreInfo >= 1 ?
                            <Button 
                                className='skipMoreInfo submitBtn' 
                                onClick={() => this.submitIndicator()}
                            >
                                Submit Indicator
                                <Image
                                    className='skipArrow'
                                    width='auto'
                                    height='auto'
                                    src={require('../../Styles/Images/pinkRightArrow.png')}
                                />
                            </Button>
                        :
                            <Button 
                                className='skipMoreInfo' 
                                onClick={this.skipAdditionalInfo}
                            >
                                Skip additional information{this.props.mobileMode && <br></br>} and submit the indicator
                                <Image
                                    className='skipArrow'
                                    width='auto'
                                    height='auto'
                                    src={require('../../Styles/Images/pinkRightArrow.png')}
                                />
                            </Button>
                        }
                    </div>
                }
                    

{/* Continue Btn */}
                    {/* {this.state.continueAccess &&
                        <div>
                            <Button 
                                className='uppercase applyBtns submitBtn'
                                onClick={() => this.props.continueAccess('testing')}
                            >
                                Continue
                            </Button>
                        </div>
                    } */}

                </form>
            </div>
        )
    }
}