import React, { Component } from 'react';
import { LeftSideBar } from '../../Components/LeftSideBar';
import { RightSideBar } from '../../Components/RightSideBar';
import { OrgsCard } from './OrgsCard';

import acLogoImg from '../../Styles/Images/anniecannons-blue.png';
import mekongImg from '../../Styles/Images/mekong-blue.png';
import tReuters from '../../Styles/Images/reuters-blue.png';
import bFreedom from '../../Styles/Images/beautyForFreedom-blue.png';
import libertyShared from '../../Styles/Images/liberty-blue.png';
import scelles from '../../Styles/Images/scelles-blue.png';

import { Footer } from '../../Components/Footer';

export class SupportingOrgs extends Component {
    constructor(props){
        super(props);
        this.state = {
            mobileMode: false,
            orgs: [
                {
                    id: 1,
                    logo: acLogoImg,
                    details: "Non-governmental organization whose mission is to help trafficking survivors obtain a sustainable income through coding.",
                    website: "anniecannons.org"              
                },
                {
                    id: 2,
                    logo: mekongImg,
                    details: "Non-profit that aims to unite and mobilize the private sector for a common cause. To disrupt and end modern slavery.",
                    website: "themekongclub.org"      
                },
                {
                    id: 3,
                    logo: tReuters,
                    details: "We are the philanthropic arm of Thomson Reuters, the world's biggest news and information provider.",
                    website: "trust.org"       
                },
                {
                    id: 4,
                    logo: bFreedom,
                    details: "A non-profit dedicated to empowering survivors of human trafficking and youth through the creative arts and skills training",
                    website: "beautyforfreedom.org"       
                },
                {
                    id: 5,
                    logo: libertyShared,
                    details: "Non-profit that works to prevent human trafficking through legal advocacy, technological interventions, and strategic collaborations with NGOs & corporations in Asia and globally.",
                    website: "libertyshared.org"      
                },
                {
                    id: 6,
                    logo: scelles,
                    details: "Non-profit that focuses on combatting all forms of commercial sexual exploitation by producing high-quality research materials to educate the public and various stakeholders on the complexities of the commercial sexual exploitation of women and children.",
                    website: "fondationscelles.org"      
                },
            ]
        }
    }

    checkForMobileMode = () => {
        this.setState({ mobileMode: window.innerWidth <= 414 });
    }

    componentWillMount(){
        window.addEventListener('resize', this.checkForMobileMode.bind(this));
        this.checkForMobileMode();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkForMobileMode.bind(this));
    }

    render() {

        let orgs = '';
        if (this.state.orgs){
            orgs =  this.state.orgs.map((item, index) => {
                return (
                    <div className='orgsWrappers' key={item.id}>
                        <OrgsCard
                            id={item.id}
                            obj={item}
                            // desktopMode={this.state.desktopMode}
                        />
                    </div>
                )
            });
        }

        return (
            <div className='supportingOrgs'>
                {/* <Navbar color='blue' /> */}
                <LeftSideBar page={'supportingOrgs'} />
                <RightSideBar page={'supportingOrgs'} userHasAuthenticated = {this.props.userHasAuthenticated} />

                <div className='innerPage '> 
                    <p className='color-b title'>Supporting Organizations</p>
                    {orgs.length ? orgs : null}
                </div>

                <Footer /> 
            </div>
        )
    }
} //section1 orgs