import React, {Component } from 'react';
import  { Link } from 'react-router-dom';
import { 
    Button, 
    Checkbox,
    Image } from 'react-bootstrap';
import Switch from 'react-switch';
import { LeftSideBar } from './LeftSideBar';


export class CategoriesPanel extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,

            categories: 0,
            selections: {
                financialProduct: [],
                impactedIndustry: [],
                customerType: [],
                traffickingType: [],
                geographicalRegions: [],
                sourceFrom: []
            },

            financialOptions: {'Commercial Banking': false, 'Correspondent Banking': false, 'Credit Cards': false, 'Lending': false, 'Money Services Businesses':false, 'Prepaid Cards': false, 'Remote Deposit Capture':false, 'Retail Banking': false, 'Virtual Currency': false, 'Wire Transfer': false },
            allFinancial: false,
            financialProduct : false,

            industryOptions: {'Agriculture & Animal Husbandry': false, 'Arts & Entertainment': false, 'Bars, Strip Clubs & Cantinas': false, 'Carnivals': false, 'Commercial Cleaning Services': false,'Construction': false, 'Domestic Work': false, 'Escort Services': false, 'Factories & Manufacturing': false, 'Forestry & Logging': false, 'Health & Beauty Services': false, 'Health Care': false, 'Hotels & Hospitality': false, 'Illicit Massage, Health & Beauty': false, 'Landscaping': false, 'Illicit Activities': false, 'Outdoor Solicitation': false, 'Peddling & Begging': false,'Personal Sexual Servitude': false, 'Pornography': false, 'Recreational Facilities': false, 'Remote Interactive Sexual Acts': false, 'Residential': false, 'Restaurant & Food Services': false, 'Traveling Sales Crews': false },
            allIndustries: false,
            impactedIndustry: false,

            customerOptions: {'Labor Agency': false, 'Recruiter':  false, 'Transporter': false, 'Victim': false, 'Other Trafficker': false },
            allCustomers: false,
            customerType: false,

            traffickingOptions: {'Child Exploitation': false, 'Labor': false, 'Sex': false },
            allTrafficking: false,
            traffickingType: false,

            regionOptions: {'Africa': false, 'Asia': false, 'Canada': false, 'Caribbean': false, 'Europe': false, 'Latin America': false, 'Middle East': false, 'United States': false },
            allRegions: false,
            geographicalRegions: false,

            sources: {'Law Enforcement': false, 'Financial Institution': false, 'NGO': false},
            allSources: false,
            sourceFrom: false,
        }
    }

    openCategoriesPanel = (e) => {
        console.log();
    }


    getOptionHelper = (optionsHolder, allOptions, backend) => {
        if (this.state[allOptions] === true){
            this.setState({ [allOptions]: false });
        }
        
        let newArray = [];
        // let newBackend = [];
        let thisKey;
        let fullBackend = {...this.state[backend]};
        let newSelections = {...this.state.selections};

        setTimeout(() => {
            Object.keys(this.state[optionsHolder]).map(key => {
                if (this.state[optionsHolder][key] === true) {
                    thisKey = key;
                    newArray.push(thisKey)
                }
                return thisKey = '';
            })
            
        }, 100)
        setTimeout(() => {
            if (newArray.length >= 1) {
                fullBackend = newArray;
                newSelections[backend] = newArray;
                
                this.setState({ 
                        [backend]: fullBackend,
                        selections: newSelections
                })
            } else {
                newSelections[backend] = []

                this.setState({
                        [backend]: false,
                        selections: newSelections                  
                })
            }
        }, 200)
        setTimeout(() => {
            let newCategories = newSelections.financialProduct.length + newSelections.impactedIndustry.length + newSelections.customerType.length + newSelections.traffickingType.length + newSelections.geographicalRegions.length + newSelections.sourceFrom.length;
            this.setState({
                categories: newCategories
            })

        }, 300)
    }


    selectAllHelper = (options, backend, allOptions) => { // Reusable, select all question options
        let keyValues = {};
        let newArray = [];
        let newOptions = {...this.state[options]};
        let newSelections = {...this.state.selections};
        let thisKey;

        this.setState({ 
            [allOptions]: !this.state[allOptions]
        })

        setTimeout(() => {
            if(this.state[allOptions] === true) {
                Object.keys(newOptions).map(key => {
                    thisKey = key;
                    keyValues[thisKey] = true;
                    newArray.push(thisKey)
                    return thisKey = '';
                })
                newSelections[backend] = newArray;
                this.setState({ 
                    [options]: keyValues,
                    [backend]: newArray,
                    selections: newSelections
                    // categories: newArray.length
                })
            } else {
                Object.keys(newOptions).map(key => {
                    thisKey = key;
                    keyValues[thisKey] = false;
                    newArray.push(thisKey)
                    return thisKey = '';
                })
                newSelections[backend] = [];
                this.setState({ 
                    // [allOptions]: !this.state[allOptions],
                    [options]: keyValues,
                    [backend]: false,
                    selections: newSelections,
                    // categories: 0,
                })
            }
        }, 10)
        setTimeout(() => {
            let newCategories = newSelections.financialProduct.length + newSelections.impactedIndustry.length + newSelections.customerType.length + newSelections.traffickingType.length + newSelections.geographicalRegions.length + newSelections.sourceFrom.length;
            this.setState({
                categories: newCategories
            })

        }, 100)
    }

    //*** Financial Product
    getFinancialOptions = (name, optionsHolder, allOptions, backend) => { // Get the chosen financial product
        let financialOptions = {...this.state.financialOptions};
        financialOptions[name] = !this.state.financialOptions[name];
        this.setState({ financialOptions })
    
        this.getOptionHelper(optionsHolder, allOptions, backend);
    }


    //*** Industries Impacted
    getIndustryOptions = (name, optionsHolder, allOptions, backend) => { // Get the chosen impacted industries
        let industryOptions = {...this.state.industryOptions};
        industryOptions[name] = !this.state.industryOptions[name];
        this.setState({ industryOptions })
        
        this.getOptionHelper(optionsHolder, allOptions, backend);
    }


    //*** Customer Types
    getCustomerOptions = (name, optionsHolder, allOptions, backend) => { // Get the chosen customer types
        let customerOptions = {...this.state.customerOptions};
        customerOptions[name] = !this.state.customerOptions[name];
        this.setState({ customerOptions })
        
        this.getOptionHelper(optionsHolder, allOptions, backend);
    }


    //*** Trafficking Types 
    getTraffickingOptions = (name, optionsHolder, allOptions, backend) => { // Get the chosen Trafficking types
        let traffickingOptions = {...this.state.traffickingOptions};
        traffickingOptions[name] = !this.state.traffickingOptions[name];
        this.setState({ traffickingOptions })
        
        this.getOptionHelper(optionsHolder, allOptions, backend);
    }


    //*** Geographical Regions 
    getRegions = (name, optionsHolder, allOptions, backend) => { // Get the chosen Trafficking types
        let regionOptions = {...this.state.regionOptions};
        regionOptions[name] = !this.state.regionOptions[name];
        this.setState({ regionOptions })
        
        this.getOptionHelper(optionsHolder, allOptions, backend);
    }


    //*** Sources
    getSources = (name, optionsHolder, allOptions, backend) => { // Get the choosen Sources 
        let sources = {...this.state.sources};
        sources[name] = !this.state.sources[name];
        this.setState({ sources })

        this.getOptionHelper(optionsHolder, allOptions, backend);
    }


    updateSelections = () => {
        this.props.actions.updateSelections(this.state.selections);
        this.props.actions.closePanel();
    }

    updateOnlyOneSelection = (obj) => {
        this.setState({
            categories: 1,
        })
        this.props.actions.updateSelections(obj);
        this.props.actions.closePanel();
    }

    render() {

        //*** Financial Products  */
        let financialOptions = [];
        if (this.state.financialOptions){
            financialOptions = Object.keys(this.state.financialOptions).map(key => {
                return (                                 
                    <Checkbox
                        name='financialCheckbox' 
                        className={this.state.financialOptions[key] ? 'options color-pk selected checked' : 'options color-b'}
                        value={key}
                        key={key}
                        checked={this.state.financialOptions[key] ? 'checked' : ''}
                        readOnly
                        onClick={() => this.getFinancialOptions(key, 'financialOptions', 'allFinancial', 'financialProduct')}
                    >   
                        <span className='checkmark marginLeft'></span>
                        
                        <span className='color-b light key'>{key}</span>  

                        {this.state.categories === 0 &&
                            <span 
                                className= 'color-b uppercase onlyTextLink' 
                                onClick={() => this.updateOnlyOneSelection({
                                    
                                        financialProduct: [key],
                                        impactedIndustry: [],
                                        customerType: [],
                                        traffickingType: [],
                                        geographicalRegions: [],
                                        sourceFrom: [],
                                    }
                                )}>
                            only
                            </span>
                        }
                    </Checkbox>
                )
            });
        }


    //*** Industries Impacted
        let industryOptions = [];
        if (this.state.industryOptions){
            industryOptions = Object.keys(this.state.industryOptions).map(key => {
                return (                                 
                    <Checkbox
                        name='industryCheckbox' 
                        className={this.state.industryOptions[key] ? 'options color-pk selected checked' : 'options color-b'}
                        value={key}
                        key={key}
                        checked={this.state.industryOptions[key] ? 'checked' : ''}
                        readOnly
                        onClick={() => this.getIndustryOptions(key, 'industryOptions', 'allIndustries', 'impactedIndustry')}
                    >   
                        <span className='checkmark marginLeft'></span>

                        <span className='color-b light key'>{key}</span> 

                        {this.state.categories === 0 &&
                            <span 
                                className= 'color-b uppercase onlyTextLink' 
                                onClick={() => this.updateOnlyOneSelection({
                                        financialProduct: [],
                                        impactedIndustry: [key],
                                        customerType: [],
                                        traffickingType: [],
                                        geographicalRegions: [],
                                        sourceFrom: [],
                                    }
                                )}>
                            only
                            </span>
                        }
                    </Checkbox>
                )
            });
        }


    //*** Customer Types 
        let customerOptions = [];
        if (this.state.customerOptions){
            customerOptions = Object.keys(this.state.customerOptions).map(key => {
                return (                                 
                    <Checkbox
                        name='customerCheckbox' 
                        className={this.state.customerOptions[key] ? 'options color-pk selected checked' : 'options color-b'}
                        value={key}
                        key={key}
                        checked={this.state.customerOptions[key] ? 'checked' : ''}
                        readOnly
                        onClick={() => this.getCustomerOptions(key, 'customerOptions', 'allCustomers', 'customerType')}
                    >   
                        <span className='checkmark marginLeft'></span>

                        <span className='color-b light key'>{key}</span>  

                        {this.state.categories === 0 &&
                            <span 
                                className= 'color-b uppercase onlyTextLink' 
                                onClick={() => this.updateOnlyOneSelection({
                                        financialProduct: [],
                                        impactedIndustry: [],
                                        customerType: [key],
                                        traffickingType: [],
                                        geographicalRegions: [],
                                        sourceFrom: [],
                                    }
                                )}>
                            only
                            </span>
                        }
                    </Checkbox>
                )
            });
        }


    //*** Trafficking Types
        let traffickingOptions = [];
        if (this.state.traffickingOptions){
            traffickingOptions = Object.keys(this.state.traffickingOptions).map(key => {
                return (                                 
                    <Checkbox
                        name='customerCheckbox' 
                        className={this.state.traffickingOptions[key] ? 'options color-pk selected checked' : 'options color-b'}
                        value={key}
                        key={key}
                        checked={this.state.traffickingOptions[key] ? 'checked' : ''}
                        readOnly
                        onClick={() => this.getTraffickingOptions(key, 'traffickingOptions', 'allTrafficking', 'traffickingType')}
                    >   
                        <span className='checkmark marginLeft'></span>
                        
                        <span className='color-b light key'>{key}</span>  

                        {this.state.categories === 0 &&
                            <span 
                                className= 'color-b uppercase onlyTextLink' 
                                onClick={() => this.updateOnlyOneSelection({
                                        financialProduct: [],
                                        impactedIndustry: [],
                                        customerType: [],
                                        traffickingType: [key],
                                        geographicalRegions: [],
                                        sourceFrom: [],
                                    }
                                )}>
                            only
                            </span>
                        }
                    </Checkbox>
                )
            });
        }


    //*** Region Types
        let regionOptions = [];
        if (this.state.regionOptions){
            regionOptions = Object.keys(this.state.regionOptions).map(key => {
                return(                                 
                    <Checkbox
                        name='regionCheckbox' 
                        className={this.state.regionOptions[key] ? 'options color-pk selected checked' : 'options color-b'}
                        value={key}
                        key={key}
                        checked={this.state.regionOptions[key] ? 'checked' : ''}
                        readOnly
                        onClick={() => this.getRegions(key, 'regionOptions', 'allRegions', 'geographicalRegions')}
                    >   
                        <span className='checkmark marginLeft'></span>

                        <span className='color-b light key'>{key}</span>  

                        {this.state.categories === 0 &&
                            <span 
                                className= 'color-b uppercase onlyTextLink' 
                                onClick={() => this.updateOnlyOneSelection({
                                        financialProduct: [],
                                        impactedIndustry: [],
                                        customerType: [],
                                        traffickingType: [],
                                        geographicalRegions: [key],
                                        sourceFrom: [],
                                    }
                                )}>
                            only
                            </span>
                        }
                    </Checkbox>
                )
            });
        }


    //*** Source From
        let sources = [];
        if (this.state.sources){
            sources = Object.keys(this.state.sources).map(key => {
                return(                                 
                    <Checkbox
                        name='sourceCheckbox' 
                        className={this.state.sources[key] ? 'options color-pk selected checked' : 'options color-b'}
                        value={key}
                        key={key}
                        checked={this.state.sources[key] ? 'checked' : ''}
                        readOnly
                        onClick={() => this.getSources(key, 'sources', 'allSources', 'sourceFrom')}
                    >   
                        <span className='checkmark marginLeft'></span>
                        
                        <span className='color-b light key'>{key}</span> 

                        {this.state.categories === 0 &&
                            <span 
                                className= 'color-b uppercase onlyTextLink' 
                                onClick={() => this.updateOnlyOneSelection({
                                        financialProduct: [],
                                        impactedIndustry: [],
                                        customerType: [],
                                        traffickingType: [],
                                        geographicalRegions: [],
                                        sourceFrom: [key],
                                    }
                                )}>
                            only
                            </span>
                        }
                    </Checkbox>
                )
            });
        }



        return (
            <div className={this.props.open ? 'categoriesPanel open' : 'categoriesPanel closed'}>
                <LeftSideBar page={'seeMySelections'} openPanel={this.openCategoriesPanel} />

                <div className='innerPage CategoriesPanelInner'>
                    <div className='header'>
                        <p className='color-b bold pageTitle'>Indicators categories and sub-categories</p>
                        <Button
                            className='closePanelIcon'
                            onClick={this.props.actions.closePanel}
                        />
                    </div>

                    <div className='catRow'>
                        <div className='catColumns one'>
    {/* Financial Product */}
                            <div className='wholeCategories'>
                                <p className='light catTitles'>What <strong>Financial product</strong></p>

                                <div className='catSelections'>
                                    <label className='selectAllWrap'>
                                            <span className='color-b light'>Select all</span>
                                            <Switch 
                                                name='financialRadio'
                                                className={this.state.allFinancial ? 'iFormSwitch allTrue' : 'iFormSwitch allFalse'}
                                                onChange={() => this.selectAllHelper('financialOptions', 'financialProduct', 'allFinancial')} 
                                                checked={this.state.allFinancial}
                                            />
                                        </label>
                                    {financialOptions.length ? financialOptions : null}
                                </div>
                            </div>
                        </div>

                        <div className='catColumns two'>
    {/* Industry */}
                            <div className='wholeCategories two'>
                                <p className='light catTitles'>Where <strong>Impacted industry</strong></p>

                                <div className='catSelections'>
                                    <label className='selectAllWrap'>
                                        <span className='color-b normalWeight'>Select all</span>
                                        <Switch 
                                            name='industryRadio'
                                            className={this.state.allIndustries ? 'iFormSwitch allTrue' : 'iFormSwitch allFalse'}
                                            onChange={() => this.selectAllHelper('industryOptions', 'impactedIndustry', 'allIndustries')} 
                                            checked={this.state.allIndustries}
                                        />
                                    </label>
                                    {industryOptions.length ? industryOptions : null}
                                </div>
                            </div>
                        </div>

                        <div className='catColumns three'>
    {/* Customer type */}
                            <div className='wholeCategories three'>
                                <p className='light catTitles'>Who <strong>Customer type</strong></p>

                                <div className='catSelections'>
                                    <label className='selectAllWrap'>
                                        <span className='color-b normalWeight'>Select all</span>
                                        <Switch 
                                            name='customerRadio'
                                            className={this.state.allCustomers ? 'iFormSwitch allTrue' : 'iFormSwitch allFalse'}
                                            onChange={() => this.selectAllHelper('customerOptions', 'customerType', 'allCustomers')} 
                                            checked={this.state.allCustomers}
                                        />
                                    </label>
                                    {customerOptions.length ? customerOptions : null}
                                </div>
                            </div>
    {/* Trafficking type */}
                            <div className='wholeCategories four'>
                                <p className='light catTitles'>What <strong>Trafficking type</strong></p>

                                <div className='catSelections'>
                                    <label className='selectAllWrap'>
                                        <span className='color-b normalWeight'>Select all</span>
                                        <Switch 
                                            name='traffickingRadio'
                                            className={this.state.allTrafficking ? 'iFormSwitch allTrue' : 'iFormSwitch allFalse'}
                                            onChange={() => this.selectAllHelper('traffickingOptions', 'traffickingType', 'allTrafficking')} 
                                            checked={this.state.allTrafficking}
                                        />
                                    </label>
                                    {traffickingOptions.length ? traffickingOptions : null}
                                </div>
                            </div>
    {/* Region */}
                            <div className='wholeCategories five'>
                                <p className='light catTitles'>Location <strong>Geographical region</strong></p>

                                <div className='catSelections'>
                                    <label className='selectAllWrap'>
                                        <span className='color-b normalWeight'>Select all</span>
                                        <Switch 
                                            name='regionsRadio'
                                            className={this.state.allRegions ? 'iFormSwitch allTrue' : 'iFormSwitch allFalse'}
                                            onChange={() => this.selectAllHelper('regionOptions', 'geographicalRegions', 'allRegions')} 
                                            checked={this.state.allRegions}
                                        />
                                    </label>
                                    {regionOptions.length ? regionOptions : null}
                                </div>
                            </div>

                            <div className='wholeCategories six'>
                                <p className='light catTitles'>From <strong>Source</strong></p>

                                <div className='catSelections'>
                                    <label className='selectAllWrap'>
                                        <span className='color-b normalWeight'>Select all</span>
                                        <Switch 
                                            name='sourcesRadio'
                                            className={this.state.allSources ? 'iFormSwitch allTrue' : 'iFormSwitch allFalse'}
                                            onChange={() => this.selectAllHelper('sources', 'sourceFrom', 'allSources')} 
                                            checked={this.state.allSources}
                                        />
                                    </label>
                                    {sources.length ? sources : null}
                                </div>
                            </div>
                        </div> {/* .catColumns.three */}
                    </div> 
{/* See Indicators buttons   */}
                    <div className='catActionBtnsWrap'>
                        <Button className='catActionBtns catSelectionBtn' onClick={this.updateSelections}>
                            <span className='color-w'>See my selection</span>
                            <Image 
                                className='actionArrows'
                                src={require('../Styles/Images/pinkRightArrow.png')}
                            />
                        </Button>
                        <Link className='catActionBtns catSeeAllBtn' to='/allindicators'>
                            <span className='color-w'>See all indicators</span>
                            <Image 
                                className='actionArrows'
                                src={require('../Styles/Images/pinkRightArrow.png')}
                            />
                        </Link>
                    </div>
                </div> {/* .innerPage */}
            </div>
        )
    }
}