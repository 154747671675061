import React, { Component } from 'react';

import { IndicatorCard } from './IndicatorCard';
// import { PaginationBtns } from './PaginationBtns';

import { API } from 'aws-amplify';

export class ICardsContainer extends Component {
    constructor(props){
        super(props);
        this.state = {
            mobileMode: false,
            tabletMode: false,
            containerLoading: true,
            // columns: 3, // needs to be set when mounted to fit different screen sizes 3, 2, 1 columns
            receivedCards: false,
            cardsLoaded: false,
            // currentPage: 1,
            // cardsPerPage: 6,
        }

        this._isMounted = false;
    }

    checkForMobileMode = () => {
        this.setState({ 
            mobileMode: window.innerWidth <= 620,
            tabletMode: window.innerWidth <= 1024
        });
    }

    // getCardsContainerHeight = () => { // For masonary layout
    //     // Wait 3 seconds for card container to load so we can get the full length of the container and divide that number by a number to create columns
    //     // setTimeout(function () { 
    //     //     let height = this.cardsContainer.clientHeight / 1.1;
    //     //     this.setState({ 
    //     //         cardsContainerHeight: height
    //     //     });
    //     // }.bind(this), 3000)

    //     setTimeout(function () { 
    //         this.setState({ 
    //             containerLoading: false
    //         });
    //     }.bind(this), 3300)
    // }

    addToCounter = () => { // Get total number of iCards that have fully loaded onto the screen and compare with this.cards.length for masonary layout to load properly
        this.cardsReady += 1;
    //    console.log(this.cardsReady)
        if (this.cardsReady === this.cards.length) {
            setTimeout(() => {
                // console.log(this.cardsContainer)
                if (this._isMounted && !this.props.mobileMode) {
                    // let height = this.cardsContainer.clientHeight / 2.9;
                    // this.cardsContainerHeight = height;

                    // if (this.state.cardsContainerHeight > 0) {
                    this.setState({
                        // cardsContainerHeight: height,
                        containerLoading: false,
                    })
                } else if (this._isMounted && this.props.mobileMode) {
                    this.setState({
                        // cardsContainerHeight: '100%',
                        containerLoading: false,
                    })
                }
            }, 20)
        }
    }


    showDetailModal = (summary, id, date, source, financial, industry, customer, region, details, iType, transactionTime, traffickingType, fileUrl, views, downloads, bookmarked) => {
        this.props.actions.showDetailModal(summary, id, date, source, financial, industry, customer, region, details, iType, transactionTime, traffickingType, fileUrl, views, downloads, bookmarked);
    }

    // getPaginationNumbers = () => {
    //     // Get number of pages for pagination buttons
    //         setTimeout(() => {
    //             console.log(this.cards)
                
    //         }, 600)
    //     }
    
    // prevPage = () => {
    //     if (this.state.currentPage === 1){
    //         return
    //     } else {
    //         let number = this.state.currentPage - 1;
    //         this.setState({
    //             currentPage: number
    //         });
    //         window.scrollTo(0, 0);
    //     }
    // }

    // changePage = (e) => {
    //     // let lastPage; // need if container display is set to flex column

    //     // if (this.cards) {
    //     //     lastPage = Math.ceil(this.cards.length / this.state.cardsPerPage)
    //     // }

    //     this.setState({
    //         currentPage: Number(e.target.id)
    //     });
        
    //     setTimeout(() => {
    //         window.scrollTo(0, 0); 
    // // need if container display is set to flex column
    //     // // If current cards <= 3 change the height of the container
    //     //     let indexOfLastCard = this.state.currentPage * this.state.cardsPerPage;
    //     //     let indexOfFirstCard = indexOfLastCard - this.state.cardsPerPage;
    //     //     let currentCards = this.cards.slice(indexOfFirstCard, indexOfLastCard)

    //     //     if (this.state.currentPage === lastPage && currentCards.length <= 3) {
                
    //     //         let height = this.cardsContainer.clientHeight / 1.7;
    //     //         this.setState({ 
    //     //             cardsContainerHeight: height
    //     //         });

    //     //     } 
    //     }, 10)
    // }

    // nextPage = () => {
    //     if (this.pageNumbers){
    //         if (this.state.currentPage < this.pageNumbers.length){
    //             let number = this.state.currentPage + 1;
    //             this.setState({
    //                 currentPage: number
    //             });
    //             window.scrollTo(0, 0);
    //         } else {
    //             return
    //         }
    //     }
        
    // }


    componentWillMount(){
        window.addEventListener('resize', this.checkForMobileMode.bind(this));
        this.checkForMobileMode();
    }


    componentDidMount(){    
        this._isMounted = true;
        
        API.get("statmvp", "getuserdata").then(response => {
            const indicatorArray  = response.savedIndicators;
  
            if(indicatorArray && indicatorArray.length){
                let saved = indicatorArray;

                if (this._isMounted) {
                     this.setState({
                        savedIndicators: saved,
                    })
                }
            }
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.checkForMobileMode.bind(this));
    }


    render() {
        // this.containerLoading = true;
        this.columns = 0;
        this.originalCards = this.props.cards;
        this.cards = []; 
        this.saved = this.state.savedIndicators;
        // this.pageNumbers = [];
        this.cardsReady = 0; // Will keep track of when all cards have loaded

        if (this.state.mobileMode) {
            this.columns = 1;
        } else if (this.state.tabletMode) {
            this.columns = 2;
        } else {
            this.columns = 3;
        }

        if (!this.props.layoutCards) {
            this.columns = 1;
        }

        if (this.props.cards && this.props.cards.length) {
            // re-order the array so the "cards" read left-right
            const cols = this.columns; // cols === CSS column-count value
            const out = [];
            let col = 0;
            while(col < cols) {
                for(let i = 0; i < this.props.cards.length; i += cols) {
                    let _val = this.props.cards[i + col];
                    if (_val !== undefined) {
                        out.push(_val);
                    }
                }
                col++;
            }
            
            this.cards = out;
        }

        let iCards = [];
    
        if(this.cards && this.cards.length) {  
            //*** Logic for displaying 6 Indicators on page at a time */
            // let indexOfLastCard = this.state.currentPage * this.state.cardsPerPage;
            // let indexOfFirstCard = indexOfLastCard - this.state.cardsPerPage;
            // let currentCards = this.state.cards.slice(indexOfFirstCard, indexOfLastCard);

            //*** Logic for displaying all Indicators
            iCards = this.cards.map((item, index) => { // old: currentCards.map
                this.isThisBookmarked = '';
                
                // Check to see if user has bookmarked any indicators
                if (this.saved && this.saved.length) { // if this user has any saved indicators proceed
                    for(var i = 0; i < this.saved.length; i++) {
                        if (item.indicatorId === this.saved[i].indicatorId) { 
                        // If this current items i.d. matches an i.d. of an indicator in the users saved obj, make true
                            this.isThisBookmarked = true; 
                            break;
                        } else {
                            this.isThisBookmarked = false;
                        }
                        
                    }
                }

                return (
                    <div 
                        className={!this.props.layoutCards ? 'iCardWrappers listView' : 'iCardWrappers'} 
                        key={index}
                    >
                        <IndicatorCard 
                            saved={this.isThisBookmarked}
                            obj={item}
                            mobileMode={this.props.mobileMode}
                            layoutCards={this.props.layoutCards}
                            actions={{
                                addToCounter: this.addToCounter,
                                showDetailModal: this.showDetailModal,
                                selectToDownload: this.props.actions.selectToDownload,
                                removeDownload: this.props.actions.removeDownload,
                            }}
                        />
                    </div>
                )
                
            })
        }

        // if (this.cards && this.cards.length) {
        //     for (let i = 1; i <= Math.ceil(this.cards.length / this.cardsPerPage); i++) {
        //         this.pageNumbers.push(i);
        //     }
        // } else {
        //         this.pageNumbers = [];
        // }

        return (
            <div>
                {this.state.containerLoading && 
                    <span className='color-b'>loading...</span>
                }

                {!this.props.layoutCards &&
                    <div className='flexRow listViewCardTitles'>
                        <div className='item selectAll'>
                            <p className='color-b'>Select all</p>
                        </div>
                        <div className='item summary'>
                            <p className='color-b'>Indicator summary</p>
                        </div>
                        <div className='item date'>
                            <p className='color-b'>Date uploaded</p>
                        </div>
                        <div className='item source'>
                            <p className='color-b'>Source</p>
                        </div>
                        <div className='item type'>
                            <p className='color-b'>Indicator type</p>
                        </div>
                    </div>
                }

                <div 
                    className={this.state.containerLoading ? 'iCardsContainer loading' : 'iCardsContainer'}
                    ref={ (cardsContainer) => this.cardsContainer = cardsContainer} 
                    style={{ columnCount: this.columns }} // maxHeight: this.state.cardsContainerHeight,
                >
                    {iCards.length ? iCards : null}
                </div>

                {/* <PaginationBtns
                    mobileMode={this.props.mobileMode}
                    pageNumbers={this.pageNumbers}
                    currentPage={this.state.currentPage}
                    actions={{
                        prevPage: this.prevPage,
                        nextPage: this.nextPage,
                        changePage: this.changePage,
                        getPaginationNumbers: this.getPaginationNumbers
                    }} 
                /> */}
            </div>
        )
    }
}