import React, { Component } from 'react';
import {
    Button, 
    Glyphicon,
    FormGroup,
    FormControl,
    Overlay, // shows and hides on/off HOVER
    Tooltip,
    Checkbox  } from 'react-bootstrap';
import { API } from 'aws-amplify';

export class RequestAccess extends Component {
    constructor(props){
        super(props)
        this.state = {
            selectedIndustry: 'Select your Industry',
            industryOpen: false,
            industryOptions: ['Financial Institution', 'Law Enforcement', 'Non-Profit'],
            organization: '',
            industry: '',
            email: '',
            name: '',
            showEmailTooltip: false,
            successfulSignup: false,
            loading: false,
            industryOther: ''
        }
        this._isMounted = false;
    }

    openIndustry = (e) => {
        e.preventDefault();
        this.setState({ industryOpen: true });
    }

    closeIndustry = (e) => {
        this.setState({ industryOpen: false });
    }

    clickOutsideIndustry = (e) => { // Close industry div on off click
        if (this._isMounted && !this.state.successfulSignup) {
            if (!this.iOElement.contains(e.target)) {
                this.setState({ industryOpen: false });
            }
        }
    }

    doneBtnClosesIndustry = (e) => { // When mobile keyboard for other text done button is clicked, close industry
        if (e.target.id === 'industry') {
            this.setState({ industryOpen: false });
        }
    }

    getIndustry = (name) => { // get which industry was selected and save
        this.setState({ 
            selectedIndustry: name,
            industry: name,
            industryOther: '',
            industryOpen: false
        });
    }

    // focusText = () => {
    //     this.setState({ selectedIndustry: 'Select your Industry' })
    // }

    getText = (e) => { // Get industry other text
        this.setState({ 
            selectedIndustry: e.target.value,
            industry: e.target.value,
            industryOther: e.target.value
        });
        //console.log(e.target.value)
    }

    keyPress = (e) => { 
        // Close industry div when enter key is pressed
        if(e.keyCode === 13){
            e.preventDefault();
            this.setState({ 
                industryOpen: false
            });
        }
     }


    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
            showEmailTooltip: false
        }) 
    }

    showToolTip = (e) => {
        e.preventDefault();
        if (this._isMounted) {
            this.setState({ 
                showEmailTooltip: true
            })

            setTimeout(() => {
                if (this._isMounted) {
                    this.setState({ 
                        showEmailTooltip: false
                    })
                }
            }, 3000 )
        }
    }

    closeTooltip = (e) => {
        e.preventDefault();
        if (this._isMounted) {
            this.setState({ 
                showEmailTooltip: false
            })
        }
    }
    
    handleSubmit = (e) => {
        e.preventDefault();
        
        this.setState({
            loading: true
        })

        if (this.state.email.length > 5 && this.state.name.length > 5 && this.state.selectedIndustry !== 'Select your Industry' && this.state.organization) {

            API.post("statmvp", "approveuser", {
                body: {
                    organization: this.state.organization,
                    industry: this.state.industry,
                    email: this.state.email,
                    name: this.state.name
                }
                }).then(response => {
                    this.setState({
                        successfulSignup: true
                    })
                    this.props.successfulSignup();
                }).catch(e => { // fields not full error??
                    console.log(e)
                    this.setState({
                        loading: false
                    })
                })
        } else {
            alert('Please fill all the fields');
            this.setState({
                loading: false
            })
        }
    }


    componentDidMount() {
        this._isMounted = true;
        document.addEventListener('mousedown', this.clickOutsideIndustry);
        document.addEventListener('focusout', this.doneBtnClosesIndustry);
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.removeEventListener('mousedown', this.clickOutsideIndustry);
        document.removeEventListener('focusout', this.doneBtnClosesIndustry);
    }

    render() {
        this.requestDisabled = true;

        if (this.state.email.length > 5 && this.state.name.length > 5 && this.state.selectedIndustry !== 'Select your Industry' && this.state.organization) {
            this.requestDisabled = false;
        } else {
            this.requestDisabled = true;
        }

        let iOptions = '';
        if (this.state.industryOptions){
            iOptions = this.state.industryOptions.map((item,index) => {
                return(                                 
                    <Checkbox 
                        name='industryRadio' 
                        className={this.state.industry === item ? 'options active' : 'options'}
                        key={index} 
                        value={item}
                        checked={this.state.industry === item ? 'checked' : ''}
                        readOnly
                        onClick={() => this.getIndustry(item)}
                    >
                        {item}
                    </Checkbox>
                )
            });
        }

        return (
            <div className='requestAccess'>
            {this.state.successfulSignup === false ?
                <form className='form' onSubmit={this.handleSubmit} autoComplete='off'>
                    <input autoComplete="false" style={{display: 'none'}} />

                    <p className='requestTitle'>Be part of the Community</p>
                    <div className='industryWrapper'>
                        <Button 
                            className={'actionInput white industry'} 
                            onClick={this.openIndustry}
                            style={{paddingLeft: "10px"}}
                        >
                            {this.state.selectedIndustry}
                                <div className='industryArrow open'>
                                    <Glyphicon glyph='menu-down' />
                                </div>
                        </Button>

                        <div className={this.state.industryOpen ? 'industryOpenDiv' : 'industryOpenDiv closed'} ref={(iOElement) => this.iOElement = iOElement}>
                            <p className='color-b bold title' onClick={() => this.closeIndustry()}>Select your Industry</p>
                            <FormGroup controlId='industry' >
                                {iOptions.length ? iOptions : null}

                                <FormControl
                                    data-lpignore="true"
                                    className='textInput'
                                    type='text'
                                    value={this.state.industryOther}
                                    placeholder='Other (type here)'
                                    maxLength={50}
                                    onChange={(e) => this.getText(e)}
                                    // onClick={this.focusText}
                                    onKeyDown={(e) => this.keyPress(e)}
                                />
                            </FormGroup>
                        </div>
                    </div>

                    <FormGroup controlId='name' bsSize='large' >
                        <FormControl
                            data-lpignore="true"
                            // autocomplete="off"
                            className={'actionInput white organization'}
                            type='text'
                            value={this.state.name}
                            placeholder={'Type your name here'}
                            onChange={this.handleChange}
                        />
                    </FormGroup>

                    <FormGroup controlId='organization' bsSize='large' >
                        <FormControl
                            data-lpignore="true"
                            // autocomplete="off"
                            className={'actionInput white organization'}
                            type='text'
                            value={this.state.organization}
                            placeholder={'Type your organization here'}
                            onChange={this.handleChange}
                        />
                    </FormGroup>

                    <FormGroup controlId='email' bsSize='large'>
                        <FormControl
                            autoComplete='new-password'
                            data-lpignore="true"
                            className={'actionInput white email'}
                            type='email'
                            value={this.state.email}
                            placeholder={'Type your email here'}
                            onChange={this.handleChange}
                            onClick={(e) => this.showToolTip(e)}
                            ref={ (emailInput) => this.emailInput = emailInput }
                        />
                    </FormGroup>

                    <Overlay
                        target={this.emailInput} 
                        show={this.state.showEmailTooltip} 
                        onHide={(e) => this.closeTooltip(e)}
                        placement={'top'}
                        // rootClose={true}
                    >
                        <Tooltip 
                            id={'emailHoverTool'} 
                            className='toolTips forRequestAccess'
                        >
                            <p className='color-b light noMargin'>No personal email addresses will be accepted</p>
                        </Tooltip> 
                    </Overlay>

                    {this.state.loading === false ?
                        <Button type='submit' className={this.requestDisabled ? 'requestAccessBtn disable uppercase' : 'requestAccessBtn uppercase'} disabled={false} onClick={this.disabledRequest}>Request an account</Button>               
                    : 
                        <Button className="requestAccessBtn">Loading...</Button>
                    }
                </form>
            : 
            <p style={{width: '90%'}}>Thank you for signing up! We will notify you when you have been approved</p>
            }
            </div>
        )
    }
}