import React, {Component} from 'react';
import { Nav, NavItem, Tab, Row, Col } from 'react-bootstrap';
import Scrollspy from 'react-scrollspy';

export class HomeSidebar extends Component{
    constructor(props){
        super(props);
        this.state = {
            navcolorWhite: true
        }
        this._isMounted = false;
    }


    listenScrollEvent = e => {
        if (this._isMounted) {
            if (this.joy.length) {
                this.setState({
                    navcolorWhite: false
                })
            } else {
                this.setState({
                    navcolorWhite: true
                })
            }
        }
        
    }

    componentDidMount() {
        this._isMounted = true;
        this._isMounted && window.addEventListener('scroll', this.listenScrollEvent.bind(this));
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('scroll', this.listenScrollEvent.bind(this));
    }

    render() {
        this.joy = document.getElementsByClassName('resources active')

        return (
            <div className='homeSidebar'>
                <div className='inner'>
                    <Tab.Container id='left-tabs-example'> 
                        <Row className='clearfix'>
                            <Col sm={3}>
                                <Nav bsStyle='pills' stacked>
                                    <Scrollspy 
                                        items={['top', 'about', 'dna', 'resources']}
                                        currentClassName='active'
                                        offset={this.props.mobileMode ? -50 : -200}
                                    >
                                        <NavItem 
                                            eventKey='top' 
                                            className={this.state.navcolorWhite ? 'anchors white top logo' : 'anchors blue top logo'}
                                            href='#' 
                                        >
                                            <div className={this.state.navcolorWhite ? 'logoWrapper white' : 'logoWrapper blue'}>
                                                <p className={this.state.navcolorWhite ? 'color-w uppercase noMargin' : 'color-b uppercase noMargin'}>stat</p>
                                            </div>
                                        </NavItem>

                                        <NavItem 
                                            eventKey='about' 
                                            className={this.state.navcolorWhite ? 'anchors tubes white about' : 'anchors tubes blue about'}
                                            href='#about' 
                                        >
                                            <div className={this.state.navcolorWhite ? 'colors white' : 'colors blue'}></div>
                                            <p className='titles'>About</p>
                                        </NavItem>

                                        <NavItem 
                                            eventKey='dna' 
                                            className={this.state.navcolorWhite ? 'anchors tubes white dna' : 'anchors tubes blue dna'} 
                                            href='#dna'
                                        >
                                            <div className={this.state.navcolorWhite ? 'colors white' : 'colors blue'}></div>
                                            <p className='titles'>DNA of an Indicator</p>
                                        </NavItem>

                                        <NavItem 
                                            eventKey='resources' 
                                            className={this.state.navcolorWhite ? 'anchors tubes white resources' : 'anchors tubes blue resources'}
                                            href='#resources'
                                        >
                                            <div className={this.state.navcolorWhite ? 'colors white' : 'colors blue'}></div>
                                            <p className='titles'>Resources</p>
                                        </NavItem>

                                    </Scrollspy>
                                </Nav>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        );
    }
}