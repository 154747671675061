import React, { Component } from 'react';
import { Image } from 'react-bootstrap';

export class SelectionPills extends Component {
    

    render() {
        this.name = ''; // Act as name and id
        this.totalNumber = '';

        if (this.props.pillName){
            this.name = this.props.pillName
        }

        if (this.props.totalNumber) {
            this.totalNumber = this.props.totalNumber;
        }

        return (
            <div className='selectionPills'>
                {this.name && this.name.length &&
                    <div className='sPillsInner'>
                        <p className='color-w noMargin name'>{this.name} ({this.totalNumber})</p>
                        <Image 
                            className='removeCategory'
                            width='auto'
                            height='auto'
                            src={require('../../Styles/Images/whiteX.png')}
                            onClick={() => this.props.actions.removeCat(this.name)}
                        />
                    </div>
                }
            </div>
        )
    }
}