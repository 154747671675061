import React, { Component } from 'react';
import { Auth, API } from "aws-amplify";
import './App.css';
import Routes from "./Pages/Router";

class App extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          isAuthenticated: false,
          isAuthenticating: true,
        };
      }
    
      async componentDidMount() {

        try {
          if (await Auth.currentSession()) {
            this.userHasAuthenticated(true);
          }
        }
        catch(e) {
          if (e !== 'No current user') {
            console.log(e);
          }
        }
    
        this.setState({ isAuthenticating: false });
      }
    
      userHasAuthenticated = authenticated => {
        this.setState({ isAuthenticated: authenticated });
      }

      handleLogout = async event => {
        await Auth.signOut();
    
        this.userHasAuthenticated(false);

        API.post("statmvp", "userlogout").then(response => {
          console.log('success')
        }).catch(error => {
          console.log(error)
        })
    
        this.props.history.push("/login");
      }
    
    render() {

      const childProps = {
          isAuthenticated: this.state.isAuthenticated,
          userHasAuthenticated: this.userHasAuthenticated,
        };

    return (
      <div>
        {/* <Router /> */}
        <Routes 
          childProps={childProps}
        />
      </div>
    );
  }
}

export default App;
