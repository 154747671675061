import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';

export class LeftSideBar extends Component {
    constructor(props){
        super(props)
        this.state = {
            mobileMode: false,
            logoPath: '#',
            barLink: '#'
        }
    }

    dashboardToDash = (e) => {
        if (this.props.page === 'seeMySelections') {
            e.preventDefault();
            this.props.openPanel();
        } else {
            window.location.reload();
        }
    }

    checkForMobileMode = () => {
        this.setState({ mobileMode: window.innerWidth <= 414 });
    }

    componentWillMount(){
        window.addEventListener('resize', this.checkForMobileMode.bind(this));
        this.checkForMobileMode();
    }

    componentDidMount(){
        Auth.currentAuthenticatedUser()
        .then(response => {
            this.setState({
                user: true
            });

            if(this.props.page){
                if(this.props.page === 'homePage'){
                    this.setState({
                        logoPath: '/',
                        barLink: '/dashboard'
                    })
                } else if(this.props.page === 'signup'){
                    this.setState({
                        logoPath: '/',
                        barLink: '/'
                    })
                } else if (this.props.page === 'seeMySelections'){
                    this.setState({
                        logoPath: '/',
                        barLink: '/'
                    })
                } else {
                    this.setState({
                        logoPath: '/',
                        barLink: '/dashboard'
                    })
                }
            }
        })
        .catch(error => {
            this.setState({
                user: false,
                logoPath: '/',
                barLink: '/'
            });
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkForMobileMode.bind(this));
    }

    render(){

        return(
            <div className='leftSideBar'>
                <div className='logoWrapper'>
                    <Link className='color-b uppercase' to={this.state.logoPath}>stat</Link>
                </div>
    
                <Link className='barsLink' to={this.state.barLink} onClick={(e) => this.dashboardToDash(e)}>
                    <div>
                        <ul className='ul'>
                            {this.props.page === 'dashboard' || this.props.page === 'dashboard-sub' || this.props.page === 'seeMySelections' ?
                                <div>
                                    <li className='wrappers'>
                                        <div className='colors grey'></div>
                                    </li>
                                    <li className='wrappers'>
                                        <div className='colors grey'></div>
                                    </li>
                                    <li className='wrappers'>
                                        <div className='colors grey'></div>
                                    </li>
                                    <li className='wrappers'>
                                        <div className='colors grey'></div>
                                    </li>
                                    <li className='wrappers'>
                                        <div className='colors grey'></div>
                                    </li>
                                    <li className='wrappers'>
                                        <div className='colors grey'></div>
                                    </li>
                                </div>
                            :
                                <div>
                                    <li className='wrappers'>
                                        <div className='colors grey'></div>
                                    </li>
                                    <li className='wrappers'>
                                        <div className='colors grey'></div>
                                    </li>
                                    <li className='wrappers'>
                                        <div className='colors grey'></div>
                                    </li>
                                </div>
                            }
                        </ul>
                    </div>
                </Link>
            </div>
        )
    }
}
