import React, {Component} from 'react';
import { Storage } from 'aws-amplify';
import { Link } from 'react-router-dom';
export class IndicatorPreview extends Component {

    constructor() {
        super();
        this.state = {
            attachmentUrl: null
        }
    }
    componentDidMount(){
        Storage.get(this.props.match.params.indicatorid).then(response => {
            this.setState({
                attachmentUrl: response
            })
        }).catch(error => {
            console.log(error)
        });
    }

    render(){

        return (
            <div>
                {this.state.attachmentUrl !== null ?
                <div style={{display: "flex", height: "100vh", justifyContent: "center", alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "30px", border: "2px solid black"}}>
                        <p> To see user uploaded document: </p>
                        <p>{this.props.match.params.indicatorid}</p>
                        <a href={this.state.attachmentUrl}><button>Click here</button></a>
                    </div>
                </div>
                    : 
                    <div style={{display: "flex", height: "100vh", justifyContent: "center", alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "30px", border: "2px solid black"}}>
                            <p>Please login to see documents.</p>
                            <p><Link to="/"><button>Click here</button></Link></p>
                        </div>
                    </div>
                }
            </div>
        )
    }

}