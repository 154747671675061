import React, { Component } from 'react';
// import * as contentful from 'contentful';
import { Image } from 'react-bootstrap';

export class ResourcesCard extends Component {
    constructor(props){
        super(props);
        this.state = {
            desktopMode: false,
        }
    }

    render(){

        this.obj = this.props.obj;
        this.imgUrl = '';
        this.title = false;
        this.siteUrl = '';

        if (this.obj.hasOwnProperty('imgUrl')) {
            this.imgUrl = this.obj.imgUrl;
        }

        if (this.obj.hasOwnProperty('title')) {
            this.title = this.obj.title;
        }

        if (this.obj.hasOwnProperty('siteUrl')) {
            this.siteUrl = this.obj.siteUrl;
        } else {
            this.siteUrl = '#';
        }

        // if (obj.hasOwnProperty('intro')) {
        //     this.setState({ intro: obj.intro });
        // }

        // if (obj.hasOwnProperty('siteName')) {
        //     this.setState({ siteName: obj.siteName });
        // }

        // if (this.props.placement === 'homePage' ) {
        //     this.setState({ siteUrl: '/resources' });
        // } else if (this.props.placement === 'resources' && obj.hasOwnProperty('siteUrl')) {
        //     this.setState({ siteUrl: obj.siteUrl });
        // }    

        return(
            <div className='resourcesCard'>
                <a className='link' href={this.siteUrl} target='_blank'  rel='noopener noreferrer'>
                    <div className='inner'>
                        <div className={this.imgUrl ? 'imgDiv hasImg' : 'imgDiv noImg'}>
                            { this.imgUrl !== false ?
                            <Image 
                                className={'image'}
                                width={'100%'}
                                height={'auto'}
                                src={`${this.imgUrl}`}
                                
                            />
                            : null }
                        </div>
                        <div className={this.imgUrl ? 'text hasImg' : 'text noImgBlue'}>
                            <p className='color-b leftAlign noMargin title'>{this.title}</p>
                            {/* <p className='color-b leftAlign'>{this.state.intro}</p> */}
                            {/* <p className='color-pk leftAlign siteName'>{this.state.siteName}</p> */}
                        </div>
                    </div>
                </a>
            </div>
        )
    }
}