import React, { Component } from 'react';
import { LeftSideBar } from '../../Components/LeftSideBar';
import { RightSideBar } from '../../Components/RightSideBar';
import { IndicatorForm } from './IndicatorForm';
import { IndicatorSubmitted } from './IndicatorSubmitted';
import { Footer } from '../../Components/Footer';
import { s3Upload } from '../../Components/Storage';
import { API } from 'aws-amplify';

export class SubmitAnIndicator extends Component {
    constructor(props){
        super(props);
        this.state = {
            mobileMode: false,
            continueAccess: false,
            fileArray: []
        }
    }

    checkForMobileMode = () => {
        this.setState({ mobileMode: window.innerWidth <= 414 });
    }

    continueAccess = (obj) => { 

        if (obj.newFiles !== false) {
            obj.newFiles.map((item, key) => {
                var fileArrayUpdate = this.state.fileArray;
                fileArrayUpdate.push(item.name);
                this.setState({
                    fileArray: fileArrayUpdate
                })
                s3Upload(item).then(response => {
                    console.log(response)
                }).catch(error => {
                    console.log(error)
                })
                return console.log();
            })
            API.post("statmvp", "addindicator", {
                body: {
                    userObj: obj,
                    fileName: this.state.fileArray
                }
            }).catch(error => {
                console.log(error)
            })
            this.setState({
                continueAccess: true
            })
            window.scrollTo(0, 0);
        } else {
            API.post("statmvp", "addindicator", {
                body: {
                    userObj: obj,
                    fileName: false
                }
            }).then(response => {
                this.setState({
                    continueAccess: true
                })
                window.scrollTo(0, 0);
            }).catch(error => {
                console.log(error)
            })
        }
    }

    // backToForm = () => {
    //     this.setState({
    //         continueAccess: false
    //     })
        
    // }

    resetPageComponent = () => { // Show a new form
        setTimeout(() => {
            this.setState({ 
                continueAccess: false
            })
        }, 1000);
        
    }

    componentWillMount(){
        window.addEventListener('resize', this.checkForMobileMode.bind(this));
        this.checkForMobileMode();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkForMobileMode.bind(this));
    }

    render(){
        return(
            <div className='submitAnIndicator'>
                <LeftSideBar 
                    page={'submitIndicator'} 
                />

                <RightSideBar 
                    page={'submitIndicator'} userHasAuthenticated = {this.props.userHasAuthenticated} 
                />

                <div className='innerPage inner'>
                    {this.state.continueAccess ?
                            <IndicatorSubmitted 
                                resetPageComponent={this.resetPageComponent}
                            />
                    :
                        <div className='formSection'>
                            <p className='color-b bold pageTitle'>Submit an Indicator</p>
                            <p className='requiredText'>Required Information</p>
                            <IndicatorForm 
                                continueAccess={this.continueAccess}
                                mobileMode={this.state.mobileMode}
                            />
                        </div>
                    }
                </div>
                <Footer />
            </div>
        )
    }
}
