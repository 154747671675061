import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Auth, API } from 'aws-amplify';
import { 
    // Image,
    Button,
    Overlay, // Shows and stays open on click
    OverlayTrigger, // shows and hides on/off hover
    Tooltip } from 'react-bootstrap';

export class RightSideBar extends Component {
    constructor(props){
        super(props);
        this.state = {
            mobileMode: false,
            profileTool: false,
            initials: 'cm',
            fullName: 'Carol Mendel',
            email: null,

            // notificationsTool: false,

            settingsTool: false,
        }
        this._isMounted = false;
    }

    showToolTip = (e, toolTip) => {
        e.preventDefault();
        this.setState({ 
            [toolTip]: !this.state[toolTip] 
        })
    }

    closeOverlay = (e, toolTip) => {
        e.preventDefault();
        this.setState({ 
            [toolTip]: !this.state[toolTip] 
        })
    }

    checkForMobileMode = () => {
        this.setState({ mobileMode: window.innerWidth <= 414 });
    }

    handleLogout = async event => {
        await API.post("statmvp", "userlogout").then(response => {
            console.log('success')
          }).catch(error => {
            console.log(error)
          })
        
        Auth.signOut();

        this.props.userHasAuthenticated(false);
    
        //this.props.location.history.push("/login");
      }

    componentWillMount(){
        window.addEventListener('resize', this.checkForMobileMode.bind(this));
        this.checkForMobileMode();
    }

    componentDidMount () {
        this._isMounted = true;

        Auth.currentAuthenticatedUser()
        .then(response => {
            // console.log(response)
            this.setState({
                user: true
            });
        })
        .catch(error => {
            this.setState({
                user: false
            });
        });

        Auth.currentAuthenticatedUser().then(user => {
            // console.log(user)
            this.setState({
                email: user.attributes.email,
            })
        })

        Auth.currentAuthenticatedUser().then(user => {
            API.get("statmvp", "getuserdata").then(response => {
                if (response.lastLogout) {
                    let date = response.lastLogout;

                    let iArray = new Date(response.lastLogout).toDateString().split(' ');

                    let month = iArray[1];
                    let day = iArray[2];
                    let year = iArray[3].split(',')[0];
                    let iDate = month + ' ' + day + ', ' + year;

                    let dateString = iDate;

                    this.setState({
                        lastLogout: date,
                        lastLogoutString: dateString
                    })
                } 

                if(response.savedIndicators){
                    if (this._isMounted) {
                        this.setState({
                            bookmarkNumber: response.savedIndicators.length,
                        })
                    }
                }
            }).catch(error => {
                console.log(error)
            });
        }).catch(error => {
            console.log(error)
        });


        API.get("statmvp", "getindicatordata").then(response => {
            let indicatorArray = [];
            for (let i = 0; i < response.length; i++){
                if (response[i].approved){
                    indicatorArray.push(response[i])
                }
            }

            this.setState({
                indicators: indicatorArray
            })
        
        }).catch(e => {
            console.log(e)
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.checkForMobileMode.bind(this));
    }

    render(){
        this.newCards = []; // For notifications toolTip/page
        this.overlayTrigger = ['hover', 'focus'];

        if (this.state.mobileMode) {
            this.overlayTrigger = null;
        }

        if (this.state.lastLogout && this.state.indicators) { 
        // Get all days between user's lastLogout date and today's date
            let start = new Date(this.state.lastLogout);
            // let start = new Date('04/15/2019'); // For testing!!
            let currentDate = new Date();
    
            let datesBetween = [];

            for (let sd = start; sd <= currentDate; sd.setDate(sd.getDate() + 1)){ 

                let getDate = new Date(sd).toDateString().split(' ');
               
                let month = getDate[1];
                let day = Number(getDate[2]);
                let year = Number(getDate[3]);
                let sDate = month + ' ' + day + ', ' + year;
                let compareDate = sDate;

                datesBetween.push(compareDate);
            } 
            this.state.indicators.map((item) => {
            // Make indicator date submiited into same form as compare dates above
                let iString = item.dateIndicatorSubmitted;
                let iArray = iString.split('/');

                let months = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
                let month = Number(iArray[1]) - 1;
                const textMonth = months[month];

                let day = iArray[0];
                let year = iArray[2];
                let iDate = textMonth + ' ' + day + ', ' + year;

                let indicatorDate = iDate;
                for (let i = 0; i < datesBetween.length; i++) {
                    if (indicatorDate === datesBetween[i]) {
                        // If dates are exact push indicator to array
                        return this.newCards.push(item)
                    }
                }
                return console.log();
            })
        };


        return(
            <div>
            {this.state.user === true ?
                <div className='rightSideBar'>
    {/* Profile Link */}
                    <div className='buttonHolders'>
                        <Button 
                            className='transparentBtns profileBtn'
                            ref={(profileBtn) => this.profileBtn = profileBtn} 
                            onClick={(e) => this.showToolTip(e, 'profileTool')}
                        />
                            {/* <p className='color-w noMargin uppercase initials'>{this.state.initials}</p> */}
                        {/* </Button> */}
                        
                        <Overlay 
                            target={this.profileBtn} 
                            show={this.state.profileTool} 
                            onHide={(e) => this.closeOverlay(e, 'profileTool')}
                            placement={this.state.mobileMode ? 'top' : 'left'}
                            rootClose={true}
                        >
                            <Tooltip 
                                id={'profileTooltip'} 
                                className='toolTips forRightSideBar' 
                            >
                                {/* <p className='color-b toolTitle'>{this.state.fullName}</p> */}
                                <p className='color-b light' style={{fontSize: '0.8em', wordBreak: 'break-word'}}>{this.state.email}</p>
                                <Link to='/profile' className='color-pk light pinkToolLinks'>
                                    <span>View profile</span>
                                </Link>
                                <br></br>
                                <p className='color-pk light pinkToolLinks bottom' onClick={this.handleLogout}>
                                    <span>Sign out</span>
                                </p>
                            </Tooltip>
                        </Overlay>
                    </div>
    {/* Notifications Link */}
                    <div className='buttonHolders'>
                        <Button 
                            className='transparentBtns notificationsBtn'
                            ref={(notificationsBtn) => this.notificationsBtn = notificationsBtn} 
                            onClick={(e) => this.showToolTip(e, 'notificationsTool')}
                        >
                            <div className='leftIcons notificationsIcon'>
                            </div>

                            {this.newCards.length > 0 ?
                                <div className='redCircles border'>
                                    <div className='red innerCircle'>
                                        <p className='color-w noMargin number'>{this.newCards.length}</p>
                                    </div>
                                </div>
                            :
                                <div className='redCircles border bm notifications'>
                                    <div className='grey innerCircle bm notifications'>
                                    </div>
                                </div>
                            }
                        </Button>
                        
                        <Overlay 
                            target={this.notificationsBtn} 
                            show={this.state.notificationsTool} 
                            onHide={(e) => this.closeOverlay(e, 'notificationsTool')}
                            placement={this.state.mobileMode ? 'top' : 'left'}
                            rootClose={true}
                        >
                            <Tooltip 
                                id={'notificationsTooltip'} 
                                className={this.newCards.length > 0 ? 'toolTips forRightSideBar' : 'toolTips forRightSideBar zeroNew'}
                            >
                                <p className='color-b toolTitle'>Notifications</p>
                                {this.newCards.length > 0 ?
                                    <p className='color-b light' style={{fontSize: '0.8em'}}>
                                        {this.newCards.length} new indicator{this.newCards.length >= 2 && <span>s</span> }
                                        <span> uploaded since {this.state.lastLogoutString}</span>
                                    </p>
                                :
                                    <p className='color-b light' style={{fontSize: '0.8em'}}>
                                        No new indicators uploaded since {this.state.lastLogoutString}
                                    </p>
                                }

                                {this.newCards.length > 0 &&
                                <Link 
                                    to={{
                                        pathname: '/notifications',
                                        state: {
                                            newCards: this.newCards
                                        }
                                    }}
                                 className='color-pk light pinkToolLinks'>
                                    <span>Show</span>
                                </Link>
                                }
                            </Tooltip>
                        </Overlay>
                    </div>

    {/* Add New Indicator */}
                    <div className='buttonHolders'>
                        <OverlayTrigger 
                            trigger={this.overlayTrigger}
                            placement={this.state.mobileMode ? 'top' : 'left'}
                            overlay={
                                <Tooltip 
                                    id={'addIndicatorTooltip'} 
                                    className='toolTips forRightSideBar'
                                >
                                    <p className='color-b light noMargin'>Add new indicator</p>
                                </Tooltip>
                            }
                        >
                            <Link to='/submitanindicator' className='transparentBtns addIndicatorBtn'>
                            </Link>
                        </OverlayTrigger>
                    </div>
    {/* Saved/Bookmarked Indicators  */}
                    <div className='buttonHolders bottom'>
                        <OverlayTrigger 
                            trigger={this.overlayTrigger}
                            placement={this.state.mobileMode ? 'top' : 'left'}
                            overlay={
                                <Tooltip 
                                    id={'bookmarksTooltip'} 
                                    className='toolTips forRightSideBar'
                                >
                                    {this.state.bookmarkNumber > 0 ?
                                        <p className='color-b light noMargin' style={{fontSize: '0.8em'}}>You have {this.state.bookmarkNumber} saved indicator{this.state.bookmarkNumber >= 2 && <span>s</span>}</p>
                                    :
                                        <p className='color-b light noMargin'>Saved indicators</p>
                                    }
                                </Tooltip>
                            }
                        >
                            <Link to='/bookmarks' className='transparentBtns bookmarksBtn'>
                                <div className='leftIcons bookmarksIcon'>
                                </div>
                                {this.state.bookmarkNumber > 0 ?
                                    <div className='redCircles border bm'>
                                        <div className='red innerCircle bm'>
                                            
                                        </div>
                                    </div>
                                :
                                    <div className='redCircles border bm'>
                                        <div className='grey innerCircle bm'>
                                        </div>
                                    </div>
                                }
                            </Link>
                        </OverlayTrigger>
                    </div>
    {/* Settings */}
                    {/* <div className='buttonHolders bottom'>
                        <Button 
                            className='transparentBtns settingsBtn'
                            ref={(settingsBtn) => this.settingsBtn = settingsBtn} 
                            onClick={(e) => this.showToolTip(e, 'settingsTool')}
                        />
                        <Overlay 
                        
                            target={this.settingsBtn} 
                            show={this.state.settingsTool} 
                            onHide={(e) => this.closeOverlay(e, 'settingsTool')}
                            placement={this.state.mobileMode ? 'top' : 'left'}
                            rootClose={true}
                        >
                            <Tooltip 
                                id={'settingsTooltip'} 
                                className='toolTips forRightSideBar' 
                            >
                                <p className='color-b toolTitle'>Background settings</p>
                                <Button 
                                    className='color-pk light pinkToolLinks'
                                >
                                    Light
                                </Button>
                                <br></br>
                                <Button 
                                    className='color-pk light pinkToolLinks bottom'
                                >
                                    Dark
                                </Button>
                            </Tooltip>
                        </Overlay>
                    </div> */}
                </div>
            :
                null
            }
            </div>
        )
    }
}