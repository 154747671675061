import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AuthenticatedRoute from "../Components/AuthenticatedRoute";
import UnauthenticatedRoute from "../Components/UnAuthenticatedRoute";
import AppliedRoute from "../Components/AppliedRoute";

import Homepage from './Homepage/Homepage';
import ResetPassword from '../Components/ResetPassword';
import { SignInAndAgree } from './Homepage/SignInAndAgree';
import { ResourcesPage } from './ResourcesPage/ResourcesPage';
import { SupportingOrgs } from './SupportingOrgs/SupportingOrgs';
import { SubmitAnIndicator } from './IndicatorForm/SubmitAnIndicator';
import { IndicatorSubmitted } from './IndicatorForm/IndicatorSubmitted';
import { AboutUs } from './AboutUs/AboutUs';
import { FaqPage } from './FaqPage/FaqPage';
import Dashboard from './Dashboard/Dashboard';
import { SeeMySelections } from './Dashboard/SeeMySelections';
import { SeeAllIndicators } from './Dashboard/SeeAllIndicators';
import { Profile } from './Profile/Profile';
import { NotificationsPage } from './Dashboard/NotificationsPage';
import { Bookmarks } from './Bookmarks/Bookmarks';
import { IndicatorPreview } from './Indicator/Indicator';
import { SubmittedFeedback } from './Indicator/SubmittedFeedback';

import NotFound from '../Components/NotFound';


export default ({ childProps }) =>
  <Switch>

    <AppliedRoute path="/supportingorgs" exact component={SupportingOrgs} props={childProps} />
    <AppliedRoute path="/resources" exact component={ResourcesPage} props={childProps} />
    <AppliedRoute path='/aboutus' component={AboutUs} props={childProps}/>
    <AppliedRoute path='/faqs' component={FaqPage} props={childProps}/>

    <UnauthenticatedRoute path="/" exact component={Homepage} props={childProps} />
    <UnauthenticatedRoute path='/signup' component={SignInAndAgree} props={childProps}/>
    <UnauthenticatedRoute path="/login/reset" exact component={ResetPassword} props={childProps}/> 
    

    <AuthenticatedRoute path="/dashboard" exact component={Dashboard} props={childProps} />
    <AuthenticatedRoute path="/submitanindicator" exact component={SubmitAnIndicator} props={childProps} />
    <AuthenticatedRoute path='/seemyselections' component={SeeMySelections} props={childProps}/>
    <AuthenticatedRoute path='/allindicators' component={SeeAllIndicators} props={childProps}/>
    <AuthenticatedRoute path='/notifications' component={NotificationsPage} props={childProps}/>
    <AuthenticatedRoute path='/indicatorsubmitted' component={IndicatorSubmitted} props={childProps}/>
    <AuthenticatedRoute path='/profile' component={Profile} props={childProps}/>
    <AuthenticatedRoute path='/bookmarks' component={Bookmarks} props={childProps}/>
    <AppliedRoute path='/indicator/:indicatorid' component={IndicatorPreview} props={childProps}/>
    <AppliedRoute path='/feedback/:commentid' component={SubmittedFeedback} props={childProps}/>
    <AuthenticatedRoute path="/statsupportingorgs" exact component={SupportingOrgs} props={childProps} />
    <AuthenticatedRoute path="/statresources" exact component={ResourcesPage} props={childProps} />
    <AuthenticatedRoute path='/stataboutus' component={AboutUs} props={childProps}/>
    <AuthenticatedRoute path='/statfaqs' component={FaqPage} props={childProps}/>

    <Route component={NotFound} />

  </Switch>;