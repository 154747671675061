import React, { Component } from 'react';
import { LeftSideBar } from '../../Components/LeftSideBar';
import { RightSideBar } from '../../Components/RightSideBar';
import { Footer } from '../../Components/Footer';

const breaks = <span><br></br><br></br></span>;

export class FaqPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            mobileMode: false,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }


    render() {
        return (
            <div className='faqPage'>
                <LeftSideBar page={'faq'} />
                <RightSideBar page={'faq'} userHasAuthenticated = {this.props.userHasAuthenticated} />

                <div className='innerPage faqInner'>
                    <p className='title'>FAQ<span style={{textTranform: 'lowercase'}}>s</span></p>
                 
                    {/* {breaks} */}
                    <p className='color-b bodyText'><strong>1. What is the STAT project?</strong></p>
                    <p className='color-b bodyText'>The STAT (Stand Together Against Trafficking) project is a platform developed by Enigma Technologies in collaboration with ACAMS and Polaris that enables the financial crimes community to disrupt financial activity related to human trafficking.  The platform provides a central place for financial institutions to upload and share financial indicators of human trafficking with one another, law enforcement and NGOs. In addition to creating and maintaining a centralized, searchable repository of financial indicators, the STAT platform also provides curated resources and guides from expert NGOs with critical information that can be leveraged to combat human trafficking.  By connecting the financial crimes community and facilitating information-sharing, financial institutions are better able to create effective typologies to detect human trafficking and law enforcement agencies are better able to investigate and deter the same.</p>
                    {breaks}
                    <p className='color-b bodyText'><strong>2. Who owns and operates the STAT platform?</strong></p>
                    <p className='color-b bodyText'>Enigma Technologies owns and oversees the technical operation of the STAT platform.  To deliver the minimum viable product (MVP) of the platform, Enigma contracted with AnnieCannons, a non-profit organization dedicated to transforming survivors of human trafficking into software professionals.  The requirements and information on the STAT platform are defined and validated by an advisory team consisting of representatives from Polaris, ACAMS and Enigma, as well as a user advisory team comprised of financial institutions, law enforcement organizations and NGOs.</p>
                    {breaks}
                    <p className='color-b bodyText'><strong>3. Is the STAT platform open to the public?</strong></p>

                    <p className='color-b bodyText'>The resources section of the STAT platform is open to the public. The collected indicators are accessible only after sign-in.</p>
                    {breaks}
                    <p className='color-b bodyText'><strong>4. Is the STAT platform free to use?</strong></p>

                    <p className='color-b bodyText'>Yes. The STAT platform was developed as a strictly non-commercial product.</p>
                    {breaks}
                    <p className='color-b bodyText'><strong>5. How can I become a member of the STAT community?</strong></p>

                    <p className='color-b bodyText'>Click on REQUEST ACCESS on the STAT platform homepage and fill out the required information. The STAT team will determine whether your organization has executed an End User License Agreement (EULA) and identified a STAT administrator. If so, your STAT administrator will confirm your access. If not, a member of the STAT team will contact you for further information about your organization.</p>
                    {breaks}
                    <p className='color-b bodyText'><strong>6. How is an indicator defined in the context of the STAT platform?</strong></p>

                    <p className='color-b bodyText'>An indicator is a single or set of criteria that a member of the financial crimes community has identified as financial information that led to an investigation involving human trafficking activity. Indicators can range in specificity - from general information to specific patterns and/or behaviors that are indicative of human trafficking.</p>
                    {breaks}
                    <p className='color-b bodyText'><strong>7. If I contribute indicators, will they be attributed to me or my organization in an identifiable way?</strong></p>

                    <p className='color-b bodyText'>No. All indicators are attributed to a user group (e.g., financial institutions, law enforcement, NGOs) or documented source (e.g., FATF report, US Bankers Alliance, FinCEN) rather than to a specific user or organization. The purpose of such attribution is to enable a STAT user to determine the relevance of a particular indicator to the user’s organization.</p>
                </div>
                <Footer />
            </div>
        )
    }
}