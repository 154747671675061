import React, { Component } from 'react';
import { API } from 'aws-amplify';

import { LeftSideBar } from '../../Components/LeftSideBar';
import { RightSideBar } from '../../Components/RightSideBar';
import { ICardsContainer } from '../../Components/ICardsContainer';
import { ICardModal } from '../../Components/ICardModal';
import { SortICards } from '../../Components/SortICards';
import { SortByClass } from '../../Components/SortByClass';
import { ChangeCardLayout } from '../../Components/ChangeCardLayout';
import { DownloadMultipleBtn } from '../../Components/DownloadMultipleBtn';
import { CSVLink } from "react-csv";
import { AuthFooter } from '../../Components/AuthFooter';

export class SeeAllIndicators extends Component {
    constructor(props){
        super(props);
        this.state = {
            // containerLoading: true,
            newClassification: false,
            mobileMode: false,
            sortByOpen: false,
            showDetailModal: false,
            layoutCards: true,
            activeClassification: 'all',
            sortBy: 'Recent',
            iCardsToBeDownloaded: [],
        }

        this._isMounted = false;
    }

    checkForMobileMode = () => {
        this.setState({ mobileMode: window.innerWidth <= 620 });
    }

    sortNewClass = (newClass) => { // Only show cards based off indicator type
        let newCards = [];

        this.state.indicators.map((item) => {
            let indicatorData = [];

            if(item.indicatorType) {
                indicatorData = item.indicatorType;
            }

            if (indicatorData.length) {
                console.log(newClass);
                if (indicatorData.includes(newClass)) { 
                    return newCards.push(item); // Push this indicator obj to newClassCards array
                }
            }
            return console.log();
        });

        this.setState({
            newClassification: true,
            newClassCards: newCards,
            activeClassification: newClass
        })
    }

    toggleSortBy = () => {
        this.setState({
            sortByOpen: !this.state.sortByOpen
        })
    }

    sortByDate = (type, newLoad) => {
        let cards = [];

        if (newLoad === 'newLoadTrue') {  // Presorting helps first date sort work for some reason
            let preSorted = this.state.indicators.sort((a, b) => { // b - a = most views, descending 
                let viewA = Number(a.viewCount);
                let viewB = Number(b.viewCount);    
                return viewB - viewA; 
            });
            cards = preSorted;

        } else {
            cards = this.cards;
        }

        let sorted = cards.sort((a, b) => { // b - a = Recent, a - b = Oldest
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            let submittedA = a.dateIndicatorSubmitted;
            let submittedB = b.dateIndicatorSubmitted;
            let arrayA = submittedA.split('/');
            let arrayB = submittedB.split('/');

            let months = [ "Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec" ];
            let monthForA = Number(submittedA[1]) - 1;
            let monthForB = Number(submittedB[1]) - 1;
            const textMonthA = months[monthForA];
            const textMonthB = months[monthForB];

            let dayForA = arrayA[0];
            let dayForB = arrayB[0];
            let yearForA = arrayA[2];
            let yearForB = arrayB[2];
            let dateA = textMonthA + '/' + dayForA + '/' + yearForA;
            let dateB = textMonthB + ' ' + dayForB + ', ' + yearForB;
        

            let newDateB = new Date(dateB);
            let newDateA = new Date(dateA); 

            if (type === 'Recent') {
                return newDateB - newDateA;
            } else if (type === 'Oldest') {
                return newDateA - newDateB;
            }
            
            return console.log();
        });

        this.setState({
            sortBy: type
        })

        this.cards = sorted;
        
        if (newLoad === 'newLoadTrue'){
             return
        } else {
            this.toggleSortBy()
        }  
    }


    mostViews = () => {
        let sorted = this.cards.sort((a, b) => { // b - a = most views, descending 
            let viewA = Number(a.viewCount);
            let viewB = Number(b.viewCount);    
            return viewB - viewA; 
        });
        this.setState({
            sortBy: 'Most viewed'
        })

        this.cards = sorted;

        this.toggleSortBy();
    }

    toggleCardLayout = () => {
        this.setState({
            layoutCards: !this.state.layoutCards
        })
    }


    selectToDownload = (obj) => {
        this.setState({
            iCardsToBeDownloaded: [...this.state.iCardsToBeDownloaded, obj]
        })
    }

    removeDownload = (obj) => {
        var array = [...this.state.iCardsToBeDownloaded]; 
        var index = array.indexOf(obj)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ 
                iCardsToBeDownloaded: array
            })
        }
    }

    downloadMultiple = () => {
        console.log(this.state.iCardsToBeDownloaded)
    }
    
    // downloadiCard = (obj) => {
    //     console.log('download this one')
    // }

    showDetailModal = (summary, id, date, source, financial, industry, customer, region, details, iType, transactionTime, traffickingType, views, downloads, fileUrl, bookmarked) => {
        this.setState({
            cardSummary: summary, 
            cardId: id, 
            cardDateSubmitted: date, 
            cardSource: source, 
            cardFinancial: financial, 
            cardIndustry: industry, 
            cardCustomer: customer, 
            cardRegion: region, 
            cardDetails: details, 
            cardiType: iType, 
            cardTransactionTime: transactionTime, 
            cardTraffickingType: traffickingType, 
            cardViews: views,
            cardDownloads: downloads,
            cardFileUrl: fileUrl,
            cardBookmarked: bookmarked,
        })

        setTimeout(() => {
            this.setState({
                showDetailModal: true,
            })
        }, 20)
    }
    
    closeDetailModal = () => {
        this.setState({
            showDetailModal: false,
        })
    }

    componentWillMount(){
        window.addEventListener('resize', this.checkForMobileMode.bind(this));
        this.checkForMobileMode();
    }

    componentDidMount(){
        this._isMounted = true;
        window.scrollTo(0, 0);

        API.get("statmvp", "getindicatordata").then(response => {
            var indicatorArray = [];
            for (var i = 0; i < response.length; i++){
                if (response[i].approved){
                    indicatorArray.push(response[i])
                }
            }
            this.setState({
                indicators: indicatorArray,
            })

            this.sortByDate('Recent', 'newLoadTrue');

        }).catch(e => {
            console.log(e)
        })
    }
    
    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.checkForMobileMode.bind(this));
    }



    render() {
        this.cards = [];
        
        if(this.state.indicators){
            this.cards = this.state.indicators;
        }

        if (this.state.newClassification) {
            this.cards = this.state.newClassCards;
        }

        return (
            <div id='seeAllIndicators'>
                <LeftSideBar page={'dashboard-sub'} />
                <RightSideBar page={'dashboard-sub'} userHasAuthenticated = {this.props.userHasAuthenticated} />

                <div className='innerPage seeAllInner'>
                    <p className='color-b bold pageTitle'>All indicators</p>

                    <div className='flexRow rowEnd smallSortWrapper'>
                        <SortByClass
                            size={'small'}
                            sortNewClass={this.sortNewClass}
                            activeClassification={this.state.activeClassification}
                        />

                        <SortICards 
                            mobileMode={this.state.mobileMode}
                            sortByOpen={this.state.sortByOpen}
                            sortBy={this.state.sortBy}
                            actions={{
                                sortByDate: this.sortByDate,
                                mostViews: this.mostViews,
                                toggleSortBy: this.toggleSortBy,
                            }}
                        />

                        {!this.state.mobileMode &&
                            <ChangeCardLayout
                                layoutCards={this.state.layoutCards}
                                toggleCardLayout={this.toggleCardLayout}
                            />
                        }
                    </div> {/* .smallSortWrapper */}
                    
                    {this.cards &&
                        <ICardsContainer
                            mobileMode={this.state.mobileMode}
                            cards={this.cards}
                            layoutCards={this.state.layoutCards}
                            bookmarksPage={false}
                            actions={{
                                selectToDownload: this.selectToDownload,
                                removeDownload: this.removeDownload,
                                showDetailModal: this.showDetailModal,
                            }}
                        />
                    }

                    {this.state.iCardsToBeDownloaded.length > 0 ?
                        <CSVLink data={this.state.iCardsToBeDownloaded} target="_blank" filename={"stat-indicator.csv"}>
                            <DownloadMultipleBtn
                                numToDownload={this.state.iCardsToBeDownloaded.length}
                                downloadMultiple={this.downloadMultiple}
                            />
                        </CSVLink>
                        : null
                    }

                </div> {/* .seeAllInner */}

                {this.state.showDetailModal &&
                    <ICardModal
                        // isOpen={this.state.showModal} 
                        // onRequestClose={this.closeModal}
                        actions={{
                            closeDetailModal: this.closeDetailModal,
                        }}
                        summary={this.state.cardSummary}
                        details={this.state.cardDetails}
                        id={this.state.cardId}
                        dateSubmitted={this.state.cardDateSubmitted}
                        source={this.state.cardSource}
                        iType={this.state.cardiType}
                        financial={this.state.cardFinancial}
                        customer={this.state.cardCustomer}
                        region={this.state.cardRegion}
                        industry={this.state.cardIndustry}
                        traffickingType={this.state.cardTraffickingType}
                        transactionTime={this.state.cardTransactionTime}
                        fileUrl={this.state.cardFileUrl}
                        views={this.state.cardViews}
                        downloads={this.state.cardDownloads}
                        bookmarked={this.state.cardBookmarked}
                    /> 
                }

                <AuthFooter />
            </div>
        )
    }
}