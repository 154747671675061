import React, { Component } from 'react';
import {
    Button,
    Image } from 'react-bootstrap';

export class DownloadMultipleBtn extends Component {


    render() {
        return (
            <Button id='downloadMultipleBtn' onClick={() => this.props.downloadMultiple()}>
                <p className='color-w noMargin'>
                    <span>Download {this.props.numToDownload} indicator</span>
                    {this.props.numToDownload !== 1 &&
                        <span>s</span>
                    }
                </p>
                <Image
                    className='icon'
                    src={require('../Styles/Images/pinkDownloadBtn.png')}
                />
            </Button>
        )
    }
}