import React, { Component } from 'react';
import Modal from 'react-modal';
import { Image } from 'react-bootstrap';

export class PrivacyPolicyModal extends Component {
    // constructor(props){
    //     super(props);
    // }

    closePolicy = () => {
        this.props.onRequestClose();
    }

    componentWillMount(){
        Modal.setAppElement('body');// So screen readers can see modals
    }

    render(){
        return(
            <div className='privacyPolicy'>
                <Modal
                    {...this.props}
                    // onAfterOpen={this.afterOpenModal}
                    // shouldCloseOnOverlayClick={true}
                    className='innerModal policyModal'
                >
                    <div className='policyInner'>
                        <div className='closeWrapper'>
                            <Image
                                className='closeIcon'
                                width='auto'
                                height='auto'
                                src={require('../Styles/Images/pinkClose.png')}
                                onClick={this.closePolicy}
                            />
                        </div>
                        <p className='color-b bold title'>Privacy Policy</p>

                        <p className='color-b bodyText'>We at Enigma Technologies, Inc. (“Enigma,” “we,” “us,” or “our”) have created this privacy policy (this “Privacy Policy”) because we know that you care about how information you provide to us is used and shared. This Privacy Policy applies to information collected when you use the STAT service and visit www.stat.enigma.com (the “Site”).
                        <br></br><br></br>
                        Description of Users and Acceptance of Terms
                        <br></br><br></br>
                        This Privacy Policy applies to visitors to the Site, who view only publicly-available content (“Visitors”) and users who register with us to access and use the Services made available through STAT (“Registered Users”).
                        By visiting the Site, Visitors are agreeing to the terms of this Privacy Policy and the accompanying Terms of Use.
                        <br></br><br></br>
                        By registering with us to access and use STAT and associated services (the “Services”), each Registered User is agreeing to the terms of this Privacy Policy and must also agree to and execute the accompanying STAT User Agreement.
                        <br></br><br></br>
                        What Information We Collect/Receive; the Purpose of Collection and Use
                        <br></br><br></br>
                        We collect and/or receive the following categories of information.
                        Contact Information: When you contact us through the “Contact Us” page, request a demo of our services, sign up to receive our newsletter, or sign up to become a Registered User, you will be asked to provide certain information which may include first name, last name, e-mail address, job title, and name of your organization (“Contact Information”). The Contact Information is used to provide the requested Services, or information, and to contact Customers, Registered Users and Visitors for purposes of direct marketing of our current and future products and services.
                        <br></br><br></br>
                        Billing Information: In order to purchase our Services, Customers may be required to provide certain additional information which may include a credit card number, expiration date, billing zip code, activation code, and similar information (“Billing Information”). Billing Information is collected and processed by our third-party payment processor operating as our agent (See, Onward Transfers to Third Parties). Enigma does not directly obtain or process any Billing Information.
                        <br></br><br></br>
                        Other Information: In addition to the Contact Information, we may collect or receive the following information (“Other Information”):
                        <br></br><br></br>
                        From Your Activity. In an ongoing effort to improve the Site and the Services we automatically collect certain information when visitors visit the Site and when Registered Users and Customers access the Services. This information consists of IP addresses, browser type and language, referring and exit pages and URLs, date and time, amount of time spent on particular pages, what sections of the Site Visitors visit, and similar information concerning your use of the Site and the Services.
                        <br></br><br></br>
                        From Cookies. We also collect information by using “cookie” technology. Cookies are small packets of data that a website stores on the hard drive of your computer or mobile device to “remember” information about your visit. We may use session cookies (which expire once you close your web browser) and persistent cookies (which stay on your computer/device until you delete them). If you do not want us to place a cookie on your hard drive, you may be able to turn that feature off on your computer or mobile device. Please consult your Internet browser’s documentation for information on how to do this and how to delete persistent cookies. However, if you decide not to accept cookies from us, the Site may not function properly.
                        <br></br><br></br>
                        Third-Party Analytics and Remarketing. We use one or more third–party analytics services (such as Google Analytics) to evaluate your use of the Site and the Services, compile reports on activity (based on their collection of IP addresses, Internet service provider, browser type, operating system and language, referring and exit pages and URLs, data and time, amount of time spent on particular pages, what sections of the Site you visit, number of links clicked while on the Site, search terms and other similar usage data), and analyze performance metrics. These third parties use cookies and other technologies to help analyze and provide us the data. By visiting the Site and accessing and using the Services, you consent to the processing of data about you by these analytics providers in the manner and for the purposes set out in this Privacy Policy. For more information on these third parties, including how to opt out from certain data collection, please visit the sites below. Please be advised that if you opt out of any service, you may not be able to use the full functionality of the Site or the Services.
                        <br></br><br></br>
                        For Google Analytics, please visit: https://www.google.com/analytics
                        For Mixpanel, please visit: https://mixpanel.com
                        <br></br><br></br>
                        We also use one or more third-party remarketing services (including Google Adwords) to advertise on third party websites to previous visitors to our Site. These third-party remarketing service providers use cookies to serve ads based on a past visit to the Site. Any data collected by such third party remarketing service providers will be used in accordance with this Privacy Policy and the privacy policy of such third party remarketing services provider. For more information on such third party remarketing service providers, including how to opt out from certain data collection, please visit the following links:
                        For Google Adwords, you can set preferences for how Google advertises to you using the Google Ad Preferences page, and if you want to you can opt out of interest-based advertising entirely by cookie settings or permanently using a browser plugin.
                        <br></br><br></br>
                        Information Accessible Through Third-Party Providers
                        <br></br><br></br>
                        If you register for the Services and grant Enigma access to accounts you have with third party service providers, including providers of social networking services (“Third-Party Providers”), we may receive personal information that you provided to such Third-Party Providers, or information that is posted on your accounts with them which is viewable on or through the Services, subject to the privacy policies of such Third-Party Providers and privacy settings you have chosen in your accounts with them. By granting us access to your Third-Party Provider accounts, you are authorizing Enigma to collect, store, and use, in accordance with this Privacy Policy, any and all information that you have authorized the Third-Party Provider to provide to Enigma.
                        <br></br><br></br>
                        Aggregate Data
                        <br></br><br></br>
                        In an ongoing effort to better understand our Visitors, Registered Users, Customers, and our products and services, we may analyze your information in aggregate form in order to operate, maintain, manage, and improve the Site and the Services. This aggregate information does not identify you personally. We may share this aggregate data with our affiliates, agents, and business partners. We may also disclose aggregated user statistics in order to describe our products and services to current and prospective business partners and to other third parties for other lawful purposes. In addition, we may provide aggregated statistical collective user information, to our partners so that they understand how often people use specific components of our Services.
                        <br></br><br></br>
                        Additional Uses
                        <br></br><br></br>
                        We also use information collected pursuant to this Privacy Policy to provide our Site and Services to you; to maintain and improve our Site and Services; to solicit your feedback; and to inform you about our products and services and those of our third-party marketing partners.
                        <br></br><br></br>
                        How We Share Your Information
                        <br></br><br></br>
                        Onward Transfer to Third Parties
                        <br></br><br></br>
                        Like many businesses, we contract with other companies to perform certain business-related services. We may disclose Information, including personal information in some cases, to certain types of third-party companies, but only to the extent needed to enable them to provide such services, including, without limitation, monitoring and analyzing Websites activity, operations and marketing assistance, hosting provider, and payment processing. All such third parties function as our agents, performing services at our instruction and on our behalf pursuant to contracts which require they provide at least the same level of privacy protection as is required by this Privacy Policy and implemented by Enigma. We may also disclose your information, including any personal information, to any of our parent companies, subsidiaries, joint ventures, or other companies under common control with us in order to support delivery of our products and services.
                        <br></br><br></br>
                        With regard to the principle of Accountability for Onward Transfer, for example, we remain liable if our agent processes such personal information in a manner inconsistent with the Privacy Shield Principles, unless we prove that we are not responsible for the event giving rise to the damage.
                        <br></br><br></br>
                        Opt-In to Certain Onward Transfers
                        <br></br><br></br>
                        We will not transfer your personal information to a third party acting as a controller without first providing you with information regarding the identity of such controller or the nature of such controller’s business, and obtaining your opt-in consent.
                        We will not disclose your sensitive personal information to any third party without first obtaining your opt-in consent. You may grant such consent by contacting us at privacy@enigma.com.
                        <br></br><br></br>
                        In each instance, please allow us a reasonable time to process your response.
                        <br></br><br></br>
                        Opt-Out for Direct Marketing
                        <br></br><br></br>
                        You may opt out at any time from the use of your personal information for direct marketing purposes by e-mailing the instructions to this email address: privacy@enigma.com. Please allow us a reasonable time to process your request
                        <br></br><br></br>
                        Accessing and Modifying Personal Information and Communication Preferences
                        <br></br><br></br>
                        Upon request to privacy@enigma.com, we will provide you with confirmation as to whether we are processing your personal information, and have the data communicated to you within a reasonable time. You have the right to correct, amend, or delete your personal information where it is inaccurate or has been processed in violation of this Privacy Policy. We may require payment of a non-excessive fee to defray our expenses in this regard. Please allow us a reasonable time to respond to your inquiries and requests. When you update information, however, we often maintain a copy of the unrevised information in our records. You may delete your Enigma account by following the deletion process set forth on the Services. Some information may remain in our records after you discontinue your account.
                        <br></br><br></br>
                        In addition, you may manage your receipt of marketing and non-transactional communications by clicking on the “unsubscribe” link located on the bottom of any Enigma marketing email or contacting us at contact+unsubscribe@enigma.com. Registered Users cannot opt out of receiving transactional e-mails related to their account. We will use commercially reasonable efforts to process such requests in a timely manner. You should be aware, however, that it is not always possible to completely remove or modify information in our subscription databases.
                        <br></br><br></br>
                        Business Transfers
                        <br></br><br></br>
                        In the event of a merger, dissolution, or similar corporate event or the sale of all or substantially all of our assets, we expect that the information that we have collected and/or received, including personal information, would be transferred to the surviving entity in a merger or to the acquiring entity. All such transfers shall be subject to our commitments with respect to the privacy and confidentiality of such personal information as set forth in this Privacy Policy.
                        <br></br><br></br>
                        Disclosures to Public Authorities
                        <br></br><br></br>
                        In certain situations, we may be required to disclose personal information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements. We may also disclose personal information to other third parties when compelled to do so by government authorities or required by law or regulation including, but not limited to, in response to court orders and subpoenas.
                        <br></br><br></br>
                        Information Security
                        <br></br><br></br>
                        Your Enigma account information is protected by a password for your privacy and security. You are responsible for creating this password and for protecting it and not sharing it with any third party. Enigma takes reasonable and appropriate security measures to protect your personal information from loss, misuse, and unauthorized access, disclosure, alteration and destruction, taking into account the risks involved in processing and the nature of such data, and compliance with applicable laws and regulations. You should keep in mind, however, that no internet transmission is 100% secure or error-free. In particular, e-mail sent to or from the Site, may not be secure, and you should therefore take special care in deciding what information you send to us via e-mail or other electronic means.
                        <br></br><br></br>
                        Retention of Personal Information
                        <br></br><br></br>
                        We will retain your personal information in a form that identifies you only for as long as it serves the purposes for which it was initially collected as stated in this Privacy Policy or subsequently authorized. We may continue processing your personal information for longer periods, but only for the time and to the extent such processing reasonably serves the purposes of archiving in the public interest, journalism, literature and art, scientific or historical research, and statistical analysis and subject to the protection of this Privacy Policy. After such time periods have expired, we may either delete your personal information or retain it in a form such that it does not identify you personally.
                        <br></br><br></br>
                        Important Notice to Non-U.S. Residents
                        <br></br><br></br>
                        It is important to note that the Site, the Services, and their servers are operated in the United States. If you are located outside of the U.S., please be aware that any information provided to or collected by us, including personal information, will be transferred from your country of origin to the U.S. Except in the case of data transfers under the EU-U.S. Privacy Shield and the Swiss-U.S. Privacy Shield Framework, your decision to provide such data to us, or allow us to collect such data through our Sites, and Services, constitutes your consent to this data transfer.
                        <br></br><br></br>
                        Important Notice for Residents of the European Economic Area and Switzerland
                        Enigma complies with the EU-U.S. Privacy Shield Framework and the Swiss-U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information from the European Union member countries (including Iceland, Liechtenstein, and Norway) and Switzerland. Enigma has certified that it adheres to the Privacy Shield principles of Notice, Choice, Accountability for Onward Transfer, Security, Data Integrity and Purpose Limitation, Access and Recourse, and Enforcement and Liability (the “Privacy Shield Principles”). If there is a conflict between this Privacy Policy, the Terms of Use, the Customer Agreements, and the Privacy Shield Principles, the Privacy Shield Principles shall govern for EU and Swiss individuals only. To learn more about the Privacy Shield program and to view our certification page, please visit https://www.privacyshield.gov/.
                        <br></br><br></br>
                        Enigma is subject to the investigatory and enforcement powers of the Federal Trade Commission.
                        <br></br><br></br>
                        In compliance with the EU-U.S. and the Swiss-U.S. Privacy Shield Principles, Enigma commits to resolve complaints about your privacy and our collection or use of your personal information. EU or Swiss individuals with inquiries or complaints regarding this Privacy Policy should first contact Enigma at privacy@enigma.com.
                        <br></br><br></br>
                        Enigma has further committed to refer unresolved privacy complaints under the EU-U.S. and Swiss-U.S. Privacy Shield Principles to BBB PRIVACY SHIELD, a non-profit alternative dispute resolution provider located in the United States and operated by the Council of Better Business Bureaus. If you do not receive timely acknowledgment of your complaint, or if your complaint is not satisfactorily addressed by Enigma, please visit www.bbb.org/EU-privacy-shield/for-eu-consumers/ for more information and to file a complaint. If these processes do not result in a resolution, you may also contact your local data protection authority, the U.S. Department of Commerce, and/or the Federal Trade Commission for assistance. If your complaint still remains unresolved, then you have the right to invoke binding arbitration by the Privacy Shield Panel upon written notice to Enigma at privacy@enigma.com.
                        <br></br><br></br>
                        Publicly Available Information
                        <br></br><br></br>
                        In accordance with the Privacy Shield Principles, in cases where Enigma discloses publicly available information from the European Union member countries and/or Switzerland without combining that information with non-public information, our policies on Notice, Choice, and Accountability for Onward Transfer set for in this Privacy Policy may not apply.
                        <br></br><br></br>
                        Links to External Websites
                        <br></br><br></br>
                        The Site and the Services may contain links to third party websites ("External Sites"). Enigma has no control over the privacy practices of these External Sites. As such, we are not responsible for the privacy policies of those External Sites. You should check the applicable third party privacy policy and terms of use when visiting any External Sites, and before providing any personal information to such External Sites.
                        <br></br><br></br>
                        Children
                        <br></br><br></br>
                        We do not knowingly collect any personal information from children under the age of thirteen through the Site and the Services. If you are under 13, please do not give us any personal information. We encourage parents and legal guardians to monitor their children's Internet usage and to help enforce our Privacy Policy by instructing their children never to provide personal information to us without their permission. If you have reason to believe that a child under the age of 13 has provided personal information to us, please contact us at privacy@enigma.com, and we will endeavor to delete that information from our databases.
                        <br></br><br></br>
                        California Privacy Rights
                        <br></br><br></br>
                        Pursuant to Section 1798.83 of the California Civil Code, residents of California have the right to obtain certain information about the types of personal information that companies with whom they have an established business relationship (and that are not otherwise exempt) have shared with third parties for direct marketing purposes during the preceding calendar year, including the names and addresses of those third parties, and examples of the types of services or products marketed by those third parties. If you wish to submit a request pursuant to Section 1798.83, please contact Enigma via email at privacy@enigma.com.
                        <br></br><br></br>
                        In addition, Enigma does not monitor, recognize or honor any opt-out or do not track mechanisms including general web browser, “Do Not Track” settings and/or signals.
                        <br></br><br></br>
                        Changes to this Privacy Policy
                        <br></br><br></br>
                        This Privacy Policy is effective as of the date stated at the top of this Privacy Policy. We may change this Privacy Policy from time to time, and will post any changes on the Site and the Services as soon as they go into effect. By accessing the Site and/or the Services after we make any such changes to this Privacy Policy, you are deemed to have accepted such changes. Please refer back to this Privacy Policy on a regular basis.
                        <br></br><br></br>
                        Questions or Concerns
                        <br></br><br></br>
                        If you have any questions or concerns regarding privacy at Enigma, please send us a detailed message to privacy@enigma.com or to Enigma Technologies, Inc. at 245 Fifth Avenue, 17th Floor, New York, NY 10016. Your privacy is important to us and we will make every effort to resolve your concerns.
                        </p>
                    </div>
                </Modal>
            </div>
        )
    }
}