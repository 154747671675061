import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

export class SortICards extends Component {

    render() {
        return (
            <div className='sortByGroup'>
                <Button
                    // id='sortByMenu' 
                    className='sortByDropDown'  
                    onClick={this.props.actions.toggleSortBy}                           
                >
                    <span>{this.props.sortBy}</span>
                    <span className='caret'></span>
                </Button>

                <div className={this.props.sortByOpen ? 'sortByOpenDiv open' : 'sortByOpenDiv'}>
                    <li
                        className={this.props.sortBy === 'Recent' ? 'bold sortName active': 'light sortName'}
                        onClick={() => this.props.actions.sortByDate('Recent', 'newLoadFalse')}
                    >
                        Recent
                    </li>
                    <li 
                        className={this.props.sortBy === 'Oldest' ? 'bold sortName active': 'light sortName'}
                        onClick={() => this.props.actions.sortByDate('Oldest', 'newLoadFalse')}
                    >
                        Oldest
                    </li>
                    <li 
                        className={this.props.sortBy === 'Most viewed' ? 'bold sortName active': 'light sortName'}
                        onClick={() => this.props.actions.mostViews()}
                    >
                        Most viewed
                    </li>
                </div> {/* .sortOpenDiv */}
            </div> // .sortByGroup 
        )
    }
}