import React, { Component } from 'react';
// import Modal from 'react-modal';
import { 
    Image,
    Button,
    OverlayTrigger, // shows and hides on/off hover
    Tooltip } from 'react-bootstrap';

import doc from '../Styles/Images/doc.png';
import pdf from '../Styles/Images/pdf.png';
import excel from '../Styles/Images/excel.png';

import { Storage, API } from 'aws-amplify';
import { CSVLink } from "react-csv";

export class ICardModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            attachmentUrl: []
        }
    }

    closeModal = () => {
        this.props.actions.closeDetailModal();
    }

    clickOutsideModal = (e) => {
        if (!this.iModalElement.contains(e.target)) {
            this.props.actions.closeDetailModal();
        }
    }

    componentDidMount(){
        document.addEventListener('mousedown', this.clickOutsideModal);

        for (var i = 0; i < this.props.fileUrl.length; i++){
            var item = this.props.fileUrl[i];
            Storage.get(item).then(response => {
                var attachmentUrl = this.state.attachmentUrl;
                attachmentUrl.push(response);
                this.setState({
                    attachmentUrl: attachmentUrl
                }) 
            }).catch(error => {
                console.log(error)
            });
        }
    }

    downloadIndicator = () => {
        API.post('statmvp', 'adddownloadcount', {
            body: {
                indicatorId: this.id,
                dateIndicatorSubmitted: this.dateSubmitted,
                downloads: this.downloads
            }
        })
    }

    componentWillUnmount(){
        document.removeEventListener('mousedown', this.clickOutsideModal);
    }

    render(){

        this.summary = this.props.summary;
        this.id = this.props.id;
        this.dateSubmitted = this.props.dateSubmitted;
        this.source = this.props.source;
        this.financial = this.props.financial;
        this.industry = this.props.industry
        this.customer = this.props.customer;
        this.region = this.props.region;
        this.details = this.props.details;
        this.iType = this.props.iType;
        this.transactionTime = this.props.transactionTime;
        this.traffickingType = this.props.traffickingType;
        this.views = this.props.views;
        this.downloads = this.props.downloads;
        this.fileUrl = this.props.fileUrl;
        this.bookmarked = this.props.bookmarked;

        this.doc = this.fileUrl;  

        let fileTable = [];

        if (this.fileUrl && this.fileUrl.length) {
            let fileArray = [];
        
            fileArray = fileArray.concat(this.doc);

            fileTable = fileArray.map((item, index) => {
                let fileIcon = false;
                let fileType = item;   
                let extension = fileType.split(".").pop().toLowerCase().toString();

                if (extension) {
                    if (extension === "pdf") {
                        fileIcon = pdf;
                    } else if (extension === "doc" || extension === "docx") {
                        fileIcon = doc;
                    } else if (extension === "xls" || extension === "csv" || extension === "xlsx") {
                        fileIcon = excel;
                    } else {
                        fileIcon = pdf;
                    }
                }

                let attachment;
                
                if (this.state.attachmentUrl != null){
                    attachment = this.state.attachmentUrl[index];
                } else {
                    attachment = "";
                }
// console.log('fileArray[' + index + ']')
                return (
                    <div key={index} className={'flexRow fileTableRows'}>
                        {fileIcon !== false ?
                            <div className='fileIcon'>
                                <Image
                                    width='auto'
                                    height='100%'
                                    src={`${fileIcon}`}
                                />
                            </div>
                        : null}

                        <p className='noMargin fileName'>{item}</p>
                        
                        <div className='downloadFileIconDiv'>
                            <a href={attachment} target="_blank" rel="noopener noreferrer">
                                <div className='fileDownloadIcon pointer'>
                                </div>
                            </a>
                        </div>
                    </div>
                ) 
            })
        } 

    

    // show indicator types
        let indicatorTypes = [];
        if(this.iType && this.iType.length) {
            indicatorTypes = this.iType.map((item, index) => {
                let letter = item.charAt(0).toLowerCase();
                let thisId = 'iTypeTooltip-' + letter;
                return (
                    <div key={item}>
                        <OverlayTrigger 
                            placement={'bottom'}
                            overlay={
                                <Tooltip 
                                    id={thisId} 
                                    className='iTypeTooltip'
                                >
                                    <p className='color-w light noMargin'>{item}</p>
                                </Tooltip>
                            }
                        >
                            <div id={letter} className=' flexRow typePills'>
                                <span className='color-w uppercase'>{letter}</span>
                            </div>
                        </OverlayTrigger>
                    </div>
                )
            })
        }

    // show financial
        let financialList = [];
        if (this.financial && this.financial.length) {
            financialList = this.financial.map((item, index) => {
                return (
                    <p key={item} className='color-b infoText'>
                        {item}
                    </p>
                )
            })
        }

    // show customer
        let customerList = [];

        if (this.customer && this.customer.length) {
            customerList = this.customer.map((item, index) => {
                return (
                    <p key={item} className='color-b infoText'>
                        {item}
                    </p>
                )
            })
        }

    // show region
        let regionList = [];

        if (this.region && this.region.length) {
            regionList = this.region.map((item, index) => {
                return (
                    <p key={item} className='color-b infoText'>
                        {item}
                    </p>
                )
            })
        }

    // show industry
        let industryList = [];

        if (this.industry && this.industry.length) {
            industryList = this.industry.map((item, index) => {
                return (
                    <p key={item} className='color-b infoText'>
                        {item}
                    </p>
                )
            })
        }

    // show traffickingType
        let traffickingList = [];

        if (this.traffickingType && this.traffickingType.length) {
            traffickingList = this.traffickingType.map((item, index) => {
                return (
                    <p key={item} className='color-b infoText'>
                        {item}
                    </p>
                )
            })
        }

        // For Indicator Download
        var csvData = [
            { summary: this.summary,
                indicatorId: this.id,
                dateSubmitted: this.dateSubmitted,
                sourceFrom: this.source,
                financialProduct: this.financial,
                impactedIndustry: this.industry,
                customerType: this.customer,
                geographicalRegion: this.region,
                indicatorDetails: this.details,
                indicatorType: this.iType,
                transactionTime: this.transactionTime,
                traffickingType: this.traffickingType,
                viewCount: this.views }
          ]; 
     

        return (
            <div className='iCardModal'>
                {/* 
                <Modal
                    {...this.props}
                    // onAfterOpen={this.afterOpenModal}
                    shouldCloseOnOverlayClick={true}
                    className='innerModal iCard-innerModal'
                >
                */}
                    <div className='iCardModalBody'> 
                        
                    <div className='iCard-innerModal' ref={(iModalElement) => this.iModalElement = iModalElement}>
                        <div className='mainInfo'>
                            <div className={this.bookmarked ? 'bookmarkedIcon true': 'bookmarkedIcon false'}>
                            </div>
                            
                            <div className='closeWrapper'>
                                <Image
                                    className='closeIcon'
                                    width='auto'
                                    height='auto'
                                    src={require('../Styles/Images/pinkClose.png')}
                                    onClick={() => this.closeModal()}
                                />
                            </div>
        {/* Summary */}
                            <div className='infoGroup divider rowOne'>
                                <p className='color-b smallText'>Summary</p>
                                {this.summary && this.summary.length &&
                                    <p className='color-b bold infoText'>{this.summary}</p>
                                }
                            </div>
        {/* ID */}
                            {this.id && this.id.length &&
                                <div className='infoGroup'>
                                    <p className='color-b uppercase light infoText'>ID# {this.id}</p> 
                                </div>
                            }
        {/* Description */}
                            {this.details && this.details.length &&
                                <div className='infoGroup divider'>
                                    <p className='color-b smallText'>Description</p>
                                    <p className='color-b infoText light'>{this.details}</p>
                                </div>
                            }
        {/* Date */}
                            {this.dateSubmitted && this.dateSubmitted.length &&
                                <div className='infoGroup divider'>
                                    <p className='color-b smallText'>Date</p>
                                    <p className='color-b infoText'>{this.dateSubmitted}</p>
                                </div>
                            }
        {/* Source */}
                            {this.source && this.source.length &&
                                <div className='infoGroup divider'>
                                    <p className='color-b smallText'>Source</p>
                                    <p className='color-b infoText light'>{this.source}</p>
                                </div>
                            }
        {/* Indicator Type */}
                            {this.iType && this.iType.length &&
                                <div className='infoGroup divider'>   
                                    <p className='color-b smallText' style={{marginBottom: '0.5em'}}>Indicator type</p> 
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        {indicatorTypes.length ? indicatorTypes : null}
                                    </div>
                                </div>
                            }

                            <div className='categoryContainer'>
        {/* Financial */}
                                {this.financial && this.financial.length && 
                                    <div className='categories'>
                                        <p className='color-b smallText'>Financial product</p>
                                        {financialList.length ? financialList : null}
                                    </div>
                                }
        {/* Customers */}
                                {this.customer && this.customer.length && 
                                    <div className='categories'>
                                        <p className='color-b smallText'>Customer type</p>
                                        {customerList.length ? customerList : null}
                                    </div>
                                }
        {/* Region */}
                                {this.region && this.region.length && 
                                    <div className='categories'>
                                        <p className='color-b smallText'>Geographical region</p>
                                        {regionList.length ? regionList : null}
                                    </div>
                                }
        {/* Industry */}
                                {this.industry && this.industry.length && 
                                    <div className='categories'>
                                        <p className='color-b smallText'>Impacted industry</p>
                                        {industryList.length ? industryList : null}
                                    </div>
                                }
        {/* Trafficking type */}
                                {this.traffickingType && this.traffickingType.length && 
                                    <div className='categories'>
                                        <p className='color-b smallText'>Trafficking Type</p>
                                        {traffickingList.length ? traffickingList : null}
                                    </div>
                                }
                            </div>

        {/* Supporting Docs  */}
                            {this.doc && this.doc.length &&
                                <div>
                                    <div className='noMargin'>
                                        <p className='color-b smallText docTitle'>Supporting documentation</p>
                                    </div>
                                    <div className='fileContainer'>
                                        {fileTable.length ? fileTable : null}
                                    </div>
                                </div>
                            }
        {/* Metrics */}
                            <div className='infoGroup'>
                                <p className='color-b smallText'>Indicator metrics</p>
                                {/* {this.downloads && */}
                                    <p className='color-b infoText'>Downloads {this.downloads}</p>
                                {/* } */}
                                {/* {this.views && */}
                                    <p className='color-b infoText'>Views {this.views}</p>
                                {/* } */}
                            </div>
                        </div> {/* .mainInfo */}
    {/* Download Button  */}
                        
                        <CSVLink data={csvData} target="_blank" filename={"stat-indicator.csv"} onClick={this.downloadIndicator}>
                        <Button 
                            className='downloadBtn'     
                        >
                            Download Indicator
                            <Image
                                className='cardDownloadIcon'
                                width='auto'
                                height='auto'
                                src={require('../Styles/Images/pinkDownloadBtn.png')}
                            />
                        </Button>
                        </CSVLink>
                    </div> {/* .iCard-innerModal */}
                </div>
                {/* </Modal> */}
            </div>
        )
    }
}