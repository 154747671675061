import React, { Component } from 'react';
import { Image } from 'react-bootstrap';

export class OrgsCard extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
        }
    }

    componentDidMount(){
        let obj = this.props.obj;

        if (obj.hasOwnProperty('logo')) {
            // let syntax = require( obj.logo);
            this.setState({ logoSrc: obj.logo });
        }

        if (obj.hasOwnProperty('details')) {
            this.setState({ details: obj.details });
        }

        if (obj.hasOwnProperty('website')) {
            this.setState({ website: obj.website });
        }
    }

    render(){
        return (
            <div className='orgsCard'>
                <a className='wrapperLink' href={'https://www.' + this.state.website} target='_blank' rel='noopener noreferrer'>
                    <div className='logoDiv'> 
                        <div > 
                            <Image 
                                className='orgLogos'
                                width='auto'
                                height='auto'
                                src={`${this.state.logoSrc}`}
                            />
                        </div>

                        <div className='divider'>
                        </div>
                    </div>
                        
                    <div className='details'>
                        <p className='color-b text'>{this.state.details}</p>
                        <a href={'https://www.' + this.state.website} target='_blank' rel='noopener noreferrer'>
                            <p className='color-pk noMargin website'>{this.state.website}</p>
                        </a>
                    </div>
                </a>
            </div>
        )
    }
}