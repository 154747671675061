import React, { Component } from 'react';
import Modal from 'react-modal';
import { API, Auth }from 'aws-amplify';
import TextareaAutosize from 'react-autosize-textarea';
import { 
    FormGroup,
    FormControl,
    Image } from 'react-bootstrap';

export class ProfileFeedback extends Component {
    constructor(props){
        super(props);
        this.state = {
            sent: false,
            email: '',
            message: '',
        }
    }

    getEmail = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    getMessage = (e) => {
        this.setState({
            message: e.target.value
        })
    }

    submit = () => {
        if (this.state.email.length >= 5) {
            if(this.props.forWhat === 'delete') {
                API.post("statmvp", "deleteprofile", {
                    body: {
                        email: this.state.email,
                        message: this.state.message
                    }
                }).then(response => {
                    this.setState({
                        sent: true
                    })
                    Auth.signOut();
                    API.post("statmvp", "userlogout").then(response => {
                        console.log('success')
                      }).catch(error => {
                        console.log(error)
                      })
                    this.props.userHasAuthenticated(false);
                }).catch(error => {
                    console.log(error)
                })
            } else {
                API.post("statmvp", "unsubscribeuser", {
                    body: {
                        email: this.state.email,
                        message: this.state.message
                    }
                }).then(response => {
                    this.setState({
                        sent: true
                    })
                }).catch(error => {
                    console.log(error)
                })
            }
        } else {
            alert("Please enter your email")
        }
    }

    closeFeedback = () => {
        this.props.onRequestClose();
        if(this.state.sent) {
            this.setState({
                sent: false,
                email: '',
                message: ''
            })
        }
    }


    componentWillMount(){
        Modal.setAppElement('body');// So screen readers can see modals
    }

    render(){
        return (
            <div>
                <Modal
                    {...this.props}
                    shouldCloseOnOverlayClick={true}
                    className='innerModal profileFeedbackModal'
                >
                    <div className='profileFeedback open'>
                        <div className='feedInner open'>
                            <div className='bubbleDiv open' onClick={this.closeFeedback}>
                                <p className='color-w noMargin bubbleText uppercase'>{this.props.title}</p>
                            </div>
                                <div className='insightsFeedbackDiv'>  
                                    {this.state.sent ?
                                        <div className='sentMessage'>
                                            <p className='color-pk centered'>message sent</p>
                                        </div>
                                    :
                                        <form className='form' onSubmit={this.handleSubmit}>
                                            <div className='wrapper'>
                                                <FormGroup controlId='emailGroup' className='emailGroup'>
                                                    <FormControl
                                                        className={'color-b email'}
                                                        autoFocus
                                                        type='email'
                                                        value={this.state.email}
                                                        placeholder={'Enter your email address'}
                                                        onChange={this.getEmail}
                                                    />
                                                </FormGroup>

                                                <FormGroup controlId="formControlsTextarea" className='textAreaGroup'>
                                                    <TextareaAutosize
                                                        className={'textArea'}
                                                        value={this.state.message}
                                                        rows={1} // min rows
                                                        placeholder={this.props.bodyText}
                                                        onChange={this.getMessage}
                                                    />
                                                </FormGroup>

                                                <div className='sendDiv'>
                                                    <p className='color-pk noMargin text'>Send a message...</p>
                                                    <div className='iconWrapper'>
                                                        <Image
                                                            className='sendIcon'
                                                            width='auto'
                                                            height='auto'
                                                            src={require('../../Styles/Images/sendFeedback.png')}
                                                            onClick={this.submit}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    }
                                </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}