import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Button, 
    FormGroup,
    FormControl } from 'react-bootstrap';
import { Auth } from 'aws-amplify';

import { ForgotPasswordModal } from '../../Components/ForgotPasswordModal';

// import { Link } from 'react-router-dom';

    export class Login extends Component {
        constructor(props){
            super(props);
            this.state = {
                loginDisabled: true,
                openForgotPassword: false,
                email: '',
                password: '',
                loading: false,
            }

            this.handleSubmit = this.handleSubmit.bind(this);
        }

        handleChange = (e) => {
            if (this.state.email.length > 5 && this.state.password.length > 4) {
                this.setState({
                    [e.target.id]: e.target.value,
                    loginDisabled: false
                });
            } else {
                this.setState({
                    [e.target.id]: e.target.value,
                    loginDisabled: true,
                });
            }
        }

        disabledLogin = (e) => {
            e.preventDefault();
            alert('Please fill all of the fields before requesting access.')
        }
    

        handleSubmit(e){
            e.preventDefault();

            this.setState({
                loading: true
            })

            Auth.signIn(this.state.email, this.state.password).then(response => {
                this.props.userHasAuthenticated(true);
            }).catch(error => {
                
                this.setState({
                    loading: false
                })

                let errorMessage = error.message.toLowerCase();
                 if (errorMessage === "user does not exist." ) {
                    alert("We do not have you as an authorized user. If you want access to the platform please click on 'Be a part of the Community' link and fill out the form.");
                 } else if (errorMessage === "user is not confirmed."){
                    alert("Your account is not verified yet. You will receive an email when your account is verified.")
                } else {
                    alert("Your email or password doesn't match our database. Please try again with the correct email or click on Forgot Password to reset your password.");
                }
            })
        }

        toggleForgotPassword = () => {
            this.setState({
                openForgotPassword: !this.state.openForgotPassword
            })
        }

        render(){
            return (
                <div className='login'>
                    <form className='form' onSubmit={this.handleSubmit} autoComplete='off' >
                        <input autoComplete="false" style={{display: 'none'}} />
                        <FormGroup controlId='email' bsSize='large'>
                            <FormControl
                                className={'actionInput white email'}
                                type='email'
                                value={this.state.email}
                                placeholder={'Email'}
                                onChange={this.handleChange}
                                onClick={this.props.changeLoginImage}
                                data-lpignore="true"
                            />
                        </FormGroup>

                        <FormGroup controlId='password' bsSize='large'>
                            <FormControl
                                className={'actionInput white password'}
                                value={this.state.password}
                                placeholder={'Password'}
                                onChange={this.handleChange}
                                type={'password'}
                                onClick={this.props.changeLoginImage}
                                data-lpignore="true"
                            />
                        </FormGroup>
                        
                        <Link to='/login/reset'>
                        <p className='forgotPassword' 
                        // onClick={this.toggleForgotPassword}
                        >Forgot password?</p></Link>

                        {this.state.loginDisabled ?
                            <Button type='button' className='signInBtn disable' disabled={false} onClick={this.disabledLogin}>SIGN IN</Button>
                        :
                            <Button type='submit' className='signInBtn'>SIGN IN</Button>
                        }
                        
                    </form>

{/* Forgot password modal */}
                    <ForgotPasswordModal 
                        isOpen={this.state.openForgotPassword} 
                        onRequestClose={this.toggleForgotPassword}
                    />
                </div>
            )
        }
    }