import React, { Component } from 'react';
import { 
    Carousel,
    Image,
    Grid, 
    Row, 
    Col } from 'react-bootstrap';
import { HomeSidebar } from './HomeSidebar';
import { RequestAccess } from './RequestAccess';
import { Login } from './Login';
import { ResourcesContainer } from '../../Components/ResourcesContainer';
import { Footer } from '../../Components/Footer';

class Homepage extends Component{
    constructor(props){
        super(props);
        this.state = {
            mobileMode: false,
            requestAccess: true,
            successfulSignup: false,
            login: false,
            changeLoginImage: false,
            firstCreditsOpen: false,
            secondCreditsOpen: false,
        }
    }

    checkForMobileMode = () => {
        this.setState({ mobileMode: window.innerWidth <= 620 });
    }

    showLogin = () => {
        this.setState({
            requestAccess: false,
            login: true
        })
    }

    showRequestAccess = () => {
        this.setState({
            requestAccess: true,
            login: false
        })
    }

    successfulSignup = () => {
        this.setState({
            successfulSignup: true
        })
    }

    changeLoginImage = () => {
        if (!this.state.mobileMode) {
            this.setState({
                changeLoginImage: true
            })
        } 
    }

    componentDidMount(){
        if (this.props.location.state) {
            if (this.props.location.state.login === "re-auth"){
                this.setState({
                    requestAccess: false,
                    login: true
                })
            }
        }
    }

    componentWillMount(){
        window.addEventListener('resize', this.checkForMobileMode.bind(this));
        this.checkForMobileMode();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkForMobileMode.bind(this));
    }

    render() {
        return (
            <div className='homePage'>
                {/* <Navbar color='white'/>  */}
                <HomeSidebar mobileMode={this.state.mobileMode}/>

                <Grid id='top' className='sections section1' fluid={true}>
                    <Row className='show-grid actionRow one actionBtnWrap'>
                        <Col sm={0} md={1} className='emptyCol'>
                            <p></p>
                        </Col>

                        <Col sm={12} md={10} className='index4'>
                            {this.state.requestAccess ?
                                <p className='color-pk actionBtn' onClick={this.showLogin}>Sign In</p>
                            :
                                <p className='color-pk actionBtn' onClick={this.showRequestAccess}>Become Part of the Community</p>
                            }
                        </Col>

                        <Col sm={0} md={1} className='emptyCol'>
                        </Col>
                    </Row>
    {/* Row 2 */}
                    <Row className='show-grid actionRow two'>
                        <Col sm={0} md={1} className='emptyCol'>
                            <p></p>
                        </Col>

                        <Col sm={0} md={5} className='actionCol one index4'>
                            {this.state.requestAccess ?
                                <div>
                                    {!this.state.mobileMode &&
                                        <div>
                                            {this.state.successfulSignup  ?
                                                <div>
                                                    <Image
                                                        className='ssImg'
                                                        width='100%'
                                                        height='auto'
                                                        src={require('../../Styles/Images/stat02.png')}
                                                    />
                                                </div>
                                            :
                                                <p className='requestSubTitle'>A comprehensive resource to end human trafficking through financial disruption</p>
                                            }
                                        </div>
                                    }
                                </div>
                            :
                                <div>
                                    {!this.state.mobileMode &&
                                        <div>
                                            {this.state.changeLoginImage ?
                                                <Image 
                                                    className='loginLeftImg'
                                                    width='100%'
                                                    height='auto'
                                                    src={require('../../Styles/Images/stat03.png')}
                                                />
                                            :
                                                <Image
                                                    className='loginLeftImg'
                                                    width='100%'
                                                    height='auto'
                                                    src={require('../../Styles/Images/stat01.png')}
                                                />
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        </Col>

                        <Col sm={12} md={5} className='actionCol two index4'>
                            {this.state.requestAccess ?
                                <div>
                                    <RequestAccess successfulSignup={() => this.successfulSignup()}/>
                                </div>
                            :
                                <div>
                                    <p className='loginTitle'>Start your journey</p>
                                    <Login 
                                        userHasAuthenticated = {this.props.userHasAuthenticated} 
                                        changeLoginImage={() => this.changeLoginImage()}
                                        history = {this.props.history}
                                        />
                                </div>
                            }
                        </Col>

                        <Col sm={0} md={1} className='emptyCol'>
                            <p></p>
                        </Col>
                    </Row>
    {/* Row 3 */}
                    <Row className='show-grid actionRow three actionBtnWrap'> 
                        <Col sm={12} md={12} className='index4'>
                            {this.state.requestAccess ?
                                <div>
                                    <p className='color-w light'>or</p>
                                    <p className='color-pk actionBtn' onClick={this.showLogin}>Sign In</p>
                                </div>
                            :
                                <div>
                                    <p className='color-w light'>or</p>
                                    <p className='color-pk actionBtn' onClick={this.showRequestAccess}>Become Part of the Community</p>
                                </div>
                            }
                        </Col>
                    </Row>
                </Grid>
{/* About Carousel */}
                <div id='about' className='sections about'>
                    <Carousel
                        className={'carousel'}
                        animate={'true'}
                        // activeIndex={0} //pauses the slider so i can work on it
                        interval={10000}
                        // direction='left'
                        indicators={false}
                        controls={false}
                        pauseOnHover={false}
                        wrap={true}
                    >
                        <Carousel.Item className='carouselItem carousel-1'>
                            <Carousel.Caption>    
                                <div className='flexRow rowEnd'>                                
                                    <div className='partners'>
                                        <p className='color-b title bold'>In partnership with ACAMS, Polaris, and Enigma</p>
                                        <div className='logoDivs partner1'>
                                            <Image 
                                                className='logos'
                                                width='auto'
                                                height='auto'
                                                src={require('../../Styles/Images/acamsBlue.png')}
                                            />
                                        </div>
                                        <div className='logoDivs partner2'>
                                            <Image 
                                                className='logos'
                                                width='auto'
                                                height='auto'
                                                src={require('../../Styles/Images/polarisBlue.png')}
                                            />
                                        </div>
                                        <div className='logoDivs partner3'>
                                            <Image 
                                                className='logos'
                                                width='auto'
                                                height='auto'
                                                src={require('../../Styles/Images/enigmaBlue.png')}
                                            />
                                        </div>
                                    </div> {/* .partners */}
                                </div> {/* .flexRow */}
                                <div className='credits'>
                                    <div className='flexRow rowCentered'>
                                        <p className='color-w noMargin firstTitle'>Photo Credit: Project Ghana Workshop by Beauty for Freedom</p>
                                        <Image 
                                            className='creditIcon'
                                            width='auto'
                                            height='auto'
                                            src={require('../../Styles/Images/photoCredsEye.png')}
                                            onClick={() => this.setState({ firstCreditsOpen: !this.state.firstCreditsOpen })}
                                        />
                                    </div>
                                    {this.state.firstCreditsOpen &&
                                        <div className='openCredits'>
                                            <div className='flexRow rowBetween rowOne'>
                                                <p className='color-b noMargin creditsTitle'>Project Ghana Workshop</p>
                                                <Image 
                                                    className='closeCreditIcon'
                                                    width='auto'
                                                    height='auto'
                                                    src={require('../../Styles/Images/pinkClose.png')}
                                                    onClick={() => this.setState({ firstCreditsOpen: !this.state.firstCreditsOpen })}
                                                />
                                            </div>
                                            <p className='color-b light leftAlign body'>Beauty for Freedom is an art therapy initiative that provides photography, watercolor painting and mural workshops to over 300 youth at the Challenging Heights Friends International Academy and 58 young survivors of labor trafficking house at the CH Hovde House.</p>
                                            <p className='color-pk leftAlign noMargin website'>beautyforfreedom.org</p>
                                        </div> // .openCredits
                                    }
                                </div> {/* .credits */}
                            </Carousel.Caption> 
                        </Carousel.Item>
    {/* Carousel 2 */}
                        <Carousel.Item className='carouselItem carousel-2'>
                            <Carousel.Caption>    
                                <div className='flexRow rowEnd'>                                
                                    <div className='partners'>
                                        <p className='color-b title bold'>The STAT platform brings</p>
                                        <div className='logoDivs partner1 platforms'>
                                            <p className='color-b leftAlign noMargin'>Insights and indicators for and from financial institutions</p>
                                        </div>
                                        <div className='logoDivs partner2 platforms'>
                                            <p className='color-b leftAlign noMargin'>Critical intelligence for and from law enforcement</p>
                                        </div>
                                        <div className='logoDivs partner3 platforms'>
                                            <p className='color-b leftAlign noMargin'>Curated resources, guides and intelligence from expert NGOs</p>
                                        </div>
                                    </div> {/* .partners */}
                                </div> {/* .flexRow */}

                                <div className='credits'>
                                    <div className='flexRow rowCentered'>
                                        <p className='color-w noMargin firstTitle'>Photo Credit: Project Ghana Workshop by Beauty for Freedom</p>
                                        <Image 
                                            className='creditIcon'
                                            width='auto'
                                            height='auto'
                                            src={require('../../Styles/Images/photoCredsEye.png')}
                                            onClick={() => this.setState({ secondCreditsOpen: !this.state.secondCreditsOpen })}
                                        />
                                    </div>
                                    {this.state.secondCreditsOpen &&
                                        <div className='openCredits'>
                                            <div className='flexRow rowBetween rowOne'>
                                                <p className='color-b noMargin creditsTitle'>Project Ghana Workshop</p>
                                                <Image 
                                                    className='closeCreditIcon'
                                                    width='auto'
                                                    height='auto'
                                                    src={require('../../Styles/Images/pinkClose.png')}
                                                    onClick={() => this.setState({ secondCreditsOpen: !this.state.secondCreditsOpen })}
                                                />
                                            </div>
                                            <p className='color-b light leftAlign body'>Beauty for Freedom is an art therapy initiative that provides photography, watercolor painting and mural workshops to over 300 youth at the Challenging Heights Friends International Academy and 58 young survivors of labor trafficking house at the CH Hovde House.</p>
                                            <p className='color-pk leftAlign noMargin website'>beautyforfreedom.org</p>
                                        </div>
                                    }
                                </div>
                            </Carousel.Caption> 
                        </Carousel.Item>
                    </Carousel>
                </div>

{/* DNA of an Indicator */}
                <Grid id='dna' className='sections dna' fluid={true}>
                    <Row className='show-grid'>
                        <Col sm={12} className='col index4'>
                        </Col>
                    </Row>
                </Grid>

{/* Resources */}
                <Grid id='resources' className='sections resources' fluid={true}>
                    <Row className='show-grid'>
                        <Col sm={12} className='titleCol col index4'>
                            <p>Learn about how organizations are taking a stand</p>
                            <h2>Broaden Your View</h2>
                        </Col>
                    </Row>
                    <Row className='show-grid '>
                        <Col sm={12} className='col index4'>
                            <ResourcesContainer placement={'homePage'} />
                        </Col>
                    </Row>
                </Grid>
    {/* Footer */}
                <Footer />
            </div>
        );
    }
}

export default Homepage;