import React, { Component } from 'react';
import Modal from 'react-modal';
import {
    Button, 
    Image,
    FormGroup,
    FormControl } from 'react-bootstrap';

export class ForgotPasswordModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            email: ''
        }
    }

    closeModal = () => {
        this.props.onRequestClose();
    }

    handleChange = (e) => {
        this.setState({
            email: e.target.value,
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        //console.log(this.state.email)
    }

    componentWillMount(){
        Modal.setAppElement('body');// So screen readers can see modals
    }

    render() {
        return (
            <div>
                 <Modal
                    {...this.props}
                    // onAfterOpen={this.afterOpenModal}
                    // shouldCloseOnOverlayClick={true}
                    className='innerModal forgotPasswordModal'
                >
                    <div className='passwordModalInner'>
                        <div className='flexRow rowBetween closeWrapper'>
                            <p>Forgot password</p>
                            <Image
                                className='closeIcon'
                                width='auto'
                                height='auto'
                                src={require('../Styles/Images/pinkClose.png')}
                                onClick={this.closeModal}
                            />
                        </div>
                        <form className='form' onSubmit={this.handleSubmit}>
                            <FormGroup controlId='email' bsSize='large'>
                                <FormControl
                                    className={'actionInput email'}
                                    autoFocus
                                    type='email'
                                    value={this.state.email}
                                    placeholder={'Type your email to reset your password'}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>

                            <Button type='submit' className='uppercase flexRow rowCentered passwordResetBtn'>done</Button>
                        </form>
                    </div>
                </Modal>
            </div>
        )
    }
}